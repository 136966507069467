import { format, parseISO } from 'date-fns';

export function formatDate(d: string, formatString = 'MM.dd.yyyy') {
  const parsedDate = parseISO(d); // Parses the ISO string into a Date object
  return format(parsedDate, formatString); // Formats the Date object as "MM.dd.yyyy"
}

export function formatDateWithTime(d: string) {
  const parsedDate = parseISO(d); // Parses the ISO string into a Date object
  return format(parsedDate, "MM.dd.yyyy 'at' pp");
}

export function customDate(dateString: string) {
  return new Intl.DateTimeFormat('en-US', { day: 'numeric', month: 'short' }).format(new Date(dateString));
}
