import { useAppContext } from 'contexts/AppProviders';
import { useEffect, useMemo } from 'react';
import { SharedEntitiesActionTypes } from 'reducers/sharedEntitiesReducer';
import { CommonStatusEnum } from 'types/enum';
import { NewtonApi } from 'utils/newtonApi';
import { useAuth } from './useAuthSelector';

const useSharedEntitiesState = () => {
  const {
    state: { sharedEntitiesState },
    dispatch,
  } = useAppContext();
  const { status } = sharedEntitiesState;

  useEffect(() => {
    if (status === CommonStatusEnum.INITIAL) {
      (async () => {
        dispatch({
          type: SharedEntitiesActionTypes.FETCHING_SHAREABLE_ENTITIES,
        });
        const [{ results: sharedEntities, ...pagination }] = await Promise.all([NewtonApi.fetchShareableEntities()]);

        dispatch({
          type: SharedEntitiesActionTypes.FETCH_SHAREABLE_ENTITIES,
          payload: { sharedEntities, pagination },
        });
      })();
    }
  }, [dispatch, status]);

  return { state: sharedEntitiesState };
};

const useEntitiesByConversationIdState = (id: ConversationId) => {
  const {
    state: {
      sharedEntitiesState: { sharedEntitiesByConversation },
    },
    dispatch,
  } = useAppContext();
  const { status } = sharedEntitiesByConversation;

  useEffect(() => {
    if (status === CommonStatusEnum.INITIAL) {
      (async () => {
        dispatch({
          type: SharedEntitiesActionTypes.FETCHING_SHARED_ENTITIES_BY_CONVERSATION,
        });
        const { results: sharedEntities, ...pagination } = await NewtonApi.fetchConversationPermissions({
          conversation_id: id,
        });

        dispatch({
          type: SharedEntitiesActionTypes.FETCH_SHARED_ENTITIES_BY_CONVERSATION,
          payload: { sharedEntities, pagination },
        });
      })();
    }

    return () => {
      if (status === CommonStatusEnum.FETCHED)
        dispatch({ type: SharedEntitiesActionTypes.CLEAN_SHARED_ENTITIES_BY_CONVERSATION });
    };
  }, [dispatch, status]);

  return { state: sharedEntitiesByConversation };
};

export const useSharedEntities = () => {
  const {
    state: { sharedEntities, status },
  } = useSharedEntitiesState();
  const { me } = useAuth();
  const loading = useMemo(() => status === CommonStatusEnum.FETCHING || status === CommonStatusEnum.INITIAL, [status]);
  const sharedEntitiesArray = Array.from(sharedEntities.values());
  return {
    sharedEntities: sharedEntitiesArray,
    me: {
      id: me!.id,
      type: 'user',
      name: me!.fullName,
      picture: me!.picture,
      organization: me!.organization,
      flags: me!.flags,
    } as UserEntity,
    loading,
    status,
  };
};

export const useSharedEntitiesById = (id: UserId | string) => {
  const { sharedEntities } = useSharedEntities();
  const { me } = useAuth();

  if (id === me!.id)
    return {
      id: me!.id,
      type: 'user',
      name: me!.fullName,
      picture: me!.picture,
      organization: me!.organization,
    } as UserEntity;
  return sharedEntities.find(entity => entity.id === id);
};

export const useSharedEntitiesByConversationId = (id: ConversationId) => {
  const {
    state: { results, status },
  } = useEntitiesByConversationIdState(id);

  const loading = useMemo(() => status === CommonStatusEnum.FETCHING || status === CommonStatusEnum.INITIAL, [status]);

  return {
    conversationSharedEntities: Array.from(results.values()),
    status,
    loading,
  };
};
