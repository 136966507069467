import { Flex, Typography } from 'antd';
import { FC } from 'react';
import classNames from 'classnames';

import styles from './DashboardSection.module.scss';
import { Loader } from 'components/loader/Loader';
type DashboarSectionProps = {
  bordered?: boolean;
  children: React.ReactNode;
  childrenClassName?: string;
  className?: string;
  description?: string;
  loading?: boolean;
  loadingClassName?: string;
  rightSide?: React.ReactNode;
  title: string;
  vertical?: boolean;
};
export const DashboardSection: FC<DashboarSectionProps> = ({
  bordered,
  children,
  childrenClassName,
  className,
  description,
  loading,
  loadingClassName,
  rightSide,
  title,
  vertical,
}) => {
  return (
    <Flex
      vertical={vertical}
      gap={vertical ? 20 : 15}
      className={classNames(styles.container, { [styles.bordered]: bordered }, className)}
    >
      {loading ? (
        <Loader
          size="default"
          className={classNames(styles.loader, loadingClassName)}
          wrapperClassName={styles.loadingClassname}
        />
      ) : (
        <>
          <Flex className={styles.header} gap={15}>
            <Flex className={styles.titleContainer} vertical gap={8} flex={1}>
              <Typography.Text className={styles.title}>{title}</Typography.Text>
              {description && <Typography.Text className={styles.description}>{description} </Typography.Text>}
            </Flex>
            {vertical && rightSide && (
              <Flex gap={12} align="center">
                {rightSide}
              </Flex>
            )}
          </Flex>
          <Flex
            vertical={vertical}
            className={classNames(styles.childContainer, childrenClassName)}
            gap={vertical ? 20 : 15}
          >
            {children}
          </Flex>
        </>
      )}
    </Flex>
  );
};
