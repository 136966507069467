import { Form, message } from 'antd';
import { useCallback, useMemo, useState } from 'react';
import { ModalScopesEnum, ModalTypesEnum } from 'reducers/modalsReducer';
import { useConversationContainer, useConversationMessages } from 'selectors/useConversationSelectors';
import { useUploadDataSource } from 'selectors/useDataSourcesSelector';
import { useModal } from 'selectors/useModals';
import { Button } from 'ui/Button';
import { Modal } from 'ui/Modal';
import { DataSourceForm } from '../datasource-form/DataSourceForm';
import { useLocation, useParams } from 'react-router-dom';
import { ConnectorSupportedFileTypesEnum } from 'types/enum';

export const AddDataSourceModal: React.FC = () => {
  const {
    hide,
    data: { connectorFile, displayObjectId, conversation, onConfirm: onCallback },
  } = useModal<{
    connectorFile: File;
    displayObjectId: string;
    conversation: Conversation;
    onConfirm(dataSource: DataSource): void;
  }>(ModalScopesEnum.DATA_SOURCES, ModalTypesEnum.ADD);
  const [form] = Form.useForm();

  const { saveTempData } = useConversationMessages(conversation?.id);
  const getConversationContainer = useConversationContainer();
  const uploadDatasourceFile = useUploadDataSource(conversation?.id);
  const { pathname } = useLocation();
  const { conversationId } = useParams();
  const onCancel = useCallback(() => {
    hide();
  }, [hide]);

  const [loading, setLoading] = useState<boolean>(false);

  const onConfirm = useCallback(async () => {
    await form!.validateFields();
    const {
      fileList: { file, fileList },
      isSelectedForConvo,
      ...dataInfo
    } = form.getFieldsValue(true);

    if (fileList.length === 0) {
      message.error('File is required');
      return;
    }

    setLoading(true);
    const formData = new FormData();
    const newFile = new File([file], dataInfo.name, {
      type: fileList[0].type,
    });

    displayObjectId && displayObjectId !== null
      ? formData.append('display_object_id', `${displayObjectId}`)
      : formData.append('file', newFile as unknown as Blob);
    formData.append('description', dataInfo.description);
    formData.append('scope', dataInfo.type);
    formData.append('delimiter', 'auto');

    dataInfo.name.includes(ConnectorSupportedFileTypesEnum.HYPER)
      ? formData.append('type', 'hyper')
      : newFile.type === ConnectorSupportedFileTypesEnum.CSV
        ? formData.append('type', 'csv')
        : formData.append('type', 'xls');
    formData.append('name', dataInfo.name);

    if (dataInfo.type === 'conversation') {
      conversation && formData.append('conversation_id', `${conversation?.id}`);
      conversationId && formData.append('conversation_id', `${conversationId}`);
    }

    try {
      const dataSource = await uploadDatasourceFile(formData, dataInfo.type);
      if (conversation) {
        const container = getConversationContainer(conversation.id);
        const existingDataSource = container?.temporary?.dataSources || [];
        saveTempData({
          dataSources: [...existingDataSource, dataSource].filter(Boolean),
        });
      }
      hide();
      message.success(`Connector ${dataInfo.name} successfully created`);
      isSelectedForConvo && onCallback(dataSource);
    } catch (error) {
      message.error((error as unknown as string).toString());
    }
    setLoading(false);
  }, [
    conversation,
    displayObjectId,
    form,
    getConversationContainer,
    hide,
    onCallback,
    saveTempData,
    uploadDatasourceFile,
  ]);

  const fileList = Form.useWatch('fileList', form);
  const name = Form.useWatch('name', form);
  const description = Form.useWatch('description', form);
  const isFormValid = !!(fileList && fileList.fileList?.length > 0) && name && description;

  const hasConversationScope = useMemo(() => {
    return displayObjectId ? false : !!conversation || !!conversationId;
  }, [conversation, conversationId, displayObjectId]);

  const showNewConversationCheckbox = useMemo(() => {
    return !pathname.includes('blueprints');
  }, [pathname]);

  return (
    <Modal
      footer={
        <>
          <Button onClick={hide} type="secondary">
            Cancel
          </Button>
          <Button
            disabled={!isFormValid} // Disable based on form validity
            onClick={onConfirm}
            loading={loading}
            type="primary"
          >
            Add
          </Button>
        </>
      }
      minHeight="var(--nri-add-datasource-modal-width)"
      onOk={onConfirm}
      onCancel={onCancel}
      open={true}
      title="Add connector"
      description="Here you can add connectors to your conversation"
      divider={true}
    >
      <DataSourceForm
        connectorFile={connectorFile}
        form={form}
        showConversationScope={hasConversationScope}
        showNewConversationCheckbox={showNewConversationCheckbox}
      />
    </Modal>
  );
};
