import { Tooltip, Badge } from 'antd';

export const ProjectLabels: React.FC<{ projectLabels: ProjectLabel[]; width?: string }> = ({
  projectLabels,
  width,
}) => {
  const firstLabel = projectLabels[0];

  if (!firstLabel) {
    return null;
  }

  return (
    <Tooltip
      color="var(--nri-color-white)"
      title={
        <>
          {projectLabels.map(label => (
            <div key={label.id}>
              <Badge text={label.name} color={label.color ?? 'var(--nri-color-purple-grey)'} />
            </div>
          ))}
        </>
      }
    >
      <Badge
        style={{ width: width ?? 'auto', overflow: 'hidden', textWrap: 'nowrap', textOverflow: 'ellipsis' }}
        text={firstLabel.name}
        color={firstLabel.color ?? 'var(--nri-color-purple-grey)'}
      />
    </Tooltip>
  );
};
