export const generateHourIntervals = (interval: number) => {
  const times = [];
  for (let h = 0; h < 24; h++) {
    for (let m = 0; m < 60; m += interval) {
      const hour12 = h % 12 === 0 ? 12 : h % 12;
      const minute = m.toString().padStart(2, '0');
      const period = h < 12 ? 'AM' : 'PM';
      times.push(`${hour12}:${minute} ${period}`);
    }
  }
  return times;
};

export const getLocalizedDaysOfWeek = (locale: string = 'en-US') => {
  return [...Array(7)].map((_, i) => new Date(2023, 0, i + 1).toLocaleString(locale, { weekday: 'long' }));
};

export const getLocalizedDaysInMonth = (year: number, month: number, locale: string = 'en-US') => {
  const days = new Date(year, month, 0).getDate();
  return Array.from({ length: days }, (_, i) =>
    new Date(year, month - 1, i + 1).toLocaleString(locale, { day: 'numeric' }),
  );
};

export const getLocalizedMonths = (locale: string = 'en-US') =>
  Array.from({ length: 12 }, (_, i) => new Date(2000, i, 1).toLocaleString(locale, { month: 'long' }));
