import { FC, useCallback, useState } from 'react';
import styles from './configureData.module.scss';
import { Alert, Flex, Form, message, Upload, UploadFile, UploadProps } from 'antd';
import DocumentIcon from '../../assets/iconsNewVersion/file.svg?react';
import { PageSection } from 'components/page-section/PageSection';
import { InputSimple } from 'ui/Input';
import { Button } from 'ui/Button';
import { UploadOutlined } from '@ant-design/icons';
import { NewtonApi } from 'utils/newtonApi';

const ConfigureData: FC = () => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isloading, setIsLoading] = useState<boolean>(false);
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);

  const successTitle = 'Thank you for uploading your service account key file!';

  const sucessDescription =
    'The Newton Research team will resume system configuration to enable Newton to query your BigQuery datasets.';

  const props: UploadProps = {
    multiple: false,
    iconRender: () => <DocumentIcon className={styles.listItemIcon} />,
    onRemove: () => {
      setFileList([]);
    },
    onChange(info) {
      if (info.file.status == 'removed') form.setFieldValue('upload', undefined);
    },

    beforeUpload: async file => {
      setFileList([file]);
      return false;
    },
    fileList,
  };

  const handleUpload = useCallback(
    async (values: Record<string, unknown>) => {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('file', fileList[0] as unknown as Blob);
      formData.append('name', values.name as string);

      try {
        await NewtonApi.uploadSecretFile(formData);
        setIsLoading(false);
        setIsConfirmed(true);
        form.resetFields();
        setFileList([]);
      } catch (error) {
        setIsLoading(false);
        message.error('Failed to upload file');
      }
    },
    [form, fileList],
  );

  const formValidations = {
    name: { required: true },
    upload: { required: true, message: 'Please select a file' },
  };

  return (
    <PageSection heading="Upload service account key">
      <Flex vertical gap={24}>
        <Form className={styles.form} form={form} layout="vertical" onFinish={handleUpload}>
          <Form.Item name="name" label="Name" className={styles.formItem} rules={[formValidations['name']]}>
            <InputSimple maxLength={80} />
          </Form.Item>
          <Form.Item name="upload" label="File" className={styles.formItem} rules={[formValidations['upload']]}>
            <Upload name="file" listType="picture" {...props}>
              <Button icon={<UploadOutlined />} type="secondary">
                Click to upload
              </Button>
            </Upload>
          </Form.Item>
          <Form.Item label={null} className={styles.submitButtonItem}>
            <Button loading={isloading} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
        {isConfirmed && (
          <Alert
            className={styles.alert}
            closable={true}
            message={successTitle}
            description={sucessDescription}
            onClose={() => setIsConfirmed(false)}
          />
        )}
      </Flex>
    </PageSection>
    // <div className={styles.content}>
    //   <div className={styles.header}>
    //     <Typography.Text className={styles.title}>
    //       Upload service account key
    //     </Typography.Text>
    //   </div>
    //   <Form
    //     className={styles.form}
    //     form={form}
    //     layout="vertical"
    //     requiredMark={(label: ReactNode, info: { required: boolean }) => (
    //       <span>
    //         {info.required && "*"} {label}
    //       </span>
    //     )}
    //     onFinish={handleUpload}
    //   >
    //     <Form.Item name="name" label="Name" rules={[{ required: true }]}>
    //       <Input maxLength={80} />
    //     </Form.Item>
    //     <Form.Item
    //       name="upload"
    //       label="File"
    //       rules={[{ required: true, message: "Please select a file" }]}
    //     >
    //       <Upload name="file" listType="picture" {...props}>
    //         <Button style={{ marginBottom: "12px" }} icon={<UploadOutlined />}>
    //           Click to upload
    //         </Button>
    //       </Upload>
    //     </Form.Item>
    //     <Form.Item>
    //       <Button loading={isloading} type="primary" htmlType="submit">
    //         Submit
    //       </Button>
    //     </Form.Item>
    //   </Form>
    //   {isConfirmed && (
    //     <Alert
    //       className={styles.alert}
    //       closable={true}
    //       message={successTitle}
    //       description={sucessDescription}
    //       onClose={() => setIsConfirmed(false)}
    //     />
    //   )}
    // </div>
  );
};
export default ConfigureData;
