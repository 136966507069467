import classNames from 'classnames';
import { Prompt } from 'components/conversation-view/Prompt';
import { Loader } from 'components/loader/Loader';
import { useAutoScroll } from 'hooks/useAutoScroll';
import { FC, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useConversationMessages, useConversationPageView } from 'selectors/useConversationSelectors';
import { ConversationViewEnum } from '../../types/enum';
import styles from './ConversationView.module.scss';
import { DataSourcesTab } from './DataSourcesTab';
import { NotesTab } from './NotesTab';
import { SummaryTab } from './SummaryTab';

type Props = {
  blankSlate?: React.ReactNode;
  messagesClassName?: string;
  showAnalystSelect?: boolean;
  showPrompt?: boolean;
};

export const ConversationContent: FC<Props> = props => {
  const { conversationId } = useParams();
  const { blankSlate, messagesClassName, showAnalystSelect = true, showPrompt = true } = props;
  const { activeTab } = useConversationPageView();

  const {
    loading: convoLoading,
    messages,
    streaming,
  } = useConversationMessages(Number(conversationId) as ConversationId);

  const divRef = useRef<HTMLDivElement>(null);

  const loading = !conversationId ? false : convoLoading;

  const { jumpToBottom } = useAutoScroll(divRef, [messages, convoLoading, streaming]);

  // Jump to bottom
  useEffect(() => {
    if (activeTab === ConversationViewEnum.EXECUTIVE || activeTab === ConversationViewEnum.ALL || streaming) {
      jumpToBottom();
    }
  }, [activeTab, jumpToBottom, streaming]);

  return (
    <>
      <div className={classNames(styles.messages, messagesClassName)} ref={divRef}>
        <Loader fillVertical spinning={loading}>
          {activeTab === ConversationViewEnum.EXECUTIVE && (
            <SummaryTab blankSlate={blankSlate} view={ConversationViewEnum.EXECUTIVE} />
          )}
          {activeTab === ConversationViewEnum.ALL && (
            <SummaryTab blankSlate={blankSlate} view={ConversationViewEnum.ALL} />
          )}
          {activeTab === ConversationViewEnum.NOTES && <NotesTab />}
          {activeTab === ConversationViewEnum.DATA_SOURCES && <DataSourcesTab />}
        </Loader>
      </div>
      {showPrompt ? <Prompt showAnalystSelect={showAnalystSelect} /> : null}
    </>
  );
};
