import { Button, Flex, Form, Layout, Segmented, Space } from 'antd';
import { Store } from 'antd/es/form/interface';
import classNames from 'classnames';
import { Step } from 'components/entity-details/Step';
import { useEntityContext } from 'components/layouts/context/EntityProvider';
import { SegmentedItemLabel } from '../../newVersionComponents/common/SegmentedItemLabel';
import styles from './EntityDetails.module.scss';

type EntityDetailsProps = {
  className?: string;
  detailsFormClassName?: string;
  entityName: string;
  children?: React.ReactNode;
  fullHeight?: boolean;
  isEditable?: boolean;
  onCancel?: () => void;
  showSegmented?: boolean;
};

export const EntityDetails = <T extends Store | undefined>({
  className,
  children,
  detailsFormClassName,
  entityName,
  fullHeight,
  isEditable = true,
  onCancel,
  showSegmented = true,
}: EntityDetailsProps) => {
  const {
    current,
    form,
    initialFormValue,
    isDirty,
    isNew,
    isSubmitting,
    jump,
    next,
    onFinish,
    prev,
    steps,
    stepRef,
    validateAndSubmit,
  } = useEntityContext<T>();

  const currentStep = steps[current];

  const buttonText = isSubmitting
    ? isNew
      ? `Creating ${entityName}`
      : `Updating ${entityName}`
    : isNew
      ? `Create ${entityName}`
      : `Update ${entityName}`;

  return (
    <>
      <Flex vertical gap={32} className={classNames(styles.content, { [styles.fullHeight]: fullHeight }, className)}>
        {showSegmented && (
          <div className={styles.segmentedContainer}>
            <Segmented
              className={styles.segmented}
              options={steps.map(({ title, key }, index) => ({
                label: (
                  <SegmentedItemLabel
                    label={title}
                    active={current === index}
                    handleClick={() => {
                      jump(index);
                    }}
                  />
                ),
                value: key,
              }))}
              value={currentStep.key}
            />
          </div>
        )}
        <div className={styles.details}>
          <Form
            form={form}
            className={classNames({ [styles.detailsForm]: !detailsFormClassName }, detailsFormClassName)}
            initialValues={initialFormValue}
            layout="vertical"
            onFinish={onFinish}
          >
            {steps.map((step, i) => (
              <Step forwardRef={stepRef} key={step.key} active={current === i} step={step} />
            ))}
          </Form>
          {children}
        </div>
      </Flex>

      {isEditable && (
        <Layout.Footer className={styles.stepsFooter}>
          <Flex className={styles.stepsFooterContent} justify="flex-end">
            <Form.Item noStyle>
              <Space>
                {current > 0 && <Button onClick={prev}>Back</Button>}
                {current === 0 && onCancel && <Button onClick={onCancel}>Cancel</Button>}
                {current < steps.length - 1 && (
                  <Button type={'primary'} disabled={!isDirty && isNew} onClick={next}>
                    Next
                  </Button>
                )}

                {current === steps.length - 1 && (
                  <Button
                    type="primary"
                    htmlType="submit"
                    onClick={validateAndSubmit}
                    loading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    {buttonText}
                  </Button>
                )}
              </Space>
            </Form.Item>
          </Flex>
        </Layout.Footer>
      )}
    </>
  );
};
