import { Layout } from 'antd';
import { Loader } from 'components/loader/Loader';
import { useAuth } from 'contexts/AuthContext';
import { PropsWithChildren } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import '../../App.scss';
import { Preload } from 'components/Preload';
import { useAuth0 } from '@auth0/auth0-react';

const ProtectedRoute: React.FC<
  PropsWithChildren & { onlySuperUser?: boolean; isRouteAccessible?: (user: UserEntity) => boolean }
> = ({ onlySuperUser, isRouteAccessible, children }) => {
  const { isAuthenticated, isLoading } = useAuth0();
  const {
    state: { authenticated, loading, user },
  } = useAuth();
  const location = useLocation();

  // Show loader while Auth0 is initializing
  if (isLoading || loading) {
    return (
      <Layout
        style={{
          alignContent: 'center',
          height: '100vh',
          justifyContent: 'center',
        }}
      >
        <Loader fullscreen spinning />
      </Layout>
    );
  }

  // Redirect to login if not authenticated
  if (!isAuthenticated) {
    return <Navigate to="/authorizing" state={{ returnTo: location.pathname }} replace />;
  }

  if (!authenticated || !user) {
    return <Navigate to="/unauthorized" replace />;
  }
  if (onlySuperUser && !user.isSuperuser) {
    throw new Error('Not found');
  }

  if (isRouteAccessible && !isRouteAccessible(user)) {
    throw new Error('Not found');
  }

  return <Preload>{children || <Outlet />}</Preload>;
};

export default ProtectedRoute;
