import { AgentActions } from 'reducers/agentsReducer';
import { AnalystsAction } from 'reducers/analystsReducer';
import { BlueprintActions } from 'reducers/bluePrintReducer';
import { ConversationsAction } from 'reducers/conversationsReducer';
import { DataSourcesAction } from 'reducers/dataSourcesReducer';
import { FeedbackActions } from 'reducers/feedbackReducer';
import { MemoryActions } from 'reducers/memoriesReducer';
import { ModalsActions } from 'reducers/modalsReducer';
import { ProjectLabelsAction } from 'reducers/projectsReducer';
import { SharedEntitiesAction } from 'reducers/sharedEntitiesReducer';
import { StatisticsActions } from 'reducers/statisticsReducer';
import { ScheduledTasksActions } from 'reducers/scheduledTasksReducer';

export enum AppActionTypes {
  READY = 'READY',
}

export type AppAction = { type: AppActionTypes.READY };

export enum AuthActionTypes {
  SET_AUTHENTICATED = 'SET_AUTHENTICATED',
  SET_USER = 'SET_USER',
  SET_LOADING = 'SET_LOADING',
}

export enum MessagesActionTypes {
  NEW_MESSAGE = 'NEW_MESSAGE',
  UPDATE_MESSAGE = 'UPDATE_MESSAGE',
  DELETE_MESSAGE = 'DELETE_MESSAGE',
}

export enum StatisticsActionTypes {}

export type Actions =
  | AgentActions
  | AppAction
  | AnalystsAction
  | BlueprintActions
  | ConversationsAction
  | DataSourcesAction
  | FeedbackActions
  | MemoryActions
  | ModalsActions
  | ProjectLabelsAction
  | SharedEntitiesAction
  | StatisticsActions
  | ScheduledTasksActions;
