import { AuthorizationParams } from '@auth0/auth0-react';
import EventSource from '@sanity/eventsource';
import { ConversationStatusEnum, EntityPermissionsEnum, FeedbackScopeEnum, RetrieveTypesEnum } from '../types/enum';
import { redirectToLogin, ErrorCode } from 'types/errorCodes';

type ShareRequest = {
  users: { id: UserId; permission: EntityPermissionsEnum }[];
  teams: { id: UserId; permission: EntityPermissionsEnum }[];
  organizations: { id: UserId; permission: EntityPermissionsEnum }[];
};

const displayObjectCache = new Map<number, unknown>();
export type ApiError = {
  id: number;
  url: string;
  additionalHeaders?: object;
  status: number;
  message: string;
};

export class NewtonApi {
  static retryCount = 0;
  static errors: ApiError[] = [];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static fetchToken = (_data: { authorizationParams: AuthorizationParams }) => {};
  static clearErrors() {
    NewtonApi.errors = [];
  }

  static async request(
    url: string,
    method: 'GET' | 'PATCH' | 'POST' | 'PUT' | 'DELETE',
    iBody?: BodyInit | null | undefined,
    additionalHeaders?: object,
    options?: { shouldThrow: boolean; signal?: AbortSignal },
  ): Promise<Response> {
    const token = await NewtonApi.fetchToken({
      authorizationParams: {
        audience: __AUTH0_AUDIENCE__,
        redirect_uri: `${window.location.origin}/authorizing`,
      },
    });
    const headers = {} as Record<string, string>;
    let body = iBody || undefined;

    if (!(body instanceof FormData)) {
      headers['Content-Type'] = 'application/json';
      body = iBody ? JSON.stringify(iBody) : undefined;
    }

    return fetch(`${__API_URL__}/${url}`, {
      method,
      body,
      signal: options?.signal,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        ...headers,
        ...(additionalHeaders || {}),
      },
    }).then(async response => {
      if (response.status === 401 || response.status === 403) {
        if (NewtonApi.retryCount === 0) {
          NewtonApi.retryCount++;
          return NewtonApi.request(url, method, iBody, additionalHeaders, options);
        }
        throw new Error('An unexpected error occurred.');
      } else if (!response.ok) {
        if (NewtonApi.retryCount === 0) {
          NewtonApi.retryCount++;
          return NewtonApi.request(url, method, iBody, additionalHeaders, options);
        }
        let message = response.statusText;
        const clonedResponse = response.clone();

        try {
          const responseBody = await clonedResponse.json();
          message = responseBody.message;
        } catch (e) {
          message = await response.clone().text();
        }

        NewtonApi.errors.push({
          id: +Date.now(),
          url,
          additionalHeaders,
          status: response.status,
          message,
        });

        if (options?.shouldThrow) {
          throw response;
        }
      } else {
        NewtonApi.retryCount = 0;
      }

      return response;
    });
  }

  static async downloadDisplayObject(displayObject: DisplayObject) {
    const response = await NewtonApi.request(`discourse/displayobjects/${displayObject.id}/presigned-url`, 'GET');

    if (response.ok) {
      const data = await response.blob();

      const blobUrl = URL.createObjectURL(data);
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = displayObject.name;
      document.body.appendChild(a);
      a.click();
      a.remove();
      URL.revokeObjectURL(blobUrl);
    }
  }

  static async fetchDisplayObject(displayObject: DisplayObject): Promise<unknown> {
    if (displayObjectCache.has(displayObject.id)) {
      return displayObjectCache.get(displayObject.id);
    }
    const headers: Record<string, string> = {};

    if (displayObject.type === 'csv') {
      headers['Range'] = 'bytes=0-200';
    }

    const data = await NewtonApi.request(
      `discourse/displayobjects/${displayObject.id}/presigned-url`,
      'GET',
      undefined,
      headers,
    );

    switch (displayObject.type) {
      case 'png':
      case 'jpg':
      case 'jpeg':
      case 'gif':
        displayObjectCache.set(displayObject.id, data.url as unknown);
        return data.url;
      case 'python':
      case 'csv':
      default: {
        const blob = await data.text();
        displayObjectCache.set(displayObject.id, blob);
        return blob;
      }
    }
  }

  static async fetchAllConversations(
    params: {
      sort?: string;
      retrieve_type?: string;
      page_size?: string;
      feedback_scope?: FeedbackScopeEnum;
      status?: 'active' | 'archived';
    } = {},
  ) {
    const queryString = new URLSearchParams({
      sort: '-updated_at',
      page_size: '50',
      feedback_scope: FeedbackScopeEnum.USER,
      include_nested_objects: 'false',
      status: 'active',
      ...params,
    }).toString();
    const data = await NewtonApi.request(`discourse/conversations?${queryString}`, 'GET');
    return (await data.json()) as Pagination<Conversation>;
  }

  static async fetchConversationPermissions(params: {
    conversation_id: Conversation['id'];
    sort?: string;
    retrieve_type?: string;
    page_size?: string;
  }) {
    const { conversation_id, ...params2 } = params;

    // @todo fix type
    // @ts-expect-error - fixed with next types sync
    const queryString = new URLSearchParams({
      conversation_id,
      sort: '-updated_at',
      page_size: '100',
      ...params2,
    }).toString();
    const data = await NewtonApi.request(`discourse/conversationpermissions?${queryString}`, 'GET');
    return (await data.json()) as Pagination<ShareableEntity>;
  }

  static async fetchAllAnalysts({ filter_enabled = 'true' } = {}) {
    const queryString = new URLSearchParams({
      page_size: '100',
      filter_enabled: filter_enabled,
    }).toString();

    const data = await NewtonApi.request(`analyst/analysts?${queryString}`, 'GET');
    return (await data.json()) as Pagination<Analyst>;
  }

  static async fetchDefaultAnalyst() {
    const data = await NewtonApi.request(`analyst/default`, 'GET');
    return await data.json();
  }

  static async generateBlueprintFromConversation(conversationId: ConversationId) {
    const response = await NewtonApi.request(`discourse/blueprints`, 'POST', {
      from_conversation: conversationId,
    } as unknown as BodyInit);
    return await response.json();
  }

  static async fetchAllDataSources(scope: RetrieveTypesEnum, shouldThrow: boolean = false) {
    const queryParams: Record<string, string> = {
      page_size: '100',
    };

    if (scope === RetrieveTypesEnum.FAVORITE) {
      queryParams.favorite_scope = scope;
    } else if (scope === RetrieveTypesEnum.RECENT) {
      queryParams.recent = 'true';
    } else {
      queryParams.retrieve_type = scope;
    }
    const queryString = new URLSearchParams(queryParams).toString();

    const data = await NewtonApi.request(`memory/datasources?${queryString}`, 'GET', undefined, undefined, {
      shouldThrow,
    });
    return (await data.json()) as Pagination<DataSource>;
  }

  static async fetchAllBlueprintCategories(shouldThrow: boolean = false) {
    const data = await NewtonApi.request(`discourse/blueprints/use-case-categories`, 'GET', undefined, undefined, {
      shouldThrow,
    });
    return (await data.json()) as BlueprintCategory[];
  }

  static async archiveBlueprint(id: IceBreaker['id'], shouldThrow: boolean = false) {
    return NewtonApi.request(`discourse/blueprints/${id}/archive`, 'POST', undefined, undefined, { shouldThrow });
  }

  static async createBlueprint(iceBreaker: NewIceBreaker) {
    const response = await NewtonApi.request(`discourse/blueprints`, 'POST', iceBreaker as unknown as BodyInit);
    return (await response.json()) as IceBreaker;
  }

  static async copyBlueprint(iceBreaker: UpdateIceBreaker, shouldThrow: boolean = false) {
    const response = await NewtonApi.request(
      `discourse/blueprints/${iceBreaker.id}/copy`,
      'POST',
      iceBreaker as unknown as BodyInit,
      undefined,
      { shouldThrow },
    );
    return await response.json();
  }

  static async generateInitialMessage(blueprint: Partial<Blueprint>, signal?: AbortSignal) {
    const response = await NewtonApi.request(
      `discourse/blueprints/initial-message`,
      'POST',
      blueprint as unknown as BodyInit,
      undefined,
      { shouldThrow: true, signal },
    );
    return await response.json();
  }

  static async deleteBlueprint(id: IceBreaker['id'], shouldThrow: boolean = false) {
    return NewtonApi.request(`discourse/blueprints/${id}`, 'DELETE', undefined, undefined, { shouldThrow });
  }

  static async updateBlueprint(iceBreaker: UpdateIceBreaker) {
    const response = await NewtonApi.request(
      `discourse/blueprints/${iceBreaker.id}`,
      'PUT',
      iceBreaker as unknown as BodyInit,
    );
    return await response.json();
  }

  static async fetchAllBlueprints(
    scope: RetrieveTypesEnum | 'LIBRARY',
    shouldThrow: boolean = false,
    params?: { [key: string]: string },
  ) {
    const queryParams: Record<string, string> = {
      page_size: '100',
      ...params,
    };
    queryParams.library_scope = 'only_is_not_library';

    if (scope === 'LIBRARY') {
      queryParams.library_scope = 'only_is_library';
    } else if (scope === RetrieveTypesEnum.FAVORITE) {
      queryParams.favorite_scope = 'only_favorites';
    } else if (scope === RetrieveTypesEnum.RECENT) {
      queryParams.recent = 'true';
    }

    const queryString = new URLSearchParams(queryParams).toString();

    const data = await NewtonApi.request(`discourse/blueprints?${queryString}`, 'GET', undefined, undefined, {
      shouldThrow,
    });
    return (await data.json()) as Pagination<IceBreaker>;
  }

  static async fetchBlueprint(id: IceBreakerId) {
    const response = await NewtonApi.request(`discourse/blueprints/${id}`, 'GET');
    return await response.json();
  }

  static async createBlueprintStep(step: NewBlueprintStep) {
    const response = await NewtonApi.request(`discourse/steps`, 'POST', step as unknown as BodyInit);
    return (await response.json()) as BlueprintStep;
  }

  static async deleteBlueprintStep(id: BlueprintStepId, shouldThrow: boolean = false) {
    return NewtonApi.request(`discourse/steps/${id}`, 'DELETE', undefined, undefined, { shouldThrow });
  }

  static async updateBlueprintStep(step: UpdateBlueprintStep) {
    const response = await NewtonApi.request(`discourse/steps/${step.id}`, 'PUT', step as unknown as BodyInit);
    return await response.json();
  }

  static async toggleBlueprintFavorite(id: IceBreakerId, isFavorite: boolean, shouldThrow: boolean = false) {
    await NewtonApi.request(
      `discourse/blueprints/${id}/favorite`,
      'PUT',
      { is_favorite: isFavorite } as unknown as BodyInit,
      undefined,
      {
        shouldThrow,
      },
    );
  }

  static async fetchShareableEntities() {
    const queryString = new URLSearchParams({
      page_size: '100',
    }).toString();
    const data = await NewtonApi.request(`user/shareable-entities?${queryString}`, 'GET');
    return (await data.json()) as Pagination<ShareableEntity>;
  }

  static async fetchUser() {
    const data = await NewtonApi.request('user/me', 'GET');
    if (!data.ok) {
      throw new Error('Failed to fetch user');
    }
    return await data.json();
  }

  static async fetchConversation(
    id: ConversationId,
    params: {
      feedback_scope?: string;
      message_id?: string;
      messages_to_tree?: string;
      only_most_recent_version?: string;
    } = {},
    shouldThrow: boolean = false,
  ): Promise<Conversation & { dataSources: DataSource[] }> {
    const queryString = new URLSearchParams({
      feedback_scope: 'all',
      messages_to_tree: 'true',
      only_most_recent_version: 'true',
      ...params,
    }).toString();
    const data = await NewtonApi.request(`discourse/conversations/${id}?${queryString}`, 'GET', undefined, undefined, {
      shouldThrow,
    });
    return await data.json();
  }

  static async toggleConversationFavorite(id: ConversationId, isFavorite: boolean, shouldThrow: boolean = false) {
    await NewtonApi.request(
      `discourse/conversations/${id}/favorite`,
      'PUT',
      { is_favorite: isFavorite } as unknown as BodyInit,
      undefined,
      {
        shouldThrow,
      },
    );
  }

  static async fetchMessage(id: MessageId, feedbackScope: FeedbackScopeEnum = FeedbackScopeEnum.ALL): Promise<Message> {
    const queryString = new URLSearchParams({
      feedback_scope: feedbackScope,
    }).toString();
    const data = await NewtonApi.request(`discourse/messages/${id}?${queryString}`, 'GET');
    return await data.json();
  }

  static async fetchMemories(
    {
      page = 1,
      pageSize = 100,
      specialist,
    }: { page?: number; pageSize?: number; specialist?: 'chartReview' | 'insights' | 'endOfTurnSummary' } = {},
    shouldThrow: boolean = false,
  ) {
    const queryString = new URLSearchParams({
      page_size: pageSize.toString(),
      page: page.toString(),
    });

    if (specialist) {
      queryString.append('specialists', specialist);
    }

    const data = await NewtonApi.request(`memory/memories?${queryString.toString()}`, 'GET', undefined, undefined, {
      shouldThrow,
    });
    return (await data.json()) as Pagination<Memory>;
  }

  static async fetchMemoryTypes() {
    const queryString = new URLSearchParams({
      page_size: '100',
    }).toString();
    const data = await NewtonApi.request(`memory/memorytypes?${queryString}`, 'GET');
    return (await data.json()) as Pagination<MemoryType>;
  }

  static async fetchMemoryLabels() {
    const queryString = new URLSearchParams({
      page_size: '100',
    }).toString();
    const data = await NewtonApi.request(`memory/memorylabels?${queryString}`, 'GET');
    return (await data.json()) as Pagination<MemoryLabel>;
  }

  static async fetchMemorySources() {
    const queryString = new URLSearchParams({
      page_size: '100',
    }).toString();
    const data = await NewtonApi.request(`memory/memorysources?${queryString}`, 'GET');
    return (await data.json()) as Pagination<MemorySource>;
  }

  static async createMemory(memory: NewMemory, shouldThrow: boolean = false) {
    const response = await NewtonApi.request(`memory/memories`, 'POST', memory as unknown as BodyInit, undefined, {
      shouldThrow,
    });
    return await response.json();
  }

  static async deleteMemory(id: Memory['id'], shouldThrow: boolean = false) {
    await NewtonApi.request(`memory/memories/${id}`, 'DELETE', undefined, undefined, { shouldThrow });
  }

  static async updateMemory(data: Memory) {
    const response = await NewtonApi.request(`memory/memories/${data.id}`, 'PUT', data as unknown as BodyInit);
    return await response.json();
  }

  static async toggleMemoryStatus(id: Memory['id'], status: boolean, shouldThrow: boolean = false) {
    const payload = {
      isEnabled: status,
    };
    const response = await NewtonApi.request(
      `memory/memories/${id}`,
      'PATCH',
      payload as unknown as BodyInit,
      undefined,
      {
        shouldThrow,
      },
    );

    return await response.json();
  }

  static async copyConversation(id: ConversationId) {
    const data = await NewtonApi.request(`discourse/conversations/${id}/copy`, 'POST');
    return await data.json();
  }

  static async uploadMemoryFile(form: FormData, shouldThrow: boolean = false) {
    const data = await NewtonApi.request(`memory/file/datasources`, 'POST', form, undefined, { shouldThrow });
    return await data.json();
  }

  static async stopConversation(conversationId: ConversationId) {
    const reponse = await NewtonApi.request(`analyst/process-control/stop-process-in-redis`, 'POST', {
      conversation_id: conversationId,
    } as unknown as BodyInit);
    return await reponse.json();
  }

  static async shareConversation(id: ConversationId, entities: ShareableEntity[]) {
    const data: ShareRequest = entities.reduce(
      (acc, item: ShareableEntity) => {
        if (item.type === 'user') {
          acc.users.push({ id: item.id as UserId, permission: item.permission || EntityPermissionsEnum.READ });
        } else if (item.type === 'team') {
          acc.teams.push({ id: item.id as UserId, permission: item.permission || EntityPermissionsEnum.READ });
        } else if (item.type === 'organization') {
          acc.organizations.push({ id: item.id as UserId, permission: item.permission || EntityPermissionsEnum.READ });
        }
        return acc;
      },
      { users: [], teams: [], organizations: [] } as ShareRequest,
    );

    const reponse = await NewtonApi.request(`discourse/conversations/${id}/actors`, 'PUT', data as unknown as BodyInit);
    return await reponse.json();
  }

  static async createConversation(conversation: NewConversation) {
    const response = await NewtonApi.request(`discourse/conversations`, 'POST', {
      ...conversation,
      push_to_redis: true,
    } as unknown as BodyInit);
    return await response.json();
  }

  static async deleteConversation(id: ConversationId, shouldThrow: boolean = false) {
    return NewtonApi.request(`discourse/conversations/${id}?push_to_redis=true`, 'DELETE', undefined, undefined, {
      shouldThrow,
    });
  }

  static async archiveConversation(id: ConversationId, status: ConversationStatusEnum, shouldThrow: boolean = false) {
    return NewtonApi.request(
      `user/me/conversations/${id}/status`,
      'PATCH',
      { status } as unknown as BodyInit,
      undefined,
      {
        shouldThrow,
      },
    );
  }

  static async updateConversation(conversation: Partial<Conversation>) {
    const data = await NewtonApi.request(
      `discourse/conversations/${conversation.id}`,
      'PATCH',
      conversation as unknown as BodyInit,
    );
    return await data.json();
  }

  static async rewindConversation(conversationId: ConversationId, messageId: MessageId) {
    return NewtonApi.request(`discourse/conversations/${conversationId}/rewind`, 'POST', {
      message_id: messageId,
    } as unknown as BodyInit);
  }

  static async createFileDescription(form: FormData) {
    const data = await NewtonApi.request(`memory/file/info`, 'POST', form);
    return await data.json();
  }

  static async fetchFeedbackSentiments() {
    const data = await NewtonApi.request(`discourse/sentiments`, 'GET');
    return (await data.json()) as FeedbackSentiments;
  }

  static async createComment(comment: CommentData) {
    const data = await NewtonApi.request(`discourse/comments`, 'POST', comment as unknown as BodyInit);
    return await data.json();
  }

  static async sendFeedback(feedback: Feedback, id: MessageId) {
    const data = await NewtonApi.request(`discourse/messages/${id}/feedbacks`, 'PUT', feedback as unknown as BodyInit);
    return await data.json();
  }

  static async fetchDatasource(id: DataSourceId, shouldThrow: boolean = false) {
    const data = await NewtonApi.request(`memory/datasources/${id}`, 'GET', undefined, undefined, { shouldThrow });
    return await data.json();
  }

  static async fetchDatasourcePermissions(id: DataSourceId, shouldThrow: boolean = false) {
    const data = await NewtonApi.request(`memory/datasources/${id}/actors`, 'GET', undefined, undefined, {
      shouldThrow,
    });
    return await data.json();
  }

  static async updateDatasourcePermissions(
    id: DataSourceId,
    sharedEntities: PermissionsPayload,
    shouldThrow: boolean = false,
  ) {
    const data = await NewtonApi.request(
      `memory/datasources/${id}/actors`,
      'PUT',
      sharedEntities as unknown as BodyInit,
      undefined,
      { shouldThrow },
    );
    return await data.json();
  }

  static async archiveDatasource(id: DataSourceId, shouldThrow: boolean = false) {
    return NewtonApi.request(`memory/datasources/${id}/archive`, 'POST', undefined, undefined, { shouldThrow });
  }

  static async downloadDatasource(id: DataSourceId, name: string, shouldThrow: boolean = false) {
    const response = await NewtonApi.request(`memory/datasources/${id}/file`, 'GET', undefined, undefined, {
      shouldThrow,
    });

    if (response.ok) {
      const data = await response.blob();
      const blobUrl = URL.createObjectURL(data);
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = name;
      document.body.appendChild(a);
      a.click();
      a.remove();
      URL.revokeObjectURL(blobUrl);
    }
  }

  static async deleteDatasource(id: DataSourceId, shouldThrow: boolean = false) {
    await NewtonApi.request(`memory/datasources/${id}`, 'DELETE', undefined, undefined, { shouldThrow });
  }

  static async toggleDataSourceAsFavorite(id: DataSourceId, isFavorite: boolean, shouldThrow: boolean = false) {
    await NewtonApi.request(
      `memory/datasources/${id}/favorite?`,
      'PUT',
      { is_favorite: isFavorite } as unknown as BodyInit,
      undefined,
      { shouldThrow },
    );
  }

  static async updateDatasource(id: DataSourceId, dataSource: UpdateDataSourcePayload, shouldThrow: boolean = false) {
    const data = await NewtonApi.request(
      `memory/datasources/${id}`,
      'PUT',
      dataSource as unknown as BodyInit,
      undefined,
      { shouldThrow },
    );
    return (await data.json()) as DataSource;
  }

  static async updateDatasourceWithSupportedFormat(
    id: DataSourceId,
    dataSource: UpdateDataSourcePayload,
    shouldThrow: boolean = false,
  ) {
    const dataSourceData = new FormData();

    Object.entries(dataSource).forEach(([key, value]) => {
      dataSourceData.append(key, value);
    });

    const data = await NewtonApi.request(
      `memory/datasources/${id}/file`,
      'PUT',
      dataSourceData as FormData,
      undefined,
      { shouldThrow },
    );
    return (await data.json()) as DataSource;
  }

  static async fetchAllFeedbacks(page: number = 1, pageSize: number = 10) {
    const queryParams = new URLSearchParams({
      page_size: pageSize.toString(),
      page: page.toString(),
      split_by_sentiment: 'true',
    });
    const data = await NewtonApi.request(`discourse/feedbacks?${queryParams.toString()}`, 'GET');
    return (await data.json()) as Pagination<FeedbackItemData>;
  }

  static async fetchProjectLabels(page_size: number = 50) {
    const queryParams = new URLSearchParams({
      page_size: page_size.toString(),
    });
    const data = await NewtonApi.request(`discourse/projectlabels?${queryParams}`, 'GET');
    return (await data.json()) as Pagination<ProjectLabel>;
  }

  static async updateProjectLabel(projectLabel: ProjectLabel) {
    const data = await NewtonApi.request(
      `discourse/projectlabels/${projectLabel.id}`,
      'PUT',
      projectLabel as unknown as BodyInit,
    );
    return await data.json();
  }

  static async uploadProjectLabel(form: FormData, shouldThrow: boolean = false) {
    const data = await NewtonApi.request(`discourse/projectlabels`, 'POST', form, undefined, { shouldThrow });
    return (await data.json()) as ProjectLabel;
  }

  static async deleteProjectLabel(projectId: ProjectLabelId, shouldThrow: boolean = false) {
    await NewtonApi.request(`discourse/projectlabels/${projectId}`, 'DELETE', undefined, undefined, {
      shouldThrow,
    });
  }

  static async uploadSecretFile(form: FormData, shouldThrow: boolean = false) {
    const data = await NewtonApi.request(`memory/secrets`, 'POST', form, undefined, { shouldThrow });
    return await data.json();
  }

  static async createFileByDisplayObject(displayObject: DisplayObject, type: string) {
    const response = await NewtonApi.request(`discourse/displayobjects/${displayObject.id}/presigned-url`, 'GET');

    if (response.ok) {
      const data = await response.blob();
      const file = new File([data], displayObject.name, {
        type,
      });
      return file;
    }
  }

  static async fetchFeedbackComments(messageId: MessageId) {
    const data = await NewtonApi.request(`discourse/messages/${messageId}/comments`, 'GET');
    return await data.json();
  }

  static async fetchFeedback(feedbackId: FeedbackId) {
    const data = await NewtonApi.request(`discourse/feedbacks/${feedbackId}`, 'GET');
    return await data.json();
  }

  static async fetchConversationDataSources(
    conversationId: ConversationId,
    shouldThrow: boolean = false,
  ): Promise<
    {
      id: Message['id'];
      dataSources: DataSource[];
    }[]
  > {
    const data = await NewtonApi.request(
      `discourse/conversations/${conversationId}/data-sources`,
      'GET',
      undefined,
      undefined,
      { shouldThrow },
    );
    return await data.json();
  }

  static async fetchFile(id: DataSource['id'], name: string, type: string) {
    const data = await NewtonApi.request(`memory/datasources/${id}/file`, 'GET');

    if (data.ok) {
      const res = await data.blob();
      const file = new File([res], name, {
        type,
      });
      return file;
    }
  }

  static async streamingConversations() {
    const response = await NewtonApi.request(`discourse/conversations/running`, 'GET');
    return (await response.json()) as ConversationId[];
  }

  static async streamListener(uuid: UserEntity['uuid'], notify: (event: StreamEvents) => void) {
    const token = await NewtonApi.fetchToken({
      authorizationParams: {
        audience: __AUTH0_AUDIENCE__,
        redirect_uri: `${window.location.origin}/authorizing`,
      },
    });

    const eventSourceInitDict = {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      heartbeatTimeout: 1000000,
    };
    const eventSource = new EventSource(`${__API_URL__}/user/${uuid}/stream`, eventSourceInitDict);

    eventSource.onopen = () => {
      notify({
        type: 'system',
        event: 'connected',
        payload: null,
      });
    };
    eventSource.onmessage = async event => {
      const details = JSON.parse(event.data);
      notify(details);
    };

    eventSource.onerror = (e: unknown) => {
      notify({
        type: 'system',
        event: 'error',
        payload: e,
      });
      eventSource.close();
    };

    return eventSource;
  }

  static async keepAlive({ shouldThrow = false }) {
    try {
      await NewtonApi.request(`user/me/keep-alive`, 'POST', undefined, undefined, { shouldThrow });
    } catch (e) {
      console.error(e);
      redirectToLogin(ErrorCode.SESSION_EXPIRED);
    }
  }

  static async newMessage(payload: {
    conversationId: ConversationId;
    dataSources?: DataSourceId[];
    iceBreakerId: IceBreaker['id'] | null;
    message: Message;
  }) {
    const response = await NewtonApi.request(`discourse/user-text`, 'POST', payload as unknown as BodyInit);
    return await response;
  }

  static async fetchUsageReport(params: { [key: string]: string | string[] } = {}, shouldThrow: boolean = false) {
    const { fields, ...rest } = params;

    const queryParams = new URLSearchParams({
      level: 'organization',
      time_period: 'this_month',
      aggregate: 'sum',
      ...rest,
    });

    if (Array.isArray(fields)) {
      fields?.map(field => queryParams.append('fields', field));
    }

    const response = await NewtonApi.request(
      `discourse/llm-statistics/usage-report?${queryParams}`,
      'GET',
      undefined,
      undefined,
      { shouldThrow },
    );
    return await response.json();
  }

  static async fetchExpendedCreditsInfo(params?: { [key: string]: string }, shouldThrow: boolean = false) {
    const queryParams = new URLSearchParams({ time_period: 'this_month', ...params });

    const response = await NewtonApi.request(
      `discourse/llm-statistics/credits-info?${queryParams}`,
      'GET',
      undefined,
      undefined,
      {
        shouldThrow,
      },
    );
    return await response.json();
  }

  static async fetchTimePeriods() {
    const response = await NewtonApi.request(`discourse/llm-statistics/time-period`, 'GET');
    return await response.json();
  }

  static async toggleAgentFavorite(id: Analyst['id'], isFavorite: boolean, shouldThrow: boolean = false) {
    await NewtonApi.request(
      `analyst/analysts/${id}/favorite`,
      'PUT',
      { is_favorite: isFavorite } as unknown as BodyInit,
      undefined,
      { shouldThrow },
    );
  }

  static async fetchAgent(id: Analyst['id']) {
    const response = await NewtonApi.request(`analyst/analysts/${id}`, 'GET');
    return await response.json();
  }

  static async updateAgent(analyst: Agent) {
    const response = await NewtonApi.request(
      `analyst/analysts/${analyst.id}`,
      'PUT',
      analyst as unknown as BodyInit,
      undefined,
      { shouldThrow: true },
    );
    return await response.json();
  }

  static async deleteAgent(id: Analyst['id'], shouldThrow: boolean = false) {
    return NewtonApi.request(`analyst/analysts/${id}`, 'DELETE', undefined, undefined, { shouldThrow });
  }

  static async fetchAgentModels() {
    const queryString = new URLSearchParams({
      page_size: '1000',
    });
    const response = await NewtonApi.request(`analyst/analysts/active-models?${queryString.toString()}`, 'GET');
    return await response.json();
  }

  static async createAgent(agent: NewAgent) {
    const response = await NewtonApi.request(`analyst/analysts`, 'POST', agent as unknown as BodyInit, undefined, {
      shouldThrow: true,
    });
    return await response.json();
  }

  static async fetchScheduledTasks(params: { [key: string]: string | string[] } = {}, shouldThrow: boolean = false) {
    const queryParams = new URLSearchParams({
      page_size: '10',
      page: '1',
      ...params,
    });
    const data = await NewtonApi.request(`discourse/scheduledtasks?${queryParams}`, 'GET', undefined, undefined, {
      shouldThrow,
    });
    return await data.json();
  }
  static async createScheduledTask(data: NewScheduledTask, shouldThrow: boolean = false) {
    const response = await NewtonApi.request(
      `discourse/scheduledtasks`,
      'POST',
      data as unknown as BodyInit,
      undefined,
      {
        shouldThrow,
      },
    );
    return await response.json();
  }
  static async updateScheduledTask(id: ScheduledTask['id'], data: NewScheduledTask, shouldThrow: boolean = false) {
    const response = await NewtonApi.request(
      `discourse/scheduledtasks/${id}`,
      'PUT',
      data as unknown as BodyInit,
      undefined,
      {
        shouldThrow,
      },
    );
    return await response.json();
  }
  static async updateScheduledTaskStatus(id: ScheduledTask['id'], isEnabled: boolean, shouldThrow: boolean = false) {
    const response = await NewtonApi.request(
      `discourse/scheduledtasks/${id}`,
      'PATCH',
      { isEnabled } as unknown as BodyInit,
      undefined,
      { shouldThrow },
    );
    return await response.json();
  }
  static async deleteScheduledTask(id: ScheduledTask['id'], shouldThrow: boolean = false) {
    await NewtonApi.request(`discourse/scheduledtasks/${id}`, 'DELETE', undefined, undefined, { shouldThrow });
  }
  static async archiveScheduledTask(id: ScheduledTask['id'], status: string, shouldThrow: boolean = false) {
    await NewtonApi.request(
      `user/me/scheduled-tasks/${id}/status`,
      'PATCH',
      { status } as unknown as BodyInit,
      undefined,
      { shouldThrow },
    );
  }

  static async fetchTimeZones(params: { [key: string]: string | string[] } = {}, shouldThrow: boolean = false) {
    const queryParams = new URLSearchParams({
      page: '1',
      page_size: '100',
      ...params,
    });
    const response = await NewtonApi.request(
      `discourse/scheduledtasks/timezones?${queryParams}`,
      'GET',
      undefined,
      undefined,
      { shouldThrow },
    );
    return await response.json();
  }

  static async fetchScheduledTask(id: ScheduledTask['id'], shouldThrow: boolean = false) {
    const response = await NewtonApi.request(`discourse/scheduledtasks/${id}`, 'GET', undefined, undefined, {
      shouldThrow,
    });
    return await response.json();
  }

  static async fetchDatasourceTypes(page: number = 1, pageSize: number = 1000) {
    const queryString = new URLSearchParams({
      page: page.toString(),
      page_size: pageSize.toString(),
    });

    const response = await NewtonApi.request(`memory/datasources/type?${queryString}`, 'GET', undefined, undefined, {
      shouldThrow: true,
    });
    return await response.json();
  }
}
