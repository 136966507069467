import { Avatar, Flex, Menu, Spin, Typography } from 'antd';
import styles from 'components/sidebar/Sidebar.module.scss';
import { TimeAgo } from 'components/timeago/TimeAgo';
import { motion } from 'framer-motion';
import React, { useMemo } from 'react';
import { useConversationStatus } from 'selectors/useConversationSelectors';
import { cx } from 'utils/cx';

import Icon from '@ant-design/icons';
import CheckCircleIcon from 'assets/iconsNewVersion/check_circle.svg?react';
import SpinnerIcon from 'assets/iconsNewVersion/spinner.svg?react';
import { ConversationsActionMenu } from 'components/conversations-actions-menu/ConversationsActionMenu';
import { ProjectLabels } from 'components/project-labels/ProjectLabels';
import { useSharedEntitiesById } from 'selectors/useSharedEntitiesSelector';
import { ConversationStatusEnum } from 'types/enum';

type Props = {
  active: boolean;
  conversation: Conversation;
  conversationFilter?: string;
  disabled?: boolean;
  index: number;
  onSelect(conv: Conversation): void;
  key: string;
};

export const SidebarItem: React.FC<Props> = ({ active, conversation, disabled, onSelect }) => {
  const { notifications, streaming, unread } = useConversationStatus(conversation?.id);
  const sharedEntityById = useSharedEntitiesById;
  const owner = sharedEntityById(conversation.owner!);
  const key = conversation.id;

  const showActionsMenu = useMemo(() => {
    if (conversation?.status === ConversationStatusEnum.ARCHIVED) {
      return false;
    }
    return true;
  }, [conversation.status]);

  return (
    <motion.li
      className={styles.sidebarItemContainer}
      key={key}
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, x: -20 }}
      layout
      transition={{ duration: 0.2, ease: 'easeInOut' }}
    >
      <Menu.Item
        className={cx(styles.sidebarItem, { [styles.active]: active })}
        disabled={disabled}
        onClick={() => {
          if (!disabled) {
            onSelect(conversation);
          }
        }}
        key={key}
      >
        <div className={styles.pill}>
          <Typography.Text className={styles.projectTag}>
            <ProjectLabels projectLabels={conversation.projectLabels} />
          </Typography.Text>
          <Flex justify="space-between" align="center">
            <Typography.Text className={styles.conversationName}>{conversation.name}</Typography.Text>
            <Flex align="center">
              <TimeAgo className={styles.timeago} date={conversation.updatedAt} />
              {showActionsMenu && (
                <ConversationsActionMenu className={styles.conversationsActionMenu} conversation={conversation} />
              )}
            </Flex>
          </Flex>
          <Typography.Paragraph className={styles.description} ellipsis={{ rows: 2 }}>
            {conversation.currentStatusSummary || conversation.description}
          </Typography.Paragraph>
          <Typography.Text className={styles.status}>
            <div className={styles.statusNotification}>
              {streaming ? (
                <>
                  <Spin
                    size="small"
                    indicator={
                      <Icon
                        component={SpinnerIcon}
                        style={{
                          display: 'flex',
                        }}
                        spin
                      />
                    }
                  />
                  {notifications?.length ? (
                    <>notifications[notifications.length-1].message</>
                  ) : (
                    'Newton is working on request'
                  )}
                </>
              ) : unread?.length ? (
                <>
                  <CheckCircleIcon height={16} width={16} color="var(--nri-color-olive-green)" />
                  Newton has completed the user request
                </>
              ) : null}
            </div>
            <Flex justify="center" align="center" className={styles.avatarContainer}>
              <Avatar src={owner?.picture} size={22} />
            </Flex>
          </Typography.Text>
        </div>
        <div className={styles.right} style={{ right: '16px' }}></div>
      </Menu.Item>
    </motion.li>
  );
};
