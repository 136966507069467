import { Button } from 'antd';
import { FC, useCallback, useState } from 'react';
import { Modal } from 'ui/Modal';

import { ModalScopesEnum, ModalTypesEnum } from 'reducers/modalsReducer';
import { useModal } from 'selectors/useModals';
import { DataSourceList } from 'components/datasource-list/DataSourceList';

export type SelectDataSourcesModal = {
  locked: DataSource[];
  selections: DataSource[];
  onConfirm(dataSources: DataSource[]): void;
};

export const SelectExistingDatasourceModal: FC = () => {
  const {
    hide,
    data: { locked = [], selections = [], onConfirm = () => {} },
  } = useModal<SelectDataSourcesModal>(ModalScopesEnum.DATA_SOURCES, ModalTypesEnum.SELECT);
  const [initialSelections] = useState<DataSource[]>(selections);
  const [dataSourceSelections, setDataSourceSelections] = useState<DataSource[]>(selections);

  const handleAddSelected = useCallback(async () => {
    onConfirm(dataSourceSelections);
    hide();
  }, [dataSourceSelections, hide, onConfirm]);

  const onNewEntityConfirm = useCallback(
    (dataSource: DataSource) => {
      setDataSourceSelections(prev => [...prev, dataSource]);
    },
    [setDataSourceSelections],
  );

  const selectedIds = dataSourceSelections.map(ds => ds.id);
  const initialIds = initialSelections.map(ds => ds.id);
  const initialIdsRemoved = initialIds.filter(x => !selectedIds.includes(x));
  const newSelectionIds = selectedIds.filter(x => !initialIds.includes(x));

  // Checks if there is a difference if so allows save button to be enabled.
  const disabled = initialIdsRemoved.length === 0 && newSelectionIds.length === 0;

  const addText =
    newSelectionIds.length === 0
      ? ''
      : `Add ${newSelectionIds.length} connector${newSelectionIds.length > 1 ? 's' : ''}`;
  const removeText =
    initialIdsRemoved.length === 0
      ? ''
      : `Remove ${initialIdsRemoved.length} connector${initialIdsRemoved.length > 1 ? 's' : ''}`;

  return (
    <Modal
      title="Add connector"
      description="Here you can edit connectors"
      closeIcon={false}
      open
      onCancel={hide}
      footer={
        <>
          <Button onClick={hide}>Cancel</Button>
          <Button disabled={disabled} onClick={handleAddSelected} type="primary">
            {disabled ? 'Add connector' : ''}
            {`${addText}${addText && removeText ? `, ${removeText.toLowerCase()}` : removeText}`}
          </Button>
        </>
      }
      width={`var(--nri-modal-size-lg)`}
      style={{
        minWidth: '800px',
        maxHeight: '40vh',
      }}
    >
      <DataSourceList
        selected={dataSourceSelections}
        lockedSelections={locked}
        onSelect={setDataSourceSelections}
        onNewEntityConfirm={onNewEntityConfirm}
        projectFilter={false}
        viewOptions={false}
      />
    </Modal>
  );
};
