import { message } from 'antd';
import { useAppContext } from 'contexts/AppProviders';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ConversationsActionTypes } from 'reducers/conversationsReducer';
import { ConversationFeedbacksContainer, FeedbackActionTypes } from 'reducers/feedbackReducer';
import { CommonStatusEnum, ConversationFeedbacksViewEnum, FeedbackScopeEnum, Roles, RoleValues } from 'types/enum';
import { NewtonApi } from 'utils/newtonApi';

const ViewToRole: Partial<Record<ConversationFeedbacksViewEnum, Array<RoleValues>>> = {
  [ConversationFeedbacksViewEnum.ALL]: [Roles.USER, Roles.ASSISTANT, Roles.USER_PROXY],
  [ConversationFeedbacksViewEnum.EXECUTIVE]: [Roles.USER, Roles.SUMMARY],
};

const useFeedbackState = () => {
  const {
    state: { feedbacksState },
  } = useAppContext();
  return feedbacksState;
};

const useFeedbacksListState = (page: number) => {
  const {
    state: { feedbacksState },
    dispatch,
  } = useAppContext();
  const { status: feedbacksListStatus } = feedbacksState.feedbacks;

  useEffect(() => {
    if (feedbacksListStatus === CommonStatusEnum.INITIAL) {
      (async () => {
        dispatch({
          type: FeedbackActionTypes.FETCHING_ALL_FEEDBACKS,
        });
        const data = await NewtonApi.fetchAllFeedbacks(page);
        const { results, ...rest } = data;
        dispatch({
          type: FeedbackActionTypes.FETCH_ALL_FEEDBACKS,
          payload: { results, pagination: rest },
        });
      })();
    }
    return () => {
      if (feedbacksListStatus === CommonStatusEnum.FETCHED)
        dispatch({
          type: FeedbackActionTypes.CLEAN_ALL_FEEDBACKS,
        });
    };
  }, [dispatch, feedbacksListStatus, page]);

  return { state: feedbacksState };
};

export const useFeedbacks = (page: number) => {
  const {
    state: { feedbacks },
  } = useFeedbacksListState(page);

  const loading = useMemo(
    () => feedbacks.status === CommonStatusEnum.FETCHING || feedbacks.status === CommonStatusEnum.INITIAL,
    [feedbacks.status],
  );

  return { ...feedbacks, loading };
};

export const useCleanAllFeedbacks = () => {
  const { dispatch } = useAppContext();
  return useCallback(() => dispatch({ type: FeedbackActionTypes.CLEAN_ALL_FEEDBACKS }), [dispatch]);
};

export const useCreateFeedback = () => {
  const { dispatch } = useAppContext();

  return useCallback(
    async (feedback: Feedback, messageId: MessageId, location: string) => {
      const data = await NewtonApi.sendFeedback(feedback, messageId);
      const message = await NewtonApi.fetchMessage(messageId, FeedbackScopeEnum.USER);

      location.includes('feedbacks')
        ? dispatch({
            type: FeedbackActionTypes.UPDATE_CONVERSATION_FEEDBACKS_MESSAGE,
            payload: { message },
          })
        : dispatch({
            type: ConversationsActionTypes.INCOMING_STREAMING_MESSAGE,
            payload: {
              conversationId: message.conversationId,
              message,
            },
          });

      return data;
    },
    [dispatch],
  );
};

export const useFetchFeedbackSentiments = () => {
  const { dispatch } = useAppContext();

  return useCallback(async () => {
    const data = await NewtonApi.fetchFeedbackSentiments();
    dispatch({
      type: FeedbackActionTypes.FETCH_FEEDBACK_SENTIMENTS,
      payload: data.results,
    });
    return data;
  }, [dispatch]);
};

export const useFeedbackSentiments = () => {
  const feedbackState = useFeedbackState();
  return feedbackState.sentiments;
};

export const useConversationFeedbacksContainer = () => {
  const {
    state: { conversationsState },
  } = useAppContext();

  return useCallback(
    (id: Conversation['id']): (ConversationFeedbacksContainer & { loading: boolean }) | undefined => {
      if (id && conversationsState.conversations.get(id)) {
        const { conversation, status } = conversationsState.conversations.get(id)!;

        return {
          conversation,
          status,
          loading: conversationsState.conversations.get(id)!.status === CommonStatusEnum.FETCHING,
        };
      }
      return undefined;
    },
    [conversationsState.conversations],
  );
};

export const useConversationFeedbacks = () => {
  const {
    state: { feedbacksState },
    dispatch,
  } = useAppContext();
  const navigate = useNavigate();
  return useCallback(
    async (
      id: Conversation['id'],
      { skipNavigate = false }: { skipNavigate?: boolean } = {},
      messageId?: MessageId,
    ) => {
      const activeConversationFeedbacks = feedbacksState.conversationFeedbacks.data[id];

      if (!activeConversationFeedbacks) {
        (async () => {
          dispatch({
            type: FeedbackActionTypes.FETCHING_CONVERSATION_FEEDBACKS,
            payload: id,
          });
          try {
            const conversation = await NewtonApi.fetchConversation(
              id,
              { feedback_scope: FeedbackScopeEnum.ALL, message_id: messageId as unknown as string },
              true,
            );

            dispatch({
              type: FeedbackActionTypes.FETCH_CONVERSATION_FEEDBACKS,
              payload: { conversationId: id, conversation },
            });

            if (!skipNavigate) {
              navigate(`/memories/feedbacks/${id}?message_id=${messageId}`);
            }
          } catch (error) {
            if ((error as Response)?.status === 404) {
              navigate('/memories');
              message.error(`Feedbacks for conversation ${id} not found`);
            } else {
              message.error('Failed to load conversation');
            }
          }
        })();
      } else {
        if (!skipNavigate) {
          navigate(`/memories/feedbacks/${id}?message_id=${messageId}`);
        }
      }
    },
    [dispatch, navigate],
  );
};

export const useConversationFeedbacksPageView = () => {
  const {
    dispatch,
    state: { feedbacksState },
  } = useAppContext();
  const {
    page: { activeTab },
  } = feedbacksState;

  const setActiveTab = useCallback(
    (tab: ConversationFeedbacksViewEnum) => {
      dispatch({
        type: FeedbackActionTypes.SET_PAGE_ACTIVE_TAB,
        payload: tab,
      });
    },
    [dispatch],
  );

  return {
    activeTab,
    setActiveTab,
  };
};
export const useActiveConversationFeedbacks = () => {
  const {
    state: { feedbacksState },
  } = useAppContext();
  const { conversationId } = useParams();

  const { status, data } = feedbacksState.conversationFeedbacks;
  if (!conversationId)
    return {
      conversation: undefined,
      loading: true,
    };
  return {
    loading: status === CommonStatusEnum.FETCHING || status === CommonStatusEnum.INITIAL,
    conversation: data[conversationId!]?.conversation,
  };
};
export const useConversationFeedbackMessages = (conversationId: Conversation['id'] | undefined) => {
  const { activeTab } = useConversationFeedbacksPageView();
  const {
    state: { feedbacksState },
  } = useAppContext();
  let conversation: Conversation | undefined = undefined;

  if (conversationId) {
    conversation = feedbacksState.conversationFeedbacks.data[conversationId]?.conversation;
  }

  return {
    messages: useMemo(() => {
      if (!conversation) return [];
      return (conversation?.messageSet || []).filter(message => {
        return ViewToRole[activeTab]?.includes(message.role as RoleValues);
      });
    }, [activeTab, conversation]),
  };
};
