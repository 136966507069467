import { PageSection } from 'components/page-section/PageSection';
import { ScheduledTasksView } from 'components/scheduled-tasks-view/ScheduledTasksView';
import { useState } from 'react';
import { ModalScopesEnum, ModalTypesEnum } from 'reducers/modalsReducer';
import { useModal } from 'selectors/useModals';
import { ScheduledTasksFilterTypesEnum } from 'types/enum';
import { Button } from 'ui/Button';
import { tasksfilterOptions as filterOptions } from 'utils/scheduledTask';

export const ScheduledTasksPage = () => {
  const { show: ShowManageTaskModal } = useModal(ModalScopesEnum.SCHEDULED_TASKS, ModalTypesEnum.MANAGE);
  const [filter, setFilter] = useState<ScheduledTasksFilterTypesEnum>(filterOptions[0].value);
  const [page, setPage] = useState(1);

  return (
    <PageSection
      heading="Schedule work"
      button={
        <Button type="primary" onClick={() => ShowManageTaskModal({ page, filter })}>
          Schedule task
        </Button>
      }
    >
      <ScheduledTasksView filter={filter} page={page} setFilter={setFilter} setPage={setPage} />
    </PageSection>
  );
};
