import { Col, ConfigProvider, Divider, Flex, Progress, Row, Table, Typography } from 'antd';
import styles from './UsageDetails.module.scss';
import { FC, useMemo } from 'react';
import classNames from 'classnames';
import { ResponsivePie } from '@nivo/pie';
import { Loader } from 'components/loader/Loader';
import { UsageEntityNameEnum } from 'types/enum';
import { formatNumberWithSpace } from 'utils/FormatWithSpace';
import { ChartTooltip, ChartTooltipData } from 'components/usage-chart-tooltip/ChartTooltip';
import { ProjectLabel } from 'ui/ProjectLabel';

const capitalize = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

type PieChartProps = {
  data: CreditDetailData[];
};
type UsageDetailsListProps = {
  creditInfo: CreditDetailData[];
  entityName: UsageEntityNameEnum;
  loading?: boolean;
  pieData: CreditDetailData[];
  piePercentage?: number | string;
};

const PieChart: FC<PieChartProps> = ({ data }) => {
  const chartData = useMemo(
    () =>
      data.map(item => ({
        id: item.entityName,
        value: item.totalTokensCreditPercentage,
        credits: item.totalTokensCredit,
        color: item.color,
      })),
    [data],
  );

  const usedTotal = useMemo(() => chartData.reduce((sum, item) => sum + item.value!, 0), [chartData]);
  const unusedValue = useMemo(() => 100 - usedTotal, [usedTotal]);
  const completeData = useMemo(() => [...chartData, { id: 'unused', value: unusedValue }], [chartData, unusedValue]);

  return (
    <ResponsivePie
      data={completeData}
      innerRadius={0.87}
      padAngle={chartData.length > 1 ? 2 : 0}
      cornerRadius={8}
      valueFormat={value => `${value}%`}
      enableArcLabels={false}
      enableArcLinkLabels={false}
      tooltip={({ datum: { data } }) => {
        return data.id === 'unused' ? (
          false
        ) : (
          <ChartTooltip name={'<no project assigned>'} data={data as ChartTooltipData} />
        );
      }}
      animate={false}
      colors={({ id, data }) => {
        if (id === 'unused') return 'var( --nri-color-light-grey)';
        return (data as CreditDetailData).color || 'var(--nri-color-purple-grey)';
      }}
    />
  );
};

export const UsageDetailsList: FC<UsageDetailsListProps> = ({
  creditInfo,
  entityName,
  loading,
  pieData,
  piePercentage,
}) => {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'entityName',
      key: 'name',
      ellipsis: true,
      render: ({ name, isDeleted, color }: { name: string; isDeleted: boolean; color?: string }) => {
        return color ? (
          <ProjectLabel color={color} text={name} />
        ) : (
          <Typography.Text delete={isDeleted}>{name || '<no project assigned>'}</Typography.Text>
        );
      },
      width: 'auto',
    },
    {
      title: `% of ${entityName === UsageEntityNameEnum.CONVERSATION ? 'my' : 'all'} usage`,
      dataIndex: 'totalTokensCreditPercentage',
      key: 'percentage',
      render: (value: number) => (
        <Progress status="active" percent={value} size="small" strokeColor="var(--nri-color-purple-grey)" />
      ),
      width: 250,
    },
    {
      title: 'Credits',
      dataIndex: 'totalTokensCredit',
      key: 'credits',
      ellipsis: true,
      render: (value: number) => <Typography.Text>{formatNumberWithSpace(value)}</Typography.Text>,
      width: 100,
    },
  ];

  const pieChartName = useMemo(() => {
    if (entityName === UsageEntityNameEnum.PROJECT) return 'Project';
    return capitalize(entityName);
  }, [entityName]);

  return (
    <div className={styles.content}>
      {loading ? (
        <Loader size="default" className={styles.loader} />
      ) : (
        <>
          <Row>
            <Col className={classNames(styles.column)} span={15}>
              <ConfigProvider
                theme={{
                  components: {
                    Table: {
                      headerColor: 'var(--nri-color-purple-grey)',
                      headerSplitColor: 'transparent',
                      cellPaddingBlock: 8,
                      cellPaddingInline: 8,
                      headerBorderRadius: 0,
                      borderColor: 'transparent',
                      headerBg: 'transparent',
                    },
                  },
                }}
              >
                <Table
                  columns={columns}
                  className={styles.usageTable}
                  rowClassName={styles.tableRow}
                  dataSource={creditInfo.map(item => {
                    return {
                      key: item.id,
                      ...item,
                      entityName: { name: item.entityName, isDeleted: item.isDeleted, color: item.color },
                    };
                  })}
                  tableLayout="fixed"
                  scroll={{ y: 'var(--nri-usage-list-table-overflow-y)' }}
                  pagination={false}
                  locale={{
                    emptyText: (
                      <Flex justify="center" align="center" className={styles.empty}>
                        No data
                      </Flex>
                    ),
                  }}
                />
              </ConfigProvider>
            </Col>
            <Col className={styles.dividerColumn} span={1}>
              <Divider type="vertical" className={styles.divider} />
            </Col>
            <Col className={styles.column} span={8}>
              <div className={styles.pieChart}>
                <PieChart data={pieData} />
                <Flex className={styles.pieLegend} vertical align="center">
                  {piePercentage ? (
                    <>
                      <Typography.Text className={styles.legendDescription}>My conversations</Typography.Text>
                      <Typography.Text className={styles.legendPercentage}>{`${piePercentage}%`}</Typography.Text>
                      <Typography.Text className={styles.legendDescription}>of all org usage</Typography.Text>
                    </>
                  ) : (
                    <>
                      <Typography.Text className={styles.legendDescription}>Credit Usage</Typography.Text>
                      <Typography.Text className={styles.legendDescription}>by {pieChartName}</Typography.Text>
                    </>
                  )}
                </Flex>
              </div>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};
