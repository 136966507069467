import { CheckOutlined } from '@ant-design/icons';
import { Flex, Tag } from 'antd';
import { DataSourceActionsMenu } from 'components/datasource-actions-menu/DatasourceActionsMenu';
import { EntityCard } from 'components/entity-list/EntityCard';
import { BaseEntityCardProps } from 'components/entity-list/EntityList';
import { MultipleElements } from 'components/multiple-labels/MultipleElements';
import { useProjectLabels } from 'selectors/useProjectLabels';
import { Button } from 'ui/Button';
import { ProjectLabel } from 'ui/ProjectLabel';
import { highlightText } from 'utils/highlightText';
import { useDataSourceTypes } from 'selectors/useDataSourcesSelector';

type DatasourceCardProps = BaseEntityCardProps<DataSource>;

export const DatasourceCard = ({
  disabled = false,
  entity: dataSource,
  isSelectable = true,
  isSelected,
  onClick: onToggle,
  search = '',
}: DatasourceCardProps) => {
  const { projectLabels: labels } = useProjectLabels(dataSource.projectLabels.map(projectLabel => projectLabel.id));
  const { typesDataObject: typesObject } = useDataSourceTypes();

  const { name, description, type } = dataSource;
  const tagInfo = typesObject[type];
  const Icon = tagInfo?.icon;

  return (
    <EntityCard
      actionsMenu={<DataSourceActionsMenu dataSource={dataSource} />}
      description={highlightText(description, search)}
      footer={
        <>
          <Flex gap="8px" align="center" justify="flex-start" wrap="wrap">
            <Flex>
              {tagInfo ? (
                <Tag
                  bordered
                  color={tagInfo.textColor}
                  style={{
                    backgroundColor: tagInfo.backgroundColor,
                    borderColor: tagInfo.textColor,
                    color: tagInfo.textColor,
                  }}
                >
                  <Flex align="center" gap="4px">
                    {Icon && <Icon style={{ width: '16px', height: '16px' }} />}
                    {tagInfo.description}
                  </Flex>
                </Tag>
              ) : (
                <span />
              )}
            </Flex>{' '}
            <MultipleElements>
              {labels.map((label: ProjectLabel) => (
                <ProjectLabel
                  key={label.id}
                  color={label.color}
                  text={label.name}
                  textColor="var(--nri-color-primary)"
                />
              ))}
            </MultipleElements>
          </Flex>
          {isSelectable && (
            <Button
              disabled={disabled}
              icon={isSelected ? <CheckOutlined /> : undefined}
              onClick={() => onToggle?.(dataSource)}
              type="text"
            >
              {isSelected ? 'Selected' : 'Select'}
            </Button>
          )}
        </>
      }
      selected={isSelected}
      title={highlightText(name, search)}
    />
  );
};
