import { ConfigProvider, SelectProps, Select as AntSelect, Tag, Space } from 'antd';
import classNames from 'classnames';
import styles from 'ui/UIKit.module.scss';
import React from 'react';

const SelectComponent: React.FC<SelectProps> = ({
  className,
  mode,
  value,
  options,
  ...props
}) => {
  const selectedItemsContainerRef = React.useRef<HTMLDivElement>(null);
  const isMultipleSelect = mode === 'multiple';

  // Used for multiple select
  const renderSelectedItems = () => {
    if (!isMultipleSelect || !value || !options) return null;
    const selectedValues = Array.isArray(value) ? value : value ? [value] : [];
    if (selectedValues.length === 0) return null;

    return (
      <Space
        style={{ width: '100%' }}
        wrap
        ref={selectedItemsContainerRef}
        className={classNames(styles.selectedItemsContainer)}
      >
        {selectedValues.map(val => {
          const option = options.find(opt => typeof opt === 'object' && 'value' in opt && opt.value === val);
          const label = option && typeof option === 'object' && 'label' in option ? option.label : val;

          return (
            <Tag
              key={val.toString()}
              closable={mode === 'multiple' || mode === 'tags'}
              onClose={() => {
                if (props.onDeselect) {
                  props.onDeselect(val, []);
                }
                if (props.onChange) {
                  const newValue = Array.isArray(value) ? value.filter(v => v !== val) : undefined;
                  props.onChange(newValue, []);
                }
              }}
            >
              {label}
            </Tag>
          );
        })}
      </Space>
    );
  };

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorText: 'var(--nri-color-primary)',
            colorTextPlaceholder: 'var(--nri-color-purple-grey)',
            // @ts-expect-error type string is not assignable to type number, still works
            controlHeight: '42px',
            // @ts-expect-error type string is not assignable to type number, still works
            borderRadius: '4px',
            // @ts-expect-error type string is not assignable to type number, still works
            lineHeight: '24px',
          },
          components: {
            Select: {
              activeBorderColor: 'var(--nri-color-primary-light)',
              hoverBorderColor: 'var(--nri-color-purple-grey)',
              // @ts-expect-error type string is not assignable to type number, still works
              optionHeight: '36px',
              optionLineHeight: '24px',
              optionPadding: '8px 12px',
              optionSelectedColor: 'var(--nri-color-purple-grey)',
            },
          },
        }}
      >
        <Space direction="vertical" style={{ width: '100%' }}>
          <Space.Compact style={{ width: '100%' }}>
            <AntSelect
              className={classNames(styles.selectSimple, className)}
              mode={mode}
              value={value}
              options={options}
              tagRender={isMultipleSelect ? () => <div /> : undefined}
              {...props}
            />
          </Space.Compact>
          {renderSelectedItems()}
        </Space>
      </ConfigProvider>
    </>
  );
};

export const Select = Object.assign(SelectComponent, {
  Option: AntSelect.Option,
  OptGroup: AntSelect.OptGroup,
});
