import { CommonStatusEnum, RetrieveTypesEnum } from 'types/enum';
import { Actions } from '../types/actionTypes';

export enum DatasourceUploadType {
  ORGANIZATION = 'organization',
  USER = 'user-defined',
  CONVERSATION = 'conversation',
}

type DataSourceMessageContainer = {
  dataSources: DataSource[];
  dataSourceByMessageId: Map<Message['id'], DataSource[]>;
};

type ScopedDataSourceContainer = {
  dataSources: Map<DataSource['id'], DataSource>;
  pagination: PaginationType;
  status: CommonStatusEnum;
};

type DataSourcePage = {
  lastScope: RetrieveTypesEnum;
  selectedDataSources: DataSource[];
};

export interface DataSourcesState {
  page: DataSourcePage;
  scope: {
    [RetrieveTypesEnum.ALL]: ScopedDataSourceContainer;
    [RetrieveTypesEnum.ORGANIZATION]: ScopedDataSourceContainer;
    [RetrieveTypesEnum.OWNED]: ScopedDataSourceContainer;
    [RetrieveTypesEnum.FAVORITE]: ScopedDataSourceContainer;
    [RetrieveTypesEnum.RECENT]: ScopedDataSourceContainer;
    [RetrieveTypesEnum.SHARED]: ScopedDataSourceContainer;
    conversations: Map<Conversation['id'], DataSourceMessageContainer>;
  };
  activeDatasource: {
    data: DataSource | undefined;
    permissions: ShareableEntity[];
    file: File | undefined;
    status: CommonStatusEnum;
  };
  dataSourceTypes: { types: Map<DataSourceTypeData['id'], DataSourceTypeData>; status: CommonStatusEnum };
  status: CommonStatusEnum;
  loading: boolean;
}

export const initialDataSourcesState: DataSourcesState = {
  page: {
    lastScope: RetrieveTypesEnum.ALL,
    selectedDataSources: [],
  },
  scope: {
    [RetrieveTypesEnum.ALL]: {
      dataSources: new Map(),
      pagination: {
        next: null,
        previous: null,
        count: 0,
      },
      status: CommonStatusEnum.INITIAL,
    },
    [RetrieveTypesEnum.FAVORITE]: {
      dataSources: new Map(),
      pagination: {
        next: null,
        previous: null,
        count: 0,
      },
      status: CommonStatusEnum.INITIAL,
    },
    [RetrieveTypesEnum.ORGANIZATION]: {
      dataSources: new Map(),
      pagination: {
        next: null,
        previous: null,
        count: 0,
      },
      status: CommonStatusEnum.INITIAL,
    },
    [RetrieveTypesEnum.OWNED]: {
      dataSources: new Map(),
      pagination: {
        next: null,
        previous: null,
        count: 0,
      },
      status: CommonStatusEnum.INITIAL,
    },
    [RetrieveTypesEnum.RECENT]: {
      dataSources: new Map(),
      pagination: {
        next: null,
        previous: null,
        count: 0,
      },
      status: CommonStatusEnum.INITIAL,
    },
    [RetrieveTypesEnum.SHARED]: {
      dataSources: new Map(),
      pagination: {
        next: null,
        previous: null,
        count: 0,
      },
      status: CommonStatusEnum.INITIAL,
    },
    conversations: new Map(),
  },
  activeDatasource: { data: undefined, file: undefined, permissions: [], status: CommonStatusEnum.INITIAL },
  dataSourceTypes: { types: new Map<DataSourceTypeData['id'], DataSourceTypeData>(), status: CommonStatusEnum.INITIAL },
  status: CommonStatusEnum.INITIAL,
  loading: true,
};

export enum DataSourcesActionTypes {
  ARCHIVE_DATASOURCE = 'ARCHIVE_DATASOURCE',
  DELETE_DATASOURCE = 'DELETE_DATASOURCE',
  FETCH_CONVERSATION_DATASOURCES = 'FETCH_CONVERSATION_DATASOURCES',
  FETCHING_DATASOURCES = 'FETCHING_DATASOURCES',
  FETCH_DATASOURCES = 'FETCH_DATASOURCES',
  MANAGE_ACCESS = 'MANAGE_ACCESS',
  PAGE_SELECT_DATASOURCES = 'PAGE_SELECT_DATASOURCES',
  PAGE_SET_FORM_DATA = 'PAGE_SET_FORM_DATA',
  REPLACE_CSV = 'REPLACE_CSV',

  FILTER_DATASOURCES_REQUEST = 'FILTER_DATASOURCES_REQUEST',
  FILTER_DATASOURCES = 'FILTER_DATASOURCES',
  FILTER_DATASOURCES_CLEAN = 'FILTER_DATASOURCES_CLEAN',
  ADD_DATASOURCE = 'ADD_DATASOURCE',
  UPDATE_DATASOURCE = 'UPDATE_DATASOURCE',
  FETCHING_DATASOURCE = 'FETCHING_DATASOURCE',
  FETCH_DATASOURCE = 'FETCH_DATASOURCE',
  FETCH_DATASOURCE_PERMISSIONS = 'FETCH_DATASOURCE_PERMISSIONS',
  TOGGLE_FAVORITE_DATASOURCE = 'TOGGLE_FAVORITE_DATASOURCE',
  FETCH_DATASOURCE_FILE = 'FETCH_DATASOURCE_FILE',
  CLEAN_DATASOURCE = 'CLEAN_DATASOURCE',
  FETCHING_DATASOURCE_TYPES = 'FETCHING_DATASOURCE_TYPES',
  FETCH_DATASOURCE_TYPES = 'FETCH_DATASOURCE_TYPES',
}

export type DataSourcesAction =
  | { type: DataSourcesActionTypes.ARCHIVE_DATASOURCE; payload: DataSource['id'] }
  | { type: DataSourcesActionTypes.DELETE_DATASOURCE; payload: DataSource['id'] }
  | {
      type: DataSourcesActionTypes.FETCH_CONVERSATION_DATASOURCES;
      payload: {
        conversationId: Conversation['id'];
        clearExisting?: boolean;
        dataSources?: DataSource[];
        dataSourceMessageContainers?: {
          id: Message['id'];
          dataSources: DataSource[];
        }[];
      };
    }
  | { type: DataSourcesActionTypes.FETCHING_DATASOURCES; payload: RetrieveTypesEnum }
  | {
      type: DataSourcesActionTypes.FETCH_DATASOURCES;
      payload: {
        dataSources: DataSource[];
        pagination: PaginationType;
        retrieveType: RetrieveTypesEnum;
      };
    }
  | { type: DataSourcesActionTypes.MANAGE_ACCESS; payload: DataSource }
  | { type: DataSourcesActionTypes.PAGE_SELECT_DATASOURCES; payload: DataSource[] }
  | { type: DataSourcesActionTypes.FILTER_DATASOURCES_REQUEST }
  | { type: DataSourcesActionTypes.FILTER_DATASOURCES; payload: DataSource[] }
  | { type: DataSourcesActionTypes.FILTER_DATASOURCES_CLEAN }
  | { type: DataSourcesActionTypes.ADD_DATASOURCE; payload: DataSource }
  | { type: DataSourcesActionTypes.UPDATE_DATASOURCE; payload: { data: DataSource; csv: File | undefined } }
  | { type: DataSourcesActionTypes.DELETE_DATASOURCE; payload: DataSource['id'] }
  | { type: DataSourcesActionTypes.FETCHING_DATASOURCE }
  | { type: DataSourcesActionTypes.FETCH_DATASOURCE; payload: DataSource }
  | {
      type: DataSourcesActionTypes.FETCH_DATASOURCE_PERMISSIONS;
      payload: { entities: ShareableEntity[]; ownerId: number };
    }
  | { type: DataSourcesActionTypes.TOGGLE_FAVORITE_DATASOURCE; payload: boolean }
  | { type: DataSourcesActionTypes.FETCH_DATASOURCE_FILE; payload: File }
  | { type: DataSourcesActionTypes.CLEAN_DATASOURCE }
  | { type: DataSourcesActionTypes.FETCHING_DATASOURCE_TYPES }
  | { type: DataSourcesActionTypes.FETCH_DATASOURCE_TYPES; payload: DataSourceTypeData[] };

export const dataSourcesReducer = (state: DataSourcesState, action: Actions, userId?: UserId): DataSourcesState => {
  switch (action.type) {
    case DataSourcesActionTypes.ARCHIVE_DATASOURCE: {
      const scope = state.scope;
      const dataSourceId = action.payload;
      const dataSource = scope[RetrieveTypesEnum.ALL].dataSources.get(dataSourceId);
      if (!dataSource) return state;
      return {
        ...state,
        scope: {
          ...scope,
          [RetrieveTypesEnum.ALL]: {
            ...scope[RetrieveTypesEnum.ALL],
            dataSources: new Map([...scope[RetrieveTypesEnum.ALL].dataSources].filter(([key]) => key !== dataSourceId)),
          },
          [RetrieveTypesEnum.ORGANIZATION]: {
            ...scope[RetrieveTypesEnum.ORGANIZATION],
            dataSources: new Map(
              [...scope[RetrieveTypesEnum.ORGANIZATION].dataSources].filter(([key]) => key !== dataSourceId),
            ),
          },
          [RetrieveTypesEnum.OWNED]: {
            ...scope[RetrieveTypesEnum.OWNED],
            dataSources: new Map(
              [...scope[RetrieveTypesEnum.OWNED].dataSources].filter(([key]) => key !== dataSourceId),
            ),
          },
        },
      };
    }
    case DataSourcesActionTypes.DELETE_DATASOURCE: {
      const scope = state.scope;
      const dataSourceId = action.payload;
      const dataSource = scope[RetrieveTypesEnum.ALL].dataSources.get(dataSourceId);
      if (!dataSource) return state;
      return {
        ...state,
        scope: {
          ...scope,
          [RetrieveTypesEnum.ALL]: {
            ...scope[RetrieveTypesEnum.ALL],
            dataSources: new Map([...scope[RetrieveTypesEnum.ALL].dataSources].filter(([key]) => key !== dataSourceId)),
          },
          [RetrieveTypesEnum.ORGANIZATION]: {
            ...scope[RetrieveTypesEnum.ORGANIZATION],
            dataSources: new Map(
              [...scope[RetrieveTypesEnum.ORGANIZATION].dataSources].filter(([key]) => key !== dataSourceId),
            ),
          },
          [RetrieveTypesEnum.OWNED]: {
            ...scope[RetrieveTypesEnum.OWNED],
            dataSources: new Map(
              [...scope[RetrieveTypesEnum.OWNED].dataSources].filter(([key]) => key !== dataSourceId),
            ),
          },
        },
      };
    }

    case DataSourcesActionTypes.FETCH_CONVERSATION_DATASOURCES: {
      const {
        conversationId,
        dataSources = [],
        dataSourceMessageContainers = [],
        clearExisting = false,
      } = action.payload;
      const conversations = new Map([...state.scope.conversations]);

      const existingDatasources = clearExisting ? [] : conversations.get(conversationId)?.dataSources || [];
      const existingDatasourcesByMessageId = clearExisting
        ? new Map<Message['id'], DataSource[]>()
        : conversations.get(conversationId)?.dataSourceByMessageId || new Map();

      const uniqueDataSources = new Map<DataSource['id'], DataSource>(
        [...existingDatasources, ...dataSources].map((ds): [DataSource['id'], DataSource] => [ds.id, ds]),
      );

      const container: DataSourceMessageContainer = {
        dataSources: Array.from(uniqueDataSources.values()),
        dataSourceByMessageId: dataSourceMessageContainers.reduce((acc, { id, dataSources }) => {
          const existing = existingDatasourcesByMessageId.get(id) || [];
          const uniqueMessageDataSources = new Map<DataSource['id'], DataSource>(
            [...existing, ...dataSources].map((ds): [DataSource['id'], DataSource] => [ds.id, ds]),
          );
          acc.set(id, Array.from(uniqueMessageDataSources.values()));
          return acc;
        }, existingDatasourcesByMessageId),
      };

      conversations.set(conversationId, container);

      return {
        ...state,
        scope: {
          ...state.scope,
          conversations,
        },
      };
    }

    case DataSourcesActionTypes.FETCHING_DATASOURCES: {
      return {
        ...state,
        status: CommonStatusEnum.FETCHING,
        scope: {
          ...state.scope,
          [action.payload]: {
            ...state.scope[action.payload],
            dataSources: new Map(),
            pagination: {
              next: null,
              previous: null,
              count: 0,
            },
            status: CommonStatusEnum.FETCHING,
          },
        },
      };
    }
    case DataSourcesActionTypes.FETCH_DATASOURCES: {
      const { dataSources, pagination, retrieveType } = action.payload;
      return {
        ...state,
        loading: false,
        status: CommonStatusEnum.FETCHED,
        scope: {
          ...state.scope,
          [retrieveType]: {
            dataSources: new Map(dataSources.map(ds => [ds.id, ds])),
            pagination,
            status: CommonStatusEnum.FETCHED,
          },
        },
        page: {
          ...state.page,
          lastScope: retrieveType,
        },
      };
    }
    case DataSourcesActionTypes.PAGE_SELECT_DATASOURCES: {
      return {
        ...state,
        page: {
          ...state.page,
          selectedDataSources: action.payload,
        },
      };
    }
    case DataSourcesActionTypes.FETCHING_DATASOURCE: {
      return { ...state, activeDatasource: { ...state.activeDatasource, status: CommonStatusEnum.FETCHING } };
    }
    case DataSourcesActionTypes.FETCH_DATASOURCE: {
      const newDataSource = action.payload;

      // Update the active datasource
      const updatedActiveDatasource = {
        ...state.activeDatasource,
        data: newDataSource,
        status: CommonStatusEnum.FETCHED,
      };

      // Update the references in the various scopes
      const updatedScope = { ...state.scope };

      // Add to ALL scope if not already present
      if (!updatedScope[RetrieveTypesEnum.ALL].dataSources.has(newDataSource.id)) {
        updatedScope[RetrieveTypesEnum.ALL].dataSources.set(newDataSource.id, newDataSource);
      }

      // Add to ORGANIZATION scope if applicable
      if (newDataSource.scope === 'organization') {
        if (!updatedScope[RetrieveTypesEnum.ORGANIZATION].dataSources.has(newDataSource.id)) {
          updatedScope[RetrieveTypesEnum.ORGANIZATION].dataSources.set(newDataSource.id, newDataSource);
        }
      }

      // Add to FAVORITE scope if it's favorited
      if (newDataSource.isFavorite) {
        if (!updatedScope[RetrieveTypesEnum.FAVORITE].dataSources.has(newDataSource.id)) {
          updatedScope[RetrieveTypesEnum.FAVORITE].dataSources.set(newDataSource.id, newDataSource);
        }
      }

      // Add to OWNED scope if applicable
      if (newDataSource.owner === userId) {
        if (!updatedScope[RetrieveTypesEnum.OWNED].dataSources.has(newDataSource.id)) {
          updatedScope[RetrieveTypesEnum.OWNED].dataSources.set(newDataSource.id, newDataSource);
        }
      }

      console.log('### updatedScope', updatedScope);
      return {
        ...state,
        activeDatasource: updatedActiveDatasource,
        scope: updatedScope,
      };
    }
    case DataSourcesActionTypes.FETCH_DATASOURCE_PERMISSIONS: {
      const entities = action.payload.entities;
      const ownerId = action.payload.ownerId;
      return {
        ...state,
        activeDatasource: {
          ...state.activeDatasource,
          permissions: [...state.activeDatasource.permissions.filter(p => p.id == ownerId), ...entities],
        },
      };
    }

    case DataSourcesActionTypes.CLEAN_DATASOURCE: {
      return {
        ...state,
        activeDatasource: {
          ...initialDataSourcesState.activeDatasource,
        },
      };
    }
    case DataSourcesActionTypes.UPDATE_DATASOURCE: {
      return {
        ...state,
        activeDatasource: {
          ...state.activeDatasource,
          file: action.payload.csv,
          data: {
            ...state.activeDatasource.data,
            ...action.payload.data,
          },
        },
      };
    }

    case DataSourcesActionTypes.FETCH_DATASOURCE_FILE: {
      return { ...state, activeDatasource: { ...state.activeDatasource, file: action.payload } };
    }

    case DataSourcesActionTypes.TOGGLE_FAVORITE_DATASOURCE: {
      return {
        ...state,
        activeDatasource: {
          ...state.activeDatasource,
          data: {
            ...state.activeDatasource.data,
            isFavorite: action.payload,
          } as DataSource,
        },
      };
    }
    case DataSourcesActionTypes.FETCHING_DATASOURCE_TYPES: {
      return {
        ...state,
        dataSourceTypes: {
          ...state.dataSourceTypes,
          status: CommonStatusEnum.FETCHING,
        },
      };
    }
    case DataSourcesActionTypes.FETCH_DATASOURCE_TYPES: {
      return {
        ...state,
        dataSourceTypes: {
          status: CommonStatusEnum.FETCHED,
          types: new Map(action.payload.map(type => [type.id, type])),
        },
      };
    }
    default:
      return state;
  }
};
