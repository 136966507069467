import { ModalScopesEnum, ModalTypesEnum } from 'reducers/modalsReducer';
import { useModal } from 'selectors/useModals';
import { ConfirmationModal } from 'components/common/ConfirmationModal';
import { useCallback, useMemo, useState } from 'react';
import { useToggleArchiveConversation } from 'selectors/useConversationSelectors';
import { ConversationStatusEnum } from 'types/enum';

export const ArchiveConversationModal: React.FC = () => {
  const { data: conversation, hide } = useModal<Conversation>(ModalScopesEnum.CONVERSATIONS, ModalTypesEnum.ARCHIVE);
  const [processing, setProcessing] = useState(false);
  const status = useMemo(() => {
    return conversation.status === ConversationStatusEnum.ACTIVE
      ? ConversationStatusEnum.ARCHIVED
      : ConversationStatusEnum.ACTIVE;
  }, [conversation.status]);
  const toggleArchive = useToggleArchiveConversation();
  const onConfirm = useCallback(async () => {
    setProcessing(true);
    await toggleArchive(conversation.id, status);
    hide();
    setProcessing(false);
  }, [toggleArchive, hide, status]);

  return (
    <ConfirmationModal
      key={'conversation::delete'}
      confirmationButton={processing ? 'Archiving' : 'Confirm'}
      isOpen
      title={'Archive conversation'}
      description={
        <>
          Are you sure you want to {conversation.status === ConversationStatusEnum.ACTIVE ? 'archive' : 'unarchive'}{' '}
          this conversation?
        </>
      }
      onCancel={hide}
      onConfirm={onConfirm}
      processing={processing}
    />
  );
};
