import { Divider, Flex, Layout } from 'antd';
import classnames from 'classnames';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  useActiveConversation,
  useConversationMessages,
  useConversationPageView,
} from 'selectors/useConversationSelectors';
import { useConversationDataSources } from 'selectors/useDataSourcesSelector';
import { useFetchFeedbackSentiments } from 'selectors/useFeedbacksSelector';
import { Segmented } from 'ui/Segmented';
import { findKeysInLocalStorage } from 'utils/findKeysInLocalStorage';
import { ConversationStatusEnum, ConversationViewEnum } from '../../types/enum';
import { ConversationContent } from './ConversationContent';
import { ConversationHeader } from './ConversationHeader';
import styles from './ConversationView.module.scss';

export const ConversationView: React.FC = () => {
  const { conversation } = useActiveConversation();
  const fetchSentiments = useFetchFeedbackSentiments();
  const { activeTab, setActiveTab } = useConversationPageView();
  const { markAsRead } = useConversationMessages(conversation?.id);
  const { all: dataSources } = useConversationDataSources(conversation?.id);

  const { conversationId } = useParams();
  const isArchived = useMemo(() => conversation?.status === ConversationStatusEnum.ARCHIVED, [conversation]);

  useEffect(() => {
    fetchSentiments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    markAsRead(Number(conversationId) as ConversationId);
  }, [conversationId, markAsRead]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      findKeysInLocalStorage('feedback').forEach(key => {
        localStorage.removeItem(key);
      });
      findKeysInLocalStorage('message-set-code').forEach(key => {
        localStorage.removeItem(key);
      });
      findKeysInLocalStorage('message-set-log').forEach(key => {
        localStorage.removeItem(key);
      });
    };
  }, [conversationId]);

  return (
    <Layout
      className={classnames(styles.conversation, {
        [styles.hasDatasources]: dataSources.length,
      })}
    >
      <ConversationHeader />
      <Layout.Content className={classnames(styles.content)}>
        <div className={styles.messagePanel}>
          <Flex align="center" justify="space-between" className={styles.messagesHeader}>
            <Segmented
              onChange={view => setActiveTab(view as ConversationViewEnum)}
              options={[
                {
                  value: ConversationViewEnum.EXECUTIVE,
                  label: 'Summary',
                },
                {
                  value: ConversationViewEnum.ALL,
                  label: 'Analytics',
                },
                // {
                //   value: ConversationViewEnum.NOTES,
                //   label: 'Notes',
                // },
                {
                  value: ConversationViewEnum.DATA_SOURCES,
                  label: 'Connectors',
                },
              ]}
              value={activeTab}
            />
          </Flex>
          <Divider style={{ marginTop: 0 }} />
          <ConversationContent showPrompt={!isArchived} />
        </div>
      </Layout.Content>
    </Layout>
  );
};
