import { Avatar, Tag } from 'antd';
import styles from './UserTag.module.scss';
export const UserTag = ({ picture, name }: { picture: string; name: string }) => {
  return (
    <Tag className={styles.userTag}>
      <Avatar src={picture} size={16}>
        {name.charAt(0)}
      </Avatar>
      {name}
    </Tag>
  );
};
