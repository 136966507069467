import { Actions } from 'types/actionTypes';
import { CommonStatusEnum } from 'types/enum';

export interface StatisticsState {
  dailyCredits: {
    status: CommonStatusEnum;
    data: Map<string, DailyCreditData>;
  };
  creditsInfo: { status: CommonStatusEnum; data: UserCreditInfo };
  monthlyCredits: { status: CommonStatusEnum; data: UserCreditInfo };
  creditDetails: {
    status: CommonStatusEnum;
    data: Map<Conversation['id'] | ProjectLabel['id'] | UserEntity['id'], CreditDetailData>;
  };
  conversationsUsage: {
    status: CommonStatusEnum;
    data: Map<string, ConversationsUsageData>;
  };
  timePeriods: {
    status: CommonStatusEnum;
    data: Map<string, TimePeriodData>;
  };
}

export const initialStatisticsState: StatisticsState = {
  dailyCredits: {
    status: CommonStatusEnum.INITIAL,
    data: new Map<string, DailyCreditData>(),
  },
  creditDetails: {
    status: CommonStatusEnum.INITIAL,
    data: new Map<Conversation['id'] | ProjectLabel['id'] | UserEntity['id'], CreditDetailData>(),
  },
  creditsInfo: {
    status: CommonStatusEnum.INITIAL,
    data: { availableCredits: 0, totalCredits: 0, totalTokensCredit: 0, availableTokensPercentage: 0 },
  },
  monthlyCredits: {
    status: CommonStatusEnum.INITIAL,
    data: { availableCredits: 0, totalCredits: 0, totalTokensCredit: 0, availableTokensPercentage: 0 },
  },
  conversationsUsage: {
    status: CommonStatusEnum.INITIAL,
    data: new Map<string, ConversationsUsageData>(),
  },
  timePeriods: {
    status: CommonStatusEnum.INITIAL,
    data: new Map<string, TimePeriodData>(),
  },
};

export enum StatisticsActionTypes {
  FETCHING_DAILY_CREDITS = 'FETCHING_DAILY_CREDITS',
  FETCH_DAILY_CREDITS = 'FETCH_DAILY_CREDITS',
  FETCHING_CREDITS_INFO = 'FETCHING_CREDITS_INFO',
  FETCH_CREDITS_INFO = 'FETCH_CREDITS_INFO',
  CLEAN_CREDITS_INFO = 'CLEAN_CREDITS_INFO',
  CLEAN_DAILY_CREDITS = 'CLEAN_DAILY_CREDITS',
  FETCHING_CREDIT_DETAILS = 'FETCHING_CREDIT_DETAILS',
  FETCH_CREDIT_DETAILS = 'FETCH_CREDIT_DETAILS',
  CLEAN_CREDIT_DETAILS = 'CLEAN_CREDIT_DETAILS',
  FETCHING_CONVERSATIONS_USAGE = 'FETCHING_CONVERSATION_USAGE',
  FETCH_CONVERSATIONS_USAGE = 'FETCH_CONVERSATION_USAGE',
  CLEAN_CONVERSATIONS_USAGE = 'CLEAN_CONVERSATION_USAGE',
  FETCHING_MONTHLY_CREDITS = 'FETCHING_MONTHLY_CREDITS',
  FETCH_MONTHLY_CREDITS = 'FETCH_MONTHLY_CREDITS',
  FETCHING_TIME_PERIODS = 'FETCHING_TIME_PERIODS',
  FETCH_TIME_PERIODS = 'FETCH_TIME_PERIODS',
}

export type StatisticsActions =
  | { type: StatisticsActionTypes.FETCHING_DAILY_CREDITS }
  | { type: StatisticsActionTypes.FETCH_DAILY_CREDITS; payload: DailyCreditData[] }
  | { type: StatisticsActionTypes.CLEAN_DAILY_CREDITS }
  | { type: StatisticsActionTypes.FETCHING_CREDITS_INFO }
  | { type: StatisticsActionTypes.FETCH_CREDITS_INFO; payload: UserCreditInfo }
  | { type: StatisticsActionTypes.CLEAN_CREDITS_INFO }
  | { type: StatisticsActionTypes.FETCHING_CREDIT_DETAILS }
  | { type: StatisticsActionTypes.FETCH_CREDIT_DETAILS; payload: CreditDetailData[] }
  | { type: StatisticsActionTypes.CLEAN_CREDIT_DETAILS }
  | { type: StatisticsActionTypes.FETCHING_CONVERSATIONS_USAGE }
  | { type: StatisticsActionTypes.FETCH_CONVERSATIONS_USAGE; payload: ConversationsUsageData[] }
  | { type: StatisticsActionTypes.CLEAN_CONVERSATIONS_USAGE }
  | { type: StatisticsActionTypes.FETCHING_MONTHLY_CREDITS }
  | { type: StatisticsActionTypes.FETCH_MONTHLY_CREDITS; payload: UserCreditInfo }
  | { type: StatisticsActionTypes.FETCHING_TIME_PERIODS }
  | { type: StatisticsActionTypes.FETCH_TIME_PERIODS; payload: TimePeriodData[] };

export const statisticsReducer = (state: StatisticsState, action: Actions): StatisticsState => {
  switch (action.type) {
    case StatisticsActionTypes.FETCHING_DAILY_CREDITS:
      return { ...state, dailyCredits: { ...state.dailyCredits, status: CommonStatusEnum.FETCHING } };
    case StatisticsActionTypes.FETCH_DAILY_CREDITS:
      return {
        ...state,
        dailyCredits: {
          status: CommonStatusEnum.FETCHED,
          data: new Map(action.payload.map(item => [item.id as string, item])),
        },
      };
    case StatisticsActionTypes.CLEAN_DAILY_CREDITS:
      return { ...state, dailyCredits: { status: CommonStatusEnum.INITIAL, data: new Map() } };
    case StatisticsActionTypes.FETCHING_CREDITS_INFO:
      return {
        ...state,
        creditsInfo: { ...state.creditsInfo, status: CommonStatusEnum.FETCHING },
      };
    case StatisticsActionTypes.FETCH_CREDITS_INFO:
      return { ...state, creditsInfo: { status: CommonStatusEnum.FETCHED, data: action.payload } };
    case StatisticsActionTypes.CLEAN_CREDITS_INFO:
      return {
        ...state,
        creditsInfo: {
          status: CommonStatusEnum.INITIAL,
          data: { availableCredits: 0, totalCredits: 0, totalTokensCredit: 0, availableTokensPercentage: 0 },
        },
      };
    case StatisticsActionTypes.FETCHING_CREDIT_DETAILS:
      return {
        ...state,
        creditDetails: { ...state.creditDetails, status: CommonStatusEnum.FETCHING },
      };
    case StatisticsActionTypes.FETCH_CREDIT_DETAILS: {
      return {
        ...state,
        creditDetails: {
          status: CommonStatusEnum.FETCHED,
          data: new Map(
            action.payload.map(
              ({
                id,
                entityName,
                totalTokensCredit,
                totalTokensCreditPercentage,
                isDeleted,
                totalTokensCreditPercentageOverOrg,
                color,
              }) => [
                id as Conversation['id'] | ProjectLabel['id'] | UserEntity['id'],
                {
                  id,
                  entityName,
                  totalTokensCredit,
                  totalTokensCreditPercentage,
                  isDeleted,
                  totalTokensCreditPercentageOverOrg,
                  color,
                },
              ],
            ),
          ),
        },
      };
    }
    case StatisticsActionTypes.CLEAN_CREDIT_DETAILS:
      return { ...state, creditDetails: { status: CommonStatusEnum.INITIAL, data: new Map() } };
    case StatisticsActionTypes.FETCHING_CONVERSATIONS_USAGE:
      return {
        ...state,
        conversationsUsage: { ...state.conversationsUsage, status: CommonStatusEnum.FETCHING },
      };
    case StatisticsActionTypes.FETCH_CONVERSATIONS_USAGE: {
      return {
        ...state,
        conversationsUsage: {
          status: CommonStatusEnum.FETCHED,
          data: new Map(
            action.payload.map(({ id, isDeleted, totalTokensCreditPercentage }) => [
              id!,
              { id, isDeleted, totalTokensCreditPercentage },
            ]),
          ),
        },
      };
    }

    case StatisticsActionTypes.CLEAN_CONVERSATIONS_USAGE:
      return { ...state, conversationsUsage: { status: CommonStatusEnum.INITIAL, data: new Map() } };
    case StatisticsActionTypes.FETCHING_MONTHLY_CREDITS:
      return {
        ...state,
        monthlyCredits: { ...state.monthlyCredits, status: CommonStatusEnum.FETCHING },
      };
    case StatisticsActionTypes.FETCH_MONTHLY_CREDITS:
      return {
        ...state,
        monthlyCredits: {
          status: CommonStatusEnum.FETCHED,
          data: action.payload,
        },
      };
    case StatisticsActionTypes.FETCHING_TIME_PERIODS:
      return {
        ...state,
        timePeriods: { ...state.timePeriods, status: CommonStatusEnum.FETCHING },
      };
    case StatisticsActionTypes.FETCH_TIME_PERIODS:
      return {
        ...state,
        timePeriods: {
          ...state.timePeriods,
          status: CommonStatusEnum.FETCHED,
          data: new Map(action.payload.map(item => [item.id, item])),
        },
      };
    default:
      return state;
  }
};
