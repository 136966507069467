import {
  CloudUploadOutlined,
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
  ZoomInOutlined,
} from '@ant-design/icons';
import { CommandLineIcon } from '@heroicons/react/24/outline';
import { Button, Card, Col, Collapse, Flex, Image } from 'antd';
import { useDisplayObject, useDownloadDisplayObject } from 'hooks/useDisplayObject';
import { Highlight, themes } from 'prism-react-renderer';
import { PropsWithChildren, useCallback, useState } from 'react';
import { NewtonApi } from 'utils/newtonApi';
import CSVIcon from 'assets/connectors/csv.svg?react';
import ExcelIcon from 'assets/connectors/xls.svg?react';
import DownloadIcon from '../../assets/icons/download.svg?react';
import styles from './Message.module.scss';
import { Loader } from 'components/loader/Loader';
import { useModal } from 'selectors/useModals';
import { ModalScopesEnum, ModalTypesEnum } from 'reducers/modalsReducer';
import { redirectToLogin, ErrorCode } from 'types/errorCodes';
import { ConnectorSupportedFileTypesEnum } from 'types/enum';

type Props = PropsWithChildren & { displayObject: DisplayObject };

export const DisplayObject: React.FC<Props> = ({ displayObject }) => {
  const { show: showAddDataSourcesModal } = useModal(ModalScopesEnum.DATA_SOURCES, ModalTypesEnum.ADD);
  const [showIcons, setShowIcons] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const { data } = useDisplayObject(displayObject) as { data: string };
  const [visible, setVisible] = useState(false);
  const downloadDisplayObject = useDownloadDisplayObject();

  const handleDownloadFile = async () => {
    await downloadDisplayObject(displayObject);
  };

  const handleDownloadDataConnector = async (displayObject: DisplayObject) => {
    setIsDownloading(true);
    await downloadDisplayObject(displayObject);
    setIsDownloading(false);
  };

  const handleCreateFileByDisplayObject = useCallback(
    async (displayObject: DisplayObject) => {
      const type =
        displayObject.type === 'xls' ? ConnectorSupportedFileTypesEnum.XLSX : ConnectorSupportedFileTypesEnum.CSV;
      const res = await NewtonApi.createFileByDisplayObject(displayObject as unknown as DisplayObject, type);
      showAddDataSourcesModal({ displayObjectId: displayObject.id, connectorFile: res });
    },
    [showAddDataSourcesModal],
  );

  const renderCard = useCallback(() => {
    switch (displayObject.type) {
      case 'png':
      case 'jpg':
      case 'jpeg':
      case 'gif':
        return (
          <div onMouseOver={() => setShowIcons(true)} onMouseLeave={() => setShowIcons(false)}>
            {showIcons && (
              <div className={styles.buttonContainer}>
                <Button className={styles.actionButton} onClick={handleDownloadFile}>
                  <DownloadIcon />
                </Button>
                <Button className={styles.actionButton} onClick={() => setVisible(true)}>
                  <ZoomInOutlined style={{ fontSize: '20px' }} />
                </Button>
              </div>
            )}
            {data ? (
              <Image
                className={styles.plot}
                src={data}
                preview={{
                  visible,
                  mask: null,
                  imageRender() {
                    return (
                      <img
                        src={data}
                        style={{
                          height: 'calc(80%)',
                          width: 'calc(80%)',
                          objectFit: 'contain',
                        }}
                      />
                    );
                  },
                  toolbarRender: () => null,
                  onVisibleChange: () => setVisible(false),
                }}
                onError={e => {
                  console.error('Error loading image', e);
                  redirectToLogin(ErrorCode.IMAGE_LOAD_ERROR);
                }}
              />
            ) : null}
          </div>
        );
      case 'xls':
        return (
          <Flex align="center" className={styles.downloadButton} justify="space-between">
            <Flex align="center">
              <ExcelIcon style={{ marginRight: '10px' }} /> {displayObject.name}
            </Flex>
            <Flex gap={12}>
              <CloudUploadOutlined
                onClick={() => handleCreateFileByDisplayObject(displayObject)}
                style={{ fontSize: '24px', color: '#695AD9', opacity: 0.9 }}
              />
              {isDownloading ? (
                <Loader size="default" />
              ) : (
                <DownloadIcon onClick={() => handleDownloadDataConnector(displayObject)} color="#695AD9" />
              )}
            </Flex>
          </Flex>
        );
      case 'csv':
        return (
          <Flex align="center" className={styles.downloadButton} justify="space-between">
            <Flex align="center">
              <CSVIcon style={{ marginRight: '10px' }} /> {displayObject.name}
            </Flex>
            <Flex gap={12}>
              <CloudUploadOutlined
                onClick={() => handleCreateFileByDisplayObject(displayObject)}
                style={{ fontSize: '24px', color: '#695AD9', opacity: 0.9 }}
              />
              {isDownloading ? (
                <Loader size="default" />
              ) : (
                <DownloadIcon onClick={() => handleDownloadDataConnector(displayObject)} color="#695AD9" />
              )}
            </Flex>
          </Flex>
        );
      case 'python':
        return (
          <Card
            actions={[
              <SettingOutlined key="setting" />,
              <EditOutlined key="edit" />,
              <EllipsisOutlined key="ellipsis" />,
            ]}
            bordered={false}
          >
            <Card.Meta avatar={<CommandLineIcon height="24px" />} title="View Code" description={displayObject.name} />
            <Collapse defaultActiveKey={['1']} ghost>
              <Collapse.Panel header="Expand" key="1">
                <Highlight theme={themes.duotoneLight} code={data || ''} language="python">
                  {({ style, tokens, getLineProps, getTokenProps }) => (
                    <pre className={styles.code} style={style}>
                      {tokens.map((line, i) => (
                        <div key={i} {...getLineProps({ line })}>
                          {line.map((token, key) => (
                            <span key={key} {...getTokenProps({ token })} />
                          ))}
                        </div>
                      ))}
                    </pre>
                  )}
                </Highlight>
              </Collapse.Panel>
            </Collapse>
          </Card>
        );
      default:
        return <>{JSON.stringify(displayObject)}</>;
    }
  }, [displayObject, showIcons, handleDownloadFile, data, visible, isDownloading, handleCreateFileByDisplayObject]);

  return <Col span={24}>{renderCard()}</Col>;
};

export default DisplayObject;
