import { Dropdown } from 'ui/Dropdown';
import styles from './UsagePeriodDrpdown.module.scss';
import { FC } from 'react';
import { TimePeriodOptionsEnum } from 'types/enum';

export type TimePeriodItem = {
  key: TimePeriodOptionsEnum;
  label: string;
  disabled?: boolean;
};

type UsagePeriodDropdownProps = {
  selectedTimePeriod: TimePeriodItem;
  onSelect: (timePeriodItem: TimePeriodItem) => void;
  timePeriodItems: TimePeriodItem[];
};

export const UsagePeriodDropdown: FC<UsagePeriodDropdownProps> = ({
  selectedTimePeriod,
  onSelect,
  timePeriodItems,
}) => {
  return (
    <Dropdown
      className={styles.dropdown}
      menu={{
        items: timePeriodItems,
        onClick: ({ key }) => {
          const timePeriodItem = timePeriodItems.find(l => l.key === key)!;
          onSelect(timePeriodItem);
        },
      }}
    >
      {selectedTimePeriod.label}
    </Dropdown>
  );
};
