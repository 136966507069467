import { FC, useRef } from 'react';
import { Select } from './Select';
import { SelectProps, Spin } from 'antd';

type InfiniteScrollSelectProps = SelectProps & {
  hasMore: boolean;
  loading: boolean;
  onLoad: () => void;
};

export const InfiniteScrollSelect: FC<InfiniteScrollSelectProps> = ({ hasMore, loading, onLoad, ...props }) => {
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handlePopupScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { target } = e;
    if (!target) return;
    const bottom =
      (target as HTMLDivElement).scrollHeight - (target as HTMLDivElement).scrollTop ===
      (target as HTMLDivElement).clientHeight;

    if (bottom && !loading && hasMore) {
      onLoad();
      setTimeout(() => {
        if (dropdownRef.current) {
          dropdownRef.current.scrollTop = 0;
        }
      }, 200);
    }
  };

  return (
    <Select
      {...props}
      placeholder={props.placeholder}
      dropdownRender={menu => {
        return (
          <div ref={dropdownRef} onScroll={handlePopupScroll} style={{ maxHeight: 200, overflow: 'auto' }}>
            {menu}
            {loading && (
              <div style={{ textAlign: 'center', padding: 8 }}>
                <Spin size="small" />
              </div>
            )}
          </div>
        );
      }}
    />
  );
};
