import { Flex } from 'antd';
import { DataSourceList } from 'components/datasource-list/DataSourceList';
import { NewConversationButton } from 'components/new-converation-btn/NewConversationButton';
import { PageSection } from 'components/page-section/PageSection';
import { PropsWithChildren, useCallback, useRef, useState } from 'react';
import styles from './Home.module.scss';
import { HomeCard } from './HomeCard';
import { BlueprintList } from 'components/blueprint-list/BlueprintList';
import { useSearchParams } from 'hooks/useURLParams';
import { useNavigate } from 'react-router-dom';
import { PageOptionsEnum } from 'types/enum';
import BookOpenIcon from 'assets/icons/bookOpen.svg?react';
import { Button } from 'ui/Button';
import classNames from 'classnames';
import { useUIContext } from 'contexts/UIContext';
import { useBlueprints } from 'selectors/useBlueprints';

const NewConversationDataSources: React.FC<{ selections: DataSource[] }> = ({ selections }) => {
  const placeholder = selections.length
    ? `Start with ${selections.length} connector${selections.length > 1 ? 's' : ''}`
    : undefined;

  return <NewConversationButton placeholder={placeholder} params={{ dataSources: selections.map(ds => ds.id) }} />;
};

const pluralize = (word: string, count: number) => {
  return `${count ?? '-'} ${word}${count !== 1 ? 's' : ''}`;
};

export const Home: React.FC<PropsWithChildren> = () => {
  const { isCollapsed } = useUIContext();
  const { blueprintsCount: count } = useBlueprints();
  const { activeTab = PageOptionsEnum.BLUEPRINT } = useSearchParams<{ activeTab: PageOptionsEnum }>();
  const [collapsed, setCollapsed] = useState(false);
  const [selections, setSelections] = useState<DataSource[]>([]);
  const navigate = useNavigate();
  const scrollRef = useRef<HTMLDivElement>(null);

  /**
   * This is used for both the wrapper and the inner section scrolls. It could be broken up if we find that
   * we need to handle the scroll events differently.
   * TODO: Debounce this
   */
  const handleScroll = useCallback(
    (e: React.UIEvent, isWrapper?: boolean) => {
      const shouldCollapse = e.currentTarget.scrollTop > 0;
      if (!collapsed && shouldCollapse) {
        scrollRef.current?.scrollTo({ top: 102, behavior: 'smooth' });
      }
      // Only uncollapse if the scroll is at the top of the wrapper
      if (!isWrapper && shouldCollapse === false) {
        return;
      }
      setCollapsed(shouldCollapse);
    },
    [collapsed],
  );

  const blueprintTag = pluralize('blueprint', count);

  return (
    <div className={styles.scrollWrapper} onScroll={e => handleScroll(e, true)} ref={scrollRef}>
      <Flex justify="center" vertical className={styles.layout}>
        <Flex
          className={classNames(styles.homeTabs, {
            [styles.collapsed]: collapsed,
            [styles.sidebarCollapsed]: isCollapsed,
          })}
          gap="20px"
        >
          <HomeCard
            collapsed={collapsed}
            onClick={() => navigate('/?activeTab=blueprint')}
            selected={activeTab === PageOptionsEnum.BLUEPRINT}
            tag={blueprintTag}
            title="Start with a blueprint"
            subtitle="Select blueprint..."
          />
          <HomeCard
            collapsed={collapsed}
            onClick={() => navigate('/?activeTab=datasource')}
            selected={activeTab === PageOptionsEnum.DATASOURCE}
            tag="AI"
            title="Start new conversation"
            subtitle="Begin with or without connectors"
          />
          <HomeCard
            collapsed={collapsed}
            disabled
            onClick={() => {}}
            selected={activeTab === PageOptionsEnum.ACTIVITY}
            tag="5 updates"
            title="Last activity"
            subtitle="All last activity"
          />
        </Flex>
        {activeTab === PageOptionsEnum.BLUEPRINT && (
          <PageSection
            button={
              <Button
                bordered
                onClick={() => navigate('/blueprint/library')}
                icon={<BookOpenIcon height={20} width={20} />}
              >
                Library Blueprints
              </Button>
            }
            className={styles.tab}
            heading="Start with blueprint"
            fullHeight={false}
            onScroll={handleScroll}
            subheading="Select blueprint..."
          >
            <BlueprintList showFavorite />
          </PageSection>
        )}
        {activeTab === PageOptionsEnum.DATASOURCE && (
          <PageSection
            button={<NewConversationDataSources selections={selections} />}
            className={styles.tab}
            heading="Start new conversation"
            fullHeight={false}
            onScroll={handleScroll}
            subheading="You can add one or more connectors or start without any connector"
          >
            <DataSourceList
              onSelect={setSelections}
              onNewEntityConfirm={ds => {
                setSelections(prev => [...prev, ds]);
              }}
              selected={selections}
            />
          </PageSection>
        )}
        {activeTab === PageOptionsEnum.ACTIVITY && (
          <PageSection className={styles.tab} fullHeight={false}>
            activity
          </PageSection>
        )}
      </Flex>
    </div>
  );
};
