import { ConfirmationModal } from 'components/common/ConfirmationModal';
import { useModal } from 'selectors/useModals';
import { ModalScopesEnum, ModalTypesEnum } from 'reducers/modalsReducer';
import { useArchiveScheduledTask } from 'selectors/useScheduledTasksSelector';
import { useCallback, useState } from 'react';
export const ArchiveScheduledTaskModal = () => {
  const { data: scheduledTask, hide } = useModal<ScheduledTask>(
    ModalScopesEnum.SCHEDULED_TASKS,
    ModalTypesEnum.ARCHIVE,
  );
  const [processing, setProcessing] = useState(false);
  const archive = useArchiveScheduledTask();
  const onConfirm = useCallback(async () => {
    setProcessing(true);
    await archive(scheduledTask.id);
    hide();
    setProcessing(false);
  }, [archive, hide, scheduledTask]);
  return (
    <ConfirmationModal
      isOpen={true}
      title={'Archive Scheduled Task'}
      description={
        <>
          Are you sure you want to archive this scheduled task <strong>{scheduledTask?.name}</strong>?
        </>
      }
      onConfirm={onConfirm}
      onCancel={() => hide()}
      confirmationButton={processing ? 'Archiving' : 'Archive'}
      processing={processing}
      cancelButton={'Cancel'}
    />
  );
};
