import { Flex, Typography } from 'antd';
import { SegmentedProps } from 'antd/es/segmented';
import { EntityViewOptions } from 'components/entity-list/EntityViewOptions';
import { useId, useState } from 'react';
import { ScheduledTasksFilterTypesEnum, ViewOptionsEnum } from 'types/enum';
import { Segmented } from 'ui/Segmented';
import styles from './ScheduledTaskView.module.scss';
import { useScheduledTasks, useToggleActivateScheduledTask } from 'selectors/useScheduledTasksSelector';
import { EntityTable } from 'components/entity-list/EntityTable';
import { ColumnsType } from 'antd/es/table';
import { UserTag } from 'components/user-tag/UserTag';
import { MultipleElements } from 'components/multiple-labels/MultipleElements';
import { ProjectLabel } from 'ui/ProjectLabel';
import { Switch } from 'ui/Switch';
import ListViewIcon from '../../assets/iconsNewVersion/list_view.svg?react';
import CalendarViewIcon from '../../assets/iconsNewVersion/calendar.svg?react';
import { ScheduledTasksActionsMenu } from 'components/scheduled-tasks-actions-menu/ScheduledTasksActionsMenu';
import { tasksfilterOptions as filterOptions } from 'utils/scheduledTask';

export const ScheduledTasksView = ({
  filter,
  page,
  setFilter,
  setPage,
}: {
  filter: ScheduledTasksFilterTypesEnum;
  page: number;
  setFilter: (filter: ScheduledTasksFilterTypesEnum) => void;
  setPage: (page: number) => void;
}) => {
  const nameId = useId();
  const [view, setView] = useState<ViewOptionsEnum>(ViewOptionsEnum.LIST);
  const { tasks, pagination, loading } = useScheduledTasks(page, filter);
  const toggleActivateScheduledTask = useToggleActivateScheduledTask();

  const onFilterChange: SegmentedProps['onChange'] = value => {
    setFilter(value as ScheduledTasksFilterTypesEnum);
  };

  const viewOptions = [
    { option: ViewOptionsEnum.LIST, icon: (props: { className?: string }) => <ListViewIcon {...props} /> },
    {
      option: ViewOptionsEnum.CALENDAR,
      icon: (props: { className?: string }) => <CalendarViewIcon {...props} />,
      disabled: true,
    },
  ];

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleToggleActivateTask = (record: ScheduledTask) => {
    toggleActivateScheduledTask(record.id, !record.isEnabled);
  };

  const columns: ColumnsType<ScheduledTask> = [
    {
      key: `name_${nameId}`,
      title: 'Name',
      dataIndex: 'name',
      width: 400,
      ellipsis: true,
    },
    {
      key: 'owner',
      title: 'Owner',
      dataIndex: 'owner',
      width: 'auto',
      render: (value: UserEntity) => <UserTag picture={value?.picture || ''} name={value?.fullName || ''} />,
    },
    {
      key: 'project',
      title: 'Project',
      dataIndex: 'projectLabels',
      width: 'auto',
      render: (values: ProjectLabel[]) => (
        <MultipleElements>
          {values?.map(project => (
            <ProjectLabel
              text={project?.name || ''}
              color={project?.color || ''}
              textColor="var(--nri-color-primary)"
            />
          ))}
        </MultipleElements>
      ),
    },
    {
      key: 'nextRunsAt',
      title: 'Next runs at',
      dataIndex: 'nextRunAt',
      width: 150,
      render: (value: string) => (
        <Typography.Paragraph className={styles.wrap} ellipsis={{ rows: 2, expandable: false }}>
          {value ?? '-'}
        </Typography.Paragraph>
      ),
    },
    {
      key: `isEnable_${nameId}`,
      title: 'Active',
      dataIndex: 'isEnabled',
      width: 70,
      render: (_, record: ScheduledTask) => {
        const isArchived = record.status === 'archived';
        return (
          <Switch checked={record.isEnabled} disabled={isArchived} onClick={() => handleToggleActivateTask(record)} />
        );
      },
    },
    { key: 'actions', title: '', width: 60 },
  ];

  const renderView = (view: ViewOptionsEnum) => {
    switch (view) {
      case ViewOptionsEnum.LIST:
        return (
          <EntityTable<ScheduledTask>
            entities={tasks}
            columns={columns}
            pagination={{
              total: pagination?.count,
              pageSize: 10,
              current: page,
              onChange: handlePageChange,
            }}
            loading={loading}
            actionMenu={_task => <ScheduledTasksActionsMenu scheduledTask={_task} />}
          />
        );
      case ViewOptionsEnum.CALENDAR:
        return <div>Coming soon</div>;
      default:
        return;
    }
  };

  return (
    <Flex gap={16} vertical className={styles.container}>
      <Flex justify="space-between">
        <Segmented defaultValue={filter} onChange={onFilterChange} options={filterOptions} />
        <Flex gap={12}>
          {/* TODO: Add search feature */}
          <EntityViewOptions view={view} setView={setView} viewOptions={viewOptions} />
        </Flex>
      </Flex>
      <div>{renderView(view)}</div>
    </Flex>
  );
};
