function escapeRegExp(string: string) {
  // eslint-disable-next-line no-useless-escape
  return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
}

export const highlightText = (text: string, highlight: string) => {
  if (!highlight || !text) return text;

  const safeHighlight = escapeRegExp(highlight);
  const regex = new RegExp(`(${safeHighlight})`, 'gi');
  const parts = text.split(regex);

  return (
    <>
      {parts.map((part, index) =>
        regex.test(part) ? (
          <span key={index} style={{ backgroundColor: 'var(--nri-color-selection)' }}>
            {part}
          </span>
        ) : (
          part
        ),
      )}
    </>
  );
};
