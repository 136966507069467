// Trying to align with real HTTP error codes
export enum ErrorCode {
  // Session/Auth Errors
  SESSION_EXPIRED = 'E401',
  UNAUTHORIZED = 'U401',

  // Image/Resource Errors
  IMAGE_LOAD_ERROR = 'I500',
  DATASOURCE_NOT_FOUND = 'D404',

  STREAM_MAX_RETRIES = 'S429',
  UNKNOWN_ERROR = 999,
}

export const getErrorMessage = (code: ErrorCode): string => {
  switch (code) {
    case ErrorCode.SESSION_EXPIRED:
      return 'Your session has expired';
    case ErrorCode.UNAUTHORIZED:
      return 'You are not authorized to access this resource';
    case ErrorCode.IMAGE_LOAD_ERROR:
      return 'Error loading assets';
    case ErrorCode.DATASOURCE_NOT_FOUND:
      return 'Resource not found';
    case ErrorCode.STREAM_MAX_RETRIES:
      return 'Failed to reconnect to server';
    default:
      return 'An unknown error occurred';
  }
};

export const redirectToLogin = (code: ErrorCode) => {
  window.location.href = `/login?code=${code}`;
};
