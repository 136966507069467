import { DeleteAgentModal } from 'components/agent-modals/DeleteAgentModal';
import { ManageAgentAccessModal } from 'components/agent-modals/ManageAgentAccessModal';
import { ToggleAgentModal } from 'components/agent-modals/ToggleAgentModal';
import { ArchiveBlueprintModal } from 'components/blueprint-modals/ArchiveBlueprintModal';
import { DeleteBlueprintModal } from 'components/blueprint-modals/DeleteBlueprintModal';
import { DuplicateBlueprintModal } from 'components/blueprint-modals/DuplicateBlueprintModal';
import { AddToProjectConversationModal } from 'components/conversation-modals/AddToProjectConversationModal';
import { ArchiveConversationModal } from 'components/conversation-modals/ArchiveConversationModal';
import { DeleteConversationModal } from 'components/conversation-modals/DeleteConversationModal';
import { DuplicateConversationModal } from 'components/conversation-modals/DuplicateConversationModal';
import { ManageConversationAccess } from 'components/conversation-modals/ManageConversationAccessModal';
import { RenameConversationModal } from 'components/conversation-modals/RenameConversationModal';
import { AddDataSourceModal } from 'components/datasource-modals/AddDataSourceModal';
import { ArchiveDataSourceModal } from 'components/datasource-modals/ArchiveDataSourceModal';
import { DeleteDataSourceModal } from 'components/datasource-modals/DeleteDataSourceModal';
import { ManageDataSourceAccessModal } from 'components/datasource-modals/ManageDataSourceAccessModal';
import { ReplaceDataSourceCSV } from 'components/datasource-modals/ReplaceDataSourceCSV';
import { SelectExistingDatasourceModal } from 'components/datasource-modals/SelectExistingDatasourceModal';
import { AddMemoryModal } from 'components/memory-modals/AddMemoryModal';
import { DeleteMemoryModal } from 'components/memory-modals/DeleteMemoryModal';
import { EditMemoryModal } from 'components/memory-modals/EditMemoryModal';
import { AddProjectModal } from 'components/projects-view/AddProjectModal';
import { DeleteProjectModal } from 'components/projects-view/DeleteProjectModal';
import { ManageScheduledTaskModal } from 'components/scheduled-tasks-modals/ManageScheduledTaskModal';
import { ArchiveScheduledTaskModal } from 'components/scheduled-tasks-modals/ArchiveScheduledTaskModal';
import { DeleteScheduledTaskModal } from 'components/scheduled-tasks-modals/DeleteScheduledTaskModal';
import { ModalScopesEnum, ModalTypesEnum } from 'reducers/modalsReducer';
import { useModals } from 'selectors/useModals';

interface VisibleModal {
  scope: ModalScopesEnum;
  type: ModalTypesEnum;
}

type ModalKey = `${ModalScopesEnum}::${ModalTypesEnum}`;

/** TODO: This should be a map of modal elements */
const ModalMap: Partial<Record<ModalKey, React.FC>> = {
  [`${ModalScopesEnum.AGENTS}::${ModalTypesEnum.DELETE}`]: DeleteAgentModal,
  [`${ModalScopesEnum.AGENTS}::${ModalTypesEnum.MANAGE}`]: ManageAgentAccessModal,
  [`${ModalScopesEnum.AGENTS}::${ModalTypesEnum.TOGGLE}`]: ToggleAgentModal,
  [`${ModalScopesEnum.BLUEPRINTS}::${ModalTypesEnum.ARCHIVE}`]: ArchiveBlueprintModal,
  [`${ModalScopesEnum.BLUEPRINTS}::${ModalTypesEnum.DELETE}`]: DeleteBlueprintModal,
  [`${ModalScopesEnum.BLUEPRINTS}::${ModalTypesEnum.DUPLICATE}`]: DuplicateBlueprintModal,
  [`${ModalScopesEnum.CONVERSATIONS}::${ModalTypesEnum.ARCHIVE}`]: ArchiveConversationModal,
  [`${ModalScopesEnum.CONVERSATIONS}::${ModalTypesEnum.ADD_TO_PROJECT}`]: AddToProjectConversationModal,
  [`${ModalScopesEnum.CONVERSATIONS}::${ModalTypesEnum.DELETE}`]: DeleteConversationModal,
  [`${ModalScopesEnum.CONVERSATIONS}::${ModalTypesEnum.DUPLICATE}`]: DuplicateConversationModal,
  [`${ModalScopesEnum.CONVERSATIONS}::${ModalTypesEnum.MANAGE}`]: ManageConversationAccess,
  [`${ModalScopesEnum.CONVERSATIONS}::${ModalTypesEnum.RENAME}`]: RenameConversationModal,

  [`${ModalScopesEnum.DATA_SOURCES}::${ModalTypesEnum.ADD}`]: AddDataSourceModal,
  [`${ModalScopesEnum.DATA_SOURCES}::${ModalTypesEnum.ARCHIVE}`]: ArchiveDataSourceModal,
  [`${ModalScopesEnum.DATA_SOURCES}::${ModalTypesEnum.DELETE}`]: DeleteDataSourceModal,
  [`${ModalScopesEnum.DATA_SOURCES}::${ModalTypesEnum.MANAGE}`]: ManageDataSourceAccessModal,
  [`${ModalScopesEnum.DATA_SOURCES}::${ModalTypesEnum.REPLACE}`]: ReplaceDataSourceCSV,
  [`${ModalScopesEnum.DATA_SOURCES}::${ModalTypesEnum.SELECT}`]: SelectExistingDatasourceModal,

  [`${ModalScopesEnum.PROJECTS}::${ModalTypesEnum.ADD}`]: AddProjectModal,
  [`${ModalScopesEnum.PROJECTS}::${ModalTypesEnum.DELETE}`]: DeleteProjectModal,

  [`${ModalScopesEnum.MEMORIES}::${ModalTypesEnum.ADD}`]: AddMemoryModal,
  [`${ModalScopesEnum.MEMORIES}::${ModalTypesEnum.EDIT}`]: EditMemoryModal,
  [`${ModalScopesEnum.MEMORIES}::${ModalTypesEnum.DELETE}`]: DeleteMemoryModal,

  [`${ModalScopesEnum.SCHEDULED_TASKS}::${ModalTypesEnum.MANAGE}`]: ManageScheduledTaskModal,
  [`${ModalScopesEnum.SCHEDULED_TASKS}::${ModalTypesEnum.DELETE}`]: DeleteScheduledTaskModal,
  [`${ModalScopesEnum.SCHEDULED_TASKS}::${ModalTypesEnum.ARCHIVE}`]: ArchiveScheduledTaskModal,
};

const ModalWrapper: React.FC<{ modal: VisibleModal }> = ({ modal }) => {
  const key = `${modal.scope}::${modal.type}`;
  const Component = ModalMap[`${modal.scope}::${modal.type}`];
  if (!Component) {
    return null;
  }
  return <Component key={key} />;
};

export const ModalsOutlet: React.FC = () => {
  const modals = useModals();
  const visibleModals: VisibleModal[] = Object.entries(modals).reduce<VisibleModal[]>((acc, [scope, modalTypes]) => {
    Object.entries(modalTypes).forEach(([type, modalState]) => {
      if (modalState.visible) {
        acc.push({
          scope: scope as ModalScopesEnum,
          type: type as ModalTypesEnum,
        });
      }
    });
    return acc;
  }, []);

  return (
    <>
      {visibleModals.map(modal => (
        <ModalWrapper key={`${modal.scope}::${modal.type}`} modal={modal} />
      ))}
    </>
  );
};
