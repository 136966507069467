import { Form } from 'antd';

import { useCallback, useMemo, useState } from 'react';
import { ModalScopesEnum, ModalTypesEnum } from 'reducers/modalsReducer';
import { useModal } from 'selectors/useModals';
import { useSharedEntities, useSharedEntitiesByConversationId } from 'selectors/useSharedEntitiesSelector';

import { useShareConversation } from 'selectors/useConversationSelectors';
import { Button } from 'ui/Button';
import { Modal } from 'ui/Modal';
import { EntityShareForm } from 'components/entity-share-form/EntityShareForm';
import { ConversationSelectedItem } from '../conversation-shared-item/ConversationSelectedItem';
import { EntityPermissionsEnum } from 'types/enum';

export const ManageConversationAccess = () => {
  const { data: conversation, hide } = useModal<Conversation>(ModalScopesEnum.CONVERSATIONS, ModalTypesEnum.MANAGE);
  const { conversationSharedEntities, loading: conversationEntitiesLoading } = useSharedEntitiesByConversationId(
    conversation.id,
  );
  const [form] = Form.useForm();
  const { me, sharedEntities } = useSharedEntities();

  const sharedUsers = useMemo(() => {
    const filteredEntities = conversationSharedEntities.filter(e => e.id !== me?.id);
    return filteredEntities;
  }, [conversationSharedEntities, me?.id]);

  const owner = useMemo(
    () => sharedEntities.find(e => e.type === 'user' && e.id === conversation.owner) || me,
    [conversation.owner, sharedEntities],
  );

  const filteredSharedEntities = useMemo(() => {
    const filteredEntities = sharedEntities.filter(e => e.id !== owner?.id);
    return filteredEntities;
  }, [sharedEntities, owner]);

  const manageAccess = useShareConversation();

  const [isLoading, setIsLoading] = useState(false);
  const onConfirm = useCallback(async () => {
    setIsLoading(true);
    const { shareWith, sharedUsers } = form.getFieldsValue(true);

    const payload =
      shareWith === 'users'
        ? sharedUsers
        : [{ id: me!.organization, type: 'organization', permission: EntityPermissionsEnum.READ }];

    try {
      await manageAccess(conversation.id, payload);
      setIsLoading(false);
      hide();
    } catch (error) {
      setIsLoading(false);
    }
  }, [setIsLoading, conversation, me]);

  return (
    <Modal
      loading={conversationEntitiesLoading}
      open={true}
      centered
      styles={{
        content: { height: '572px' },
        body: { height: '100%' },
        header: { margin: '0' },
        footer: { margin: '0' },
      }}
      onCancel={hide}
      title="Manage Access"
      description="Here you can manage access to this conversation"
      destroyOnClose
      divider={true}
      footer={
        <>
          <Button onClick={hide} bordered>
            Cancel
          </Button>
          <Button loading={isLoading} onClick={onConfirm} type="primary">
            Save
          </Button>
        </>
      }
    >
      <EntityShareForm
        entityName="conversation"
        form={form}
        canShareWithOrganization={false}
        sharedEntities={filteredSharedEntities}
        sharedPermissions={sharedUsers}
        owner={owner}
        components={{ SelectedItem: ConversationSelectedItem }}
      />
    </Modal>
  );
};
