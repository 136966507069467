import { Flex, Typography } from 'antd';
import { Switch } from 'ui/Switch';
import styles from './UsageLevelSwitch.module.scss';
import { FC } from 'react';
import { SwitchProps } from 'antd/lib';
import classNames from 'classnames';
type UsageLevelSwitchProps = SwitchProps & {
  displayLabel?: boolean;
  textClassname?: string;
};
export const UsageLevelSwitch: FC<UsageLevelSwitchProps> = ({ textClassname, ...props }) => {
  return (
    <Flex align="center" gap={12}>
      <Switch {...props} />
      <Typography.Text className={classNames(styles.switchDescription, textClassname)}>
        Show personal usage only
      </Typography.Text>
    </Flex>
  );
};
