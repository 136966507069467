import { ScheduledTasksFilterTypesEnum } from 'types/enum';
import { getLocalizedDaysInMonth } from './generateTimeIntervals';
import { getLocalizedMonths } from './generateTimeIntervals';
import { getLocalizedDaysOfWeek } from './generateTimeIntervals';
import { generateHourIntervals } from './generateTimeIntervals';

export const tasksfilterOptions = [
  { label: 'All', value: ScheduledTasksFilterTypesEnum.ALL },
  { label: 'User', value: ScheduledTasksFilterTypesEnum.OWNED, disabled: true },
  { label: 'Shared with me', value: ScheduledTasksFilterTypesEnum.SHARED, disabled: true },
  { label: 'Archived', value: ScheduledTasksFilterTypesEnum.ARCHIVED },
];

const timeOptions = generateHourIntervals(30);
const dayOfWeekOptions = getLocalizedDaysOfWeek();
const monthOptions = getLocalizedMonths();
const dayOfMonthOptions = getLocalizedDaysInMonth(2024, 1);

export const frequencyFields: Record<string, { key: string; options: string[]; placeholder: string }> = {
  time: { key: 'time', options: timeOptions, placeholder: 'Select a time' },
  dayOfWeek: { key: 'dayOfWeek', options: dayOfWeekOptions, placeholder: 'Select a day of the week' },
  dayOfMonth: { key: 'dayOfMonth', options: dayOfMonthOptions, placeholder: 'Select a day of the month' },
  month: { key: 'month', options: monthOptions, placeholder: 'Select a month' },
};

export const scheduleVariants: Record<Frequency, { label: string; fields: string[] }> = {
  daily: {
    label: 'Daily',
    fields: ['time'],
  },
  weekly: {
    label: 'Weekly',
    fields: ['dayOfWeek', 'time'],
  },
  monthly: {
    label: 'Monthly',
    fields: ['dayOfMonth', 'time'],
  },
  annually: {
    label: 'Annually',
    fields: ['month', 'dayOfMonth', 'time'],
  },
};
