import { PayAsYouGoDashboard } from './dashboards/PayAsYouGoDashboard';
import { useAuth } from 'selectors/useAuthSelector';
import { Loader } from 'components/loader/Loader';
import { SubscriptionTypesEnum } from 'types/enum';

export const Usage = () => {
  const { me } = useAuth();
  const renderUsageDashboard = (key: SubscriptionTypesEnum = SubscriptionTypesEnum.PAY_AS_YOU_GO) => {
    switch (key) {
      case SubscriptionTypesEnum.PAY_AS_YOU_GO:
        return <PayAsYouGoDashboard />;
      default:
        break;
    }
  };

  return <div>{!me ? <Loader /> : renderUsageDashboard()}</div>;
};
