import { ActionItem, ActionsMenu } from 'ui/ActionsMenu';

import EditIcon from 'assets/iconsNewVersion/edit.svg?react';

import ArchiveIcon from 'assets/iconsNewVersion/archive.svg?react';

import DeleteIcon from 'assets/iconsNewVersion/trash.svg?react';
import { FC } from 'react';
import { useAuth } from 'selectors/useAuthSelector';
import { ModalScopesEnum, ModalTypesEnum } from 'reducers/modalsReducer';
import { useModal } from 'selectors/useModals';

enum ScheduledTasksActions {
  DELETE = 'DELETE',
  EDIT = 'EDIT',
  ARCHIVE = 'ARCHIVE',
}

export const ScheduledTasksActionsMenu: FC<{ scheduledTask: ScheduledTask }> = ({ scheduledTask }) => {
  const { show: showManageModal } = useModal(ModalScopesEnum.SCHEDULED_TASKS, ModalTypesEnum.MANAGE);
  const { show: showDeleteModal } = useModal(ModalScopesEnum.SCHEDULED_TASKS, ModalTypesEnum.DELETE);
  const { show: showArchiveModal } = useModal(ModalScopesEnum.SCHEDULED_TASKS, ModalTypesEnum.ARCHIVE);
  const { me } = useAuth();

  const items: ActionItem<ScheduledTask>[] = [
    {
      color: 'var(--nri-color-primary)',
      iconColor: 'var(--nri-color-purple-grey)',
      icon: EditIcon,
      label: 'Edit',
      key: ScheduledTasksActions.EDIT,
    },
    {
      color: 'var(--nri-color-primary)',
      iconColor: 'var(--nri-color-purple-grey)',
      icon: ArchiveIcon,
      label: 'Archive',
      key: ScheduledTasksActions.ARCHIVE,
      filter: task => task.owner !== me?.uuid,
    },
    {
      color: 'var(--nri-color-red)',
      iconColor: 'var(--nri-color-red)',
      icon: DeleteIcon,
      label: 'Delete',
      key: ScheduledTasksActions.DELETE,
    },
  ];

  const onActionClick = (key: ScheduledTasksActions) => {
    switch (key) {
      case ScheduledTasksActions.ARCHIVE:
        showArchiveModal(scheduledTask);
        break;
      case ScheduledTasksActions.DELETE:
        showDeleteModal(scheduledTask);
        break;
      case ScheduledTasksActions.EDIT:
        showManageModal({ task: scheduledTask });
        break;
    }
  };
  const filteredItems = items.filter(item => {
    if (item.filter) {
      return item.filter(scheduledTask, me!);
    }
    return true;
  });
  return <ActionsMenu items={filteredItems} onSelect={({ key }) => onActionClick(key as ScheduledTasksActions)} />;
};
