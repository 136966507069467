import { Space, Tag } from 'antd';
import { useCallback, useState } from 'react';
import { useMemories } from 'selectors/userMemories';
import { Select } from 'ui/Select';

interface MemorySelectProps {
  initialSelections: Memory[];
  onSelect(memories: Memory[]): void;
}

export const MemorySelect: React.FC<MemorySelectProps> = ({ initialSelections = [], onSelect }) => {
  const memories: Memory[] = useMemories().memories;
  const [selectedMemories, setSelectedMemories] = useState<Memory[]>(initialSelections);
  const [placeholderMemories, setPlaceholderMemories] = useState<{ label: string; value: number }[]>([]);

  const onSelectionConfirm = useCallback(
    (selections: Memory[]) => {
      onSelect(selections);
      setPlaceholderMemories([]);
      setSelectedMemories(selections);
    },
    [onSelect],
  );

  return (
    <Space direction='vertical' style={{ width: '100%' }}>
      <Space.Compact style={{ width: '100%' }}>
        {/* This is an odd behavior they are asking to build for the select, if we see this elsewhere its worth creating a component */}
        <Select
          mode="multiple"
          placeholder="Select one or more memories"
          options={memories
            .filter(m => !selectedMemories.find(sm => sm.id === m.id))
            .map((item: Memory) => ({
              label: item.name,
              value: item.id,
            }))}
          onBlur={() => {
            onSelectionConfirm(
              [
                ...selectedMemories,
                ...placeholderMemories.map(m => memories.find(mem => mem.id === m.value) as Memory),
              ].filter((m, i, arr) => arr.indexOf(m) === i),
            );
          }}
          onSelect={(_e, option) => {
            setPlaceholderMemories(memories => [...memories, {
              label: option.label as string,
              value: option.value as number
            }]);
          }}
          onDeselect={value => {
            setPlaceholderMemories(memories => memories.filter(m => m.value !== value));
          }}
          optionFilterProp="label"
          filterOption={(input, option) => {
            if (!option?.label || typeof option.label !== 'string') return false;
            return option.label.toLowerCase().includes(input.toLowerCase());
          }}
          value={placeholderMemories.map(u => u.value)}
        />
      </Space.Compact>
      <Space style={{ width: '100%' }} wrap>
        {selectedMemories.map(mem => (
          <Tag
            key={mem.id}
            closeIcon
            onClose={() => {
              onSelectionConfirm(selectedMemories.filter(m => m.id !== mem.id));
            }}
          >
            {mem.name}
          </Tag>
        ))}
      </Space>
    </Space>
  );
};
