import { Avatar, Flex, Form, FormInstance } from 'antd';
import { FC, useCallback, useState } from 'react';
import styles from './EntityShareForm.module.scss';
import { Select } from 'ui/Select';
import { EntityPermissionsEnum } from 'types/enum';

type EntityBaseSelectedItemProps = {
  data: ShareableEntity;
  isOwner?: boolean;
  onPermissionChange?: (value: EntityPermissionsEnum | string, data: ShareableEntity) => void;
};

type EntityShareFormProps = {
  canShareWithOrganization?: boolean;
  components: { SelectedItem: FC<EntityBaseSelectedItemProps> };
  entityName: string;
  form: FormInstance;
  owner: ShareableEntity;
  sharedEntities: ShareableEntity[];
  sharedPermissions: ShareableEntity[];
};

export const EntityShareForm: FC<EntityShareFormProps> = ({
  canShareWithOrganization = true,
  components: { SelectedItem },
  entityName,
  form,
  owner,
  sharedEntities,
  sharedPermissions,
}) => {
  const shareWith = Form.useWatch('shareWith', form);
  const [selectedUsers, setSelectedUsers] = useState<ShareableEntity[]>(sharedPermissions || []);
  const [filteredEntities, setFilteredEntities] = useState<ShareableEntity[]>(
    sharedEntities.filter(entity => !selectedUsers.some(u => u.id === entity.id && u.type === entity.type)),
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const SHARE_OPTIONS = [
    { label: 'Specify users', value: 'users' },
    { label: 'Share with organization', value: 'organization', disabled: !canShareWithOrganization },
  ];

  const initialValues = {
    shareWith: canShareWithOrganization ? SHARE_OPTIONS[0].value : SHARE_OPTIONS[0].value,
    sharedUsers: selectedUsers,
  };

  const handleUserChange = useCallback(
    (user: ShareableEntity, action: 'add' | 'remove') => {
      if (action === 'remove') {
        setSelectedUsers(prev => prev.filter(u => u.id !== user.id || u.type !== user.type));
        setFilteredEntities(prev => [...prev, user]);
        form.setFieldValue('sharedUsers', selectedUsers);
      }

      if (action === 'add') {
        setSelectedUsers(prev => [...prev, { ...user, permission: 'read' }]);
        setFilteredEntities(prev => prev.filter(u => u.id !== user.id || u.type !== user.type));
        form.setFieldValue('sharedUsers', [...selectedUsers, { ...user, permission: 'read' }]);
      }
    },
    [setSelectedUsers, setFilteredEntities, selectedUsers],
  );

  const handlePermissionChange = useCallback(
    (value: EntityPermissionsEnum | string, data: ShareableEntity) => {
      if (value === 'remove') {
        const newArray = selectedUsers.filter(u => u.id !== data.id);
        setSelectedUsers(newArray);
        setFilteredEntities((prev: ShareableEntity[]) => [...prev, data]);
        form.setFieldValue('sharedUsers', newArray);
      } else {
        const newArray = selectedUsers.map(u =>
          u.id === data.id && u.type === data.type ? { ...u, permission: value } : u,
        );
        setSelectedUsers(newArray);
        form.setFieldValue('sharedUsers', newArray);
      }
    },
    [setSelectedUsers, setFilteredEntities, selectedUsers],
  );

  const renderUserItem = useCallback(
    ({ id, name, picture, type, ...rest }: ShareableEntityProps) => (
      <Flex
        align="center"
        gap="8px"
        className={styles.userItem}
        key={`${type}-${id}`}
        onClick={() => {
          setIsDropdownOpen(false);
          handleUserChange({ id, name, picture, type, ...rest }, 'add');
        }}
      >
        {picture !== '' ? (
          <Avatar size={24} src={picture} />
        ) : (
          <Avatar className={styles.emptyAvatar}>{name.charAt(0).toUpperCase()}</Avatar>
        )}
        {name}
      </Flex>
    ),
    [handleUserChange, selectedUsers],
  );

  return (
    <Form form={form} initialValues={initialValues} layout="vertical" className={styles.form}>
      <Flex vertical gap="32px" className={styles.formContainer}>
        <Form.Item className={styles.formItem} name="shareWith" label="Share with">
          <Select options={SHARE_OPTIONS} className={styles.select} />
        </Form.Item>
        {shareWith === 'users' && (
          <>
            <Form.Item className={styles.formItem} name="sharedUsers" label={`Invite users to access ${entityName}`}>
              <Select
                open={isDropdownOpen}
                onDropdownVisibleChange={setIsDropdownOpen}
                dropdownRender={() => (
                  <div className={styles.userList}>
                    {filteredEntities.length > 0 ? (
                      filteredEntities.sort((a, b) => a.name.localeCompare(b.name)).map(renderUserItem)
                    ) : (
                      <div className={styles.userItem}>No user found</div>
                    )}
                  </div>
                )}
                className={styles.select}
              />
            </Form.Item>
            <Flex vertical gap={11} className={styles.selectedUserContainer}>
              {owner && <SelectedItem data={owner} isOwner />}
              {selectedUsers
                .filter(u => u.id !== owner?.id)
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(user => (
                  <SelectedItem
                    key={`${user.type}-${user.id}`}
                    data={user}
                    onPermissionChange={handlePermissionChange}
                  />
                ))}
            </Flex>
          </>
        )}
      </Flex>
    </Form>
  );
};
