import { Button, Flex, Layout, Typography, theme } from 'antd';
import Logo from '../assets/logo.svg?react';
import { useRouteError } from 'react-router-dom';
import { copyToClipboard } from 'utils/copyToClipboard';
import CopyIcon from '../assets/icons/copy.svg?react';
import { CSSProperties, useCallback, useState } from 'react';
import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';
import { ErrorCode, redirectToLogin } from 'types/errorCodes';

const { useToken } = theme;
const { Text, Title } = Typography;

export const ErrorPage = ({ type = 'error' }: { type?: 'error' | 'unauthorized' }) => {
  const error = useRouteError();
  const [isErrorVisible, setIsErrorVisible] = useState(false); // State to manage error visibility
  let errorMessage;
  const { token } = useToken();
  let content;

  const styles = {
    container: {
      margin: '0 auto',
      padding: `${token.paddingXL}px`,
      width: '380px',
    },
    layout: {
      background: 'white',
      justifyContent: 'center',
      height: '100vh',
    },
    footer: {
      marginTop: token.marginLG,
      textAlign: 'center',
      width: '100%',
    },
    forgotPassword: {
      float: 'right',
    },
    header: {
      marginBottom: token.marginXL,
      textAlign: 'center' as const,
    },
    logo: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      marginBottom: token.marginXL,
    },
    section: {
      alignItems: 'center',
      backgroundColor: token.colorBgContainer,
      display: 'flex',
      height: 'auto',
      padding: `${token.sizeXXL}px 0px`,
    },
    error: {
      background: 'var(--nri-color-primary-light-bg)',
      borderRadius: '8px',
      border: `1px solid var(--nri-color-danger)`,
      color: token.colorTextSecondary,
      display: 'flex',
      flex: '1:',
      flexDirection: 'column',
      justifyContent: 'start',
      margin: '16px',
      padding: '8px 16px',
    } as CSSProperties,
    errorContainer: {
      display: isErrorVisible ? 'flex' : 'none',
    },
    text: {
      color: token.colorTextSecondary,
    },
    title: {
      fontSize: token.fontSizeHeading2,
    },
    caret: {
      cursor: 'pointer',
      alignItems: 'center',
    },
  };

  if (typeof error === 'string') {
    errorMessage = error;
  } else if (error instanceof Error) {
    errorMessage = error.message;
  } else if (error && typeof error === 'object' && 'statusText' in error) {
    errorMessage = (error as { statusText: string }).statusText;
  } else {
    errorMessage = '';
  }

  const copyErrorMessage = useCallback(
    () => copyToClipboard(errorMessage, 'Successfully copied', 'Failed to copy'),
    [errorMessage],
  );

  const toggleErrorVisibility = () => {
    setIsErrorVisible(!isErrorVisible);
  };

  const handleLoginRedirect = () => {
    const code = type === 'unauthorized' ? ErrorCode.UNAUTHORIZED : ErrorCode.UNKNOWN_ERROR;
    redirectToLogin(code);
  };

  switch (type) {
    case 'unauthorized': {
      content = (
        <Text style={styles.text}>
          You are not authorized to view this page. Please login to continue.
          <br />
          <br />
          <Button onClick={handleLoginRedirect}>Login</Button>
        </Text>
      );
      break;
    }
    default: {
      content = (
        <>
          <Text style={styles.text}>
            <div style={styles.caret} onClick={toggleErrorVisibility}>
              {isErrorVisible ? <CaretDownOutlined /> : <CaretRightOutlined />}{' '}
              <span>An unexpected error occurred.</span>
            </div>
            {errorMessage && (
              <Flex align="center" style={styles.errorContainer}>
                <Flex style={styles.error} onClick={copyErrorMessage}>
                  {errorMessage}
                </Flex>
                <Button onClick={copyErrorMessage} icon={<CopyIcon />} />
              </Flex>
            )}
            <br />
            <Button onClick={() => (window.location.href = '/')}>Reload App</Button>
          </Text>
        </>
      );
    }
  }

  return (
    <Layout style={styles.layout}>
      <section style={styles.section}>
        <div style={styles.container}>
          <div style={styles.logo}>
            <Logo width={130} />
          </div>
          <div style={styles.header}>
            <Title style={styles.title}>Error</Title>
            {content}
          </div>
        </div>
      </section>
    </Layout>
  );
};
