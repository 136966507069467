import { Actions } from 'types/actionTypes';
import { CommonStatusEnum } from 'types/enum';

export interface SharedEntitiesState {
  pagination: PaginationType;
  sharedEntities: Map<ShareableEntity['id'], ShareableEntity>;
  sharedEntitiesByConversation: {
    status: CommonStatusEnum;
    results: Map<ShareableEntity['id'], ShareableEntity>;
    pagination: PaginationType;
  };
  status: CommonStatusEnum;
}

export const initialSharedEntitiesState = {
  pagination: {
    next: null,
    previous: null,
    count: 0,
  },
  sharedEntities: new Map<ShareableEntity['id'], ShareableEntity>(),
  sharedEntitiesByConversation: {
    status: CommonStatusEnum.INITIAL,
    results: new Map<ShareableEntity['id'], ShareableEntity>(),
    pagination: {
      next: null,
      previous: null,
      count: 0,
    },
  },
  status: CommonStatusEnum.INITIAL,
};
export enum SharedEntitiesActionTypes {
  FETCHING_SHAREABLE_ENTITIES = 'FETCHING_SHAREABLE_ENTITIES',
  FETCH_SHAREABLE_ENTITIES = 'FETCH_SHAREABLE_ENTITIES',
  FETCHING_SHARED_ENTITIES_BY_CONVERSATION = 'FETCHING_SHARED_ENTITIES_BY_CONVERSATION',
  FETCH_SHARED_ENTITIES_BY_CONVERSATION = 'FETCH_SHARED_ENTITIES_BY_CONVERSATION',
  CLEAN_SHARED_ENTITIES_BY_CONVERSATION = 'CLEAN_SHARED_ENTITIES_BY_CONVERSATION',
}

export type SharedEntitiesAction =
  | { type: SharedEntitiesActionTypes.FETCHING_SHAREABLE_ENTITIES }
  | {
      type: SharedEntitiesActionTypes.FETCH_SHAREABLE_ENTITIES;
      payload: {
        sharedEntities: ShareableEntity[];
        pagination: PaginationType;
      };
    }
  | { type: SharedEntitiesActionTypes.FETCHING_SHARED_ENTITIES_BY_CONVERSATION }
  | {
      type: SharedEntitiesActionTypes.FETCH_SHARED_ENTITIES_BY_CONVERSATION;
      payload: {
        sharedEntities: ShareableEntity[];
        pagination: PaginationType;
      };
    }
  | { type: SharedEntitiesActionTypes.CLEAN_SHARED_ENTITIES_BY_CONVERSATION };

export const sharedEntitiesReducer = (state: SharedEntitiesState, action: Actions): SharedEntitiesState => {
  switch (action.type) {
    case SharedEntitiesActionTypes.FETCHING_SHAREABLE_ENTITIES:
      return { ...state, status: CommonStatusEnum.FETCHING };

    case SharedEntitiesActionTypes.FETCH_SHAREABLE_ENTITIES: {
      const { sharedEntities, pagination } = action.payload;
      const latestSharedEntities = new Map(sharedEntities.map(entity => [entity.id, entity]));
      return {
        ...state,
        pagination,
        sharedEntities: new Map([...state.sharedEntities, ...latestSharedEntities]),
        status: CommonStatusEnum.FETCHED,
      };
    }
    case SharedEntitiesActionTypes.FETCHING_SHARED_ENTITIES_BY_CONVERSATION:
      return {
        ...state,
        sharedEntitiesByConversation: {
          ...state.sharedEntitiesByConversation,
          status: CommonStatusEnum.FETCHING,
        },
      };
    case SharedEntitiesActionTypes.FETCH_SHARED_ENTITIES_BY_CONVERSATION: {
      const { sharedEntities, pagination } = action.payload;
      return {
        ...state,
        sharedEntitiesByConversation: {
          pagination,
          results: new Map(sharedEntities.map(entity => [entity.id, entity])),
          status: CommonStatusEnum.FETCHED,
        },
      };
    }
    case SharedEntitiesActionTypes.CLEAN_SHARED_ENTITIES_BY_CONVERSATION:
      return {
        ...state,
        sharedEntitiesByConversation: {
          pagination: {
            next: null,
            previous: null,
            count: 0,
          },
          results: new Map(),
          status: CommonStatusEnum.INITIAL,
        },
      };

    default:
      return state;
  }
};
