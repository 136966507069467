import { ConfigProvider, Radio } from 'antd';
import styles from './EntityList.module.scss';
import ListViewIcon from '../../assets/iconsNewVersion/list_view.svg?react';
import CardViewIcon from '../../assets/iconsNewVersion/card_view.svg?react';
import { ViewOptionsEnum } from 'types/enum';
import { Dispatch, FC, SetStateAction } from 'react';

type EntityViewOptionsProps = {
  view: ViewOptionsEnum;
  setView: Dispatch<SetStateAction<ViewOptionsEnum>>;
  viewOptions?: { option: ViewOptionsEnum; icon: FC<{ className?: string }>; disabled?: boolean }[];
};

export const EntityViewOptions: FC<EntityViewOptionsProps> = ({ view, setView, viewOptions }) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimaryActive: 'transparent',
        },
        components: {
          Radio: {
            buttonBg: 'var(--nri-color-light-grey)',
            buttonCheckedBg: 'var(--nri-color-light-grey)',
            buttonColor: 'none',
            borderRadius: 10,
          },
        },
      }}
    >
      {!viewOptions ? (
        <Radio.Group className={styles.viewOptions} onChange={e => setView(e.target.value)} value={view}>
          <Radio.Button className={styles.viewOptionsButton} value={ViewOptionsEnum.CARD}>
            <CardViewIcon className={styles.viewOptionsIcon} />
          </Radio.Button>
          <Radio.Button className={styles.viewOptionsButton} value={ViewOptionsEnum.LIST}>
            <ListViewIcon className={styles.viewOptionsIcon} />
          </Radio.Button>
        </Radio.Group>
      ) : (
        <Radio.Group className={styles.viewOptions} onChange={e => setView(e.target.value)} value={view}>
          {viewOptions?.map(({ option, icon: Icon, disabled }) => (
            <Radio.Button className={styles.viewOptionsButton} disabled={disabled} value={option}>
              <Icon className={styles.viewOptionsIcon} />
            </Radio.Button>
          ))}
        </Radio.Group>
      )}
    </ConfigProvider>
  );
};
