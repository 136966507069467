import { message } from 'antd';
import { ConnectorSupportedFileTypesEnum } from '../types/enum';
import { allowedFileTypes } from './dataSourceTypes';

export const supportedFileTypes = Object.values(allowedFileTypes).flatMap(fileType => fileType.type);

export const validateDatasourceFile = (file: File) => {
  if (!supportedFileTypes.includes(file.type as ConnectorSupportedFileTypesEnum) && !file.name.includes('.hyper')) {
    message.error('Error: Unsupported file type. Please upload a valid file format.');
    return false;
  }
  return true;
};
