import { SVGProps } from 'react';
import { DatasourceTypesEnum } from 'types/enum';
import RedshiftIcon from '../assets/connectors/redshift.svg?react';
import SnowflakeIcon from '../assets/connectors/snowflake.svg?react';
import BigqueryIcon from '../assets/connectors/bigquery.svg?react';
import PostgresIcon from '../assets/connectors/postgres.svg?react';
import CsvIcon from '../assets/connectors/csv.svg?react';
import GoogleSlidesIcon from '../assets/connectors/googleslides.svg?react';
import GoogleSheetsIcon from '../assets/connectors/googlesheets.svg?react';
import TtdIcon from '../assets/connectors/ttd_platform.svg?react';
import HabuIcon from '../assets/connectors/habu.svg?react';
import XlsIcon from '../assets/connectors/xls.svg?react';
import HyperIcon from '../assets/connectors/hyper.svg?react';

export const iconMap: Partial<Record<DatasourceTypesEnum, React.ComponentType<SVGProps<SVGSVGElement>>>> = {
  [DatasourceTypesEnum.BIGQUERY]: BigqueryIcon,
  [DatasourceTypesEnum.CSV]: CsvIcon,
  [DatasourceTypesEnum.GOOGLE_SHEETS]: GoogleSheetsIcon,
  [DatasourceTypesEnum.GOOGLE_SLIDES]: GoogleSlidesIcon,
  [DatasourceTypesEnum.HABU]: HabuIcon,
  [DatasourceTypesEnum.HYPER]: HyperIcon,
  [DatasourceTypesEnum.POSTGRES]: PostgresIcon,
  [DatasourceTypesEnum.REDSHIFT]: RedshiftIcon,
  [DatasourceTypesEnum.SNOWFLAKE]: SnowflakeIcon,
  [DatasourceTypesEnum.TTD]: TtdIcon,
  [DatasourceTypesEnum.XLS]: XlsIcon,
};

export const allowedFileTypes = {
  [DatasourceTypesEnum.CSV]: { type: ['text/csv'], extension: ['.csv'] },
  [DatasourceTypesEnum.XLS]: {
    type: ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
    extension: ['.xls', '.xlsx'],
  },
  [DatasourceTypesEnum.HYPER]: {
    type: ['hyper'],
    extension: ['.hyper'],
  },
};
