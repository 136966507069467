import ArchiveIcon from 'assets/iconsNewVersion/archive.svg?react';
import CopyIcon from 'assets/iconsNewVersion/copy.svg?react';
import TrashIcon from 'assets/iconsNewVersion/trash.svg?react';
import { DatasourcesStep } from 'components/blueprint-form/steps/DatasourcesStep';
import { GeneralStep } from 'components/blueprint-form/steps/GeneralStep';
import { PlanInputsStep } from 'components/blueprint-form/steps/PlanInputsStep';
import WorkflowPlanStep from 'components/blueprint-form/steps/WorkflowStep';
import { EntityDetails } from 'components/entity-details/EntityDetails';
import { EntityHeader, EntityHeaderProps } from 'components/entity-header/EntityHeader';
import { FavoriteToggle } from 'components/favorite-toggle/FavoriteToggle';
import { EntityProvider } from 'components/layouts/context/EntityProvider';
import { urlParameters } from 'hooks/useURLParams';
import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ModalScopesEnum, ModalTypesEnum } from 'reducers/modalsReducer';
import { useAuth } from 'selectors/useAuthSelector';
import { useToggleBlueprintFavorite } from 'selectors/useBlueprints';
import { useModal } from 'selectors/useModals';
import { EntityActionsEnum } from 'types/enum';
import styles from '../entity-details/EntityDetails.module.scss';

export type BlueprintFormData = {
  dataSources: DataSourceId[];
  description: string;
  examples: {
    code: string;
    index: string;
  }[];
  hints: {
    hint: string;
  }[];
  initialMessage: string;
  inputParameters: string;
  // memories: MemoryId[];
  name: string;
  owner: UserEntity['id'];
  shareWith: [BaseEntity['id'], 'read' | 'write' | 'owner'][];
  shareWithOrganization: boolean;
  projectLabels: ProjectLabelId[];
  steps: {
    code?: string;
    detailedInstructions?: string;
    id?: BlueprintStepId;
    memories: MemoryId[];
    name: string;
    qaInstructions?: string;
    analyst?: AnalystId;
  }[];
};

type BlueprintViewProps = {
  blueprint?: Blueprint;
  edit: boolean;
  isNew: boolean;
  initialValues?: Partial<BlueprintFormData>;
  onSubmit: (values: BlueprintFormData) => Promise<void>;
};

export const BlueprintView: React.FC<BlueprintViewProps> = memo(
  ({ edit, isNew = false, onSubmit, blueprint, initialValues = {} }) => {
    const { me } = useAuth();
    const navigate = useNavigate();
    const urlParams = urlParameters({
      blueprint: blueprint?.id || 0,
    });

    const toggleBlueprintFavorite = useToggleBlueprintFavorite();
    const { show: showArchiveModal } = useModal<{ onConfirm: () => void; blueprint: Blueprint }>(
      ModalScopesEnum.BLUEPRINTS,
      ModalTypesEnum.ARCHIVE,
    );
    const { show: showDeleteModal } = useModal<{ onConfirm: () => void; blueprint: Blueprint }>(
      ModalScopesEnum.BLUEPRINTS,
      ModalTypesEnum.DELETE,
      `/blueprints`,
    );
    const { show: showDuplicateModal } = useModal(ModalScopesEnum.BLUEPRINTS, ModalTypesEnum.DUPLICATE);

    const steps = [
      {
        title: 'General',
        key: 'general',
        description: '',
        content: <GeneralStep />,
      },
      {
        title: 'Connectors',
        key: 'datasources',
        description: '',
        content: <DatasourcesStep />,
      },
      {
        title: 'Plan inputs',
        formTitle: 'Input parameters and initial user message',
        key: 'inputs',
        description: (
          <>
            Define what information the blueprint needs to collect from the user in order to get started. Newton will
            autogenerate an initial message to the user from the description field and from the inputs you specify
            below.
            <br />
            If the user needs more information, you may add it to the initial message below.
          </>
        ),
        content: <PlanInputsStep />,
      },
      {
        title: 'Workflow Plan',
        key: 'plan',
        className: 'workflow-plan',
        description: 'Set your workflow',
        content: <WorkflowPlanStep />,
      },
      // {
      //   title: 'Linked memories',
      //   key: 'linked',
      //   description: 'Some description',
      //   content: <LinkedHelperMemoriesStep />,
      // },
      // {
      //   title: 'Access',
      //   key: 'access',
      //   description: 'Some description',
      //   content: <AccessStep />,
      // },
    ];

    const headerProps: Partial<EntityHeaderProps<boolean>> = {};

    if (blueprint?.id) {
      headerProps.actions = [
        {
          key: EntityActionsEnum.DELETE,
          disabled: blueprint?.owner !== me?.id,
          icon: (
            <TrashIcon color={blueprint?.owner !== me?.id ? 'var(--nri-color-grey-stroke)' : 'var(--nri-color-red)'} />
          ),
          onClick: () => {
            showDeleteModal({
              onConfirm: () => {
                navigate('/blueprints');
              },
              blueprint,
            });
          },
        },
        {
          key: EntityActionsEnum.FAVORITE,
          icon: <FavoriteToggle isFavorite={blueprint.isFavorite!} />,
          onClick: () => {
            toggleBlueprintFavorite(blueprint);
          },
        },
        {
          key: EntityActionsEnum.ARCHIVE,
          icon: <ArchiveIcon />,
          disabled: blueprint?.isLibrary && !me?.isSuperuser,
          onClick: () => {
            showArchiveModal({
              onConfirm: () => {
                // Would have liked to use -1 to go back to the previous page, there seems to be a bug with
                // react router and their useParams hook, where it keeps pickuping up the last set of params
                navigate('/blueprints');
              },
              blueprint,
            });
          },
        },
        {
          key: EntityActionsEnum.COPY,
          icon: <CopyIcon />,
          onClick: () => {
            showDuplicateModal(blueprint);
          },
        },
      ];
      headerProps.rightSideButtonText = 'Run blueprint';
      headerProps.rightSideButtonOnClick = () => {
        navigate(`/conversations/new?${urlParams}`);
      };
    }

    return (
      <EntityProvider<BlueprintFormData>
        steps={steps}
        initialFormValue={{
          dataSources: blueprint?.dataSources.map(ds => ds.id) ?? [],
          description: blueprint?.description ?? '',
          examples: [],
          hints: [],
          initialMessage: blueprint?.initialMessage ?? '',
          inputParameters: blueprint?.inputParameters ?? '',
          // memories: blueprint?.memories.map(m => m.id) ?? [],
          name: blueprint?.name ?? '',
          owner: me!.id,
          projectLabels: blueprint?.projectLabels.map(l => l.id) ?? [],
          // @TOOD not a thing yet
          shareWith: [],
          shareWithOrganization: blueprint?.shareWithOrganization ?? false,
          steps: blueprint?.steps
            .sort((a, b) => a.order - b.order)
            .map(step => ({
              ...step,
              memories:
                step.memories.map(m => {
                  if (typeof m === 'number') {
                    return m;
                  }
                  return m.id;
                }) || [],
            })) || [
            {
              code: undefined,
              detailedInstructions: undefined,
              memories: [],
              name: 'Step 1',
              qaInstructions: undefined,
            },
          ],
          ...initialValues,
        }}
        isNew={isNew}
        readOnly={!edit}
        onSubmit={onSubmit}
      >
        <EntityHeader isEditable={false} title={blueprint?.name ?? ''} canGoBack {...headerProps} />
        <EntityDetails<BlueprintFormData>
          className={styles.blueprintDetails}
          isEditable={blueprint?.owner ? blueprint?.owner === me?.id : true}
          entityName="blueprint"
        />
      </EntityProvider>
    );
  },
);
