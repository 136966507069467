import { Tag, Typography } from 'antd';
import CreditsIcon from 'assets/iconsNewVersion/diamond.svg?react';
import styles from './UsageTag.module.scss';
import { FC } from 'react';
import { formatNumberWithSpace } from 'utils/FormatWithSpace';

type UsageTagProps = {
  credits: number;
  description?: boolean;
  secondaryDescription?: string;
  subscription?: number;
};

export const UsageTag: FC<UsageTagProps> = ({ credits, description, secondaryDescription, subscription }) => {
  return (
    <Tag className={styles.tag} icon={<CreditsIcon className={styles.creditsIcon} />}>
      <Typography.Text className={styles.credits}>{formatNumberWithSpace(credits)}</Typography.Text>
      {description && <Typography.Text className={styles.description}>Credits used</Typography.Text>}
      {secondaryDescription && <Typography.Text className={styles.description}>{secondaryDescription}</Typography.Text>}
      {subscription && (
        <>
          <Typography.Text className={styles.subscription}>
            / ${subscription}{' '}
            <Typography.Text className={styles.subscriptionInfo}>Monthly subscription</Typography.Text>
          </Typography.Text>
        </>
      )}
    </Tag>
  );
};
