import { Layout } from 'antd';
import { ScheduledTaskView } from 'components/scheduled-task-view/ScheduledTaskView';
import { memo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styles from './Layout.module.scss';
import { useScheduledTask } from 'selectors/useScheduledTasksSelector';

export const ScheduledTaskPage = memo(({ edit = false }: { edit?: boolean }) => {
  const { taskId } = useParams();
  const fetchScheduledTask = useScheduledTask();

  useEffect(() => {
    fetchScheduledTask(taskId as unknown as ScheduledTask['id']);
  }, [fetchScheduledTask, taskId]);

  return (
    <Layout className={styles.scheduledTaskPage}>
      <ScheduledTaskView editMode={edit} />
    </Layout>
  );
});
