import { Flex, Form, Input, message } from 'antd';
import { BlueprintTable } from 'components/blueprint-table/BlueprintTable';
import { EntityList } from 'components/entity-list/EntityList';
import { useBlueprintsWithoutInputParameters } from 'selectors/useBlueprints';
import { RetrieveTypesEnum } from 'types/enum';
import { SelectBlueprintCard } from '../common/SelectBlueprintCard';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useEntityContext } from 'components/layouts/context/EntityProvider';

export const SelectBlueprintStep = () => {
  const { filtered: blueprints, filter, loading } = useBlueprintsWithoutInputParameters();
  const { form, initialFormValue } = useEntityContext<ScheduledTaskFormData>();

  const selectedBlueprint: Blueprint[] = useMemo(() => {
    const initialBlueprint: Blueprint | undefined = blueprints.find(bp => bp.id === initialFormValue?.icebreaker[0]);
    return initialBlueprint ? [initialBlueprint] : [];
  }, [blueprints, initialFormValue.icebreaker]);

  const [selections, setSelections] = useState<Blueprint[]>([]);

  useEffect(() => {
    if (selectedBlueprint.length > 0) setSelections(selectedBlueprint);
  }, [selectedBlueprint]);

  const onFilterChange = (key: RetrieveTypesEnum) => {
    if (Object.values(RetrieveTypesEnum).includes(key)) {
      filter(key);
    } else {
      message.error(`Operation "${key}" currently not supported `);
    }
  };

  const onBlueprintSelection = useCallback(
    (entity: Blueprint) => {
      const isSelected = !!selections.find(bp => bp.id === entity.id);
      const selected = isSelected ? selections.filter(item => item.id !== entity.id) : [...selections, entity];
      if (selections.length > 0 && !isSelected) {
        return message.error('You can only select one blueprint');
      }

      if (selections.length > 0 && isSelected) {
        setSelections([]);
        form.setFieldsValue({
          icebreaker: [],
        });
      }

      setSelections(selected);
      form.setFieldsValue({
        icebreaker: selected.map(bp => bp.id),
      });
    },
    [selections, form],
  );

  return (
    <Flex vertical gap={16}>
      <Form.Item name="icebreaker" rules={[{ required: true }]} hidden>
        <Input />
      </Form.Item>
      <EntityList<Blueprint>
        components={{ Card: SelectBlueprintCard, Table: BlueprintTable }}
        entities={blueprints}
        entityName="blueprint"
        loading={loading}
        onFilterChange={onFilterChange}
        onNewEntity={undefined}
        projectFilter={false}
        selected={selections}
        showEmptyPlaceholder={false}
        viewOptions={false}
        onCardClick={onBlueprintSelection}
      />
    </Flex>
  );
};
