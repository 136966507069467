import { Flex } from 'antd';
import { UsageDailyCredits } from 'components/usage-daily-credits/UsageDailyCredits';
import { UsageDetails } from 'components/usage-details/UsageDetails';
import { UsageRemainingCredits } from 'components/usage-remaining-credits/UsageRemainingCredits';
import styles from '../Usage.module.scss';
import { UsagePeriodDropdown } from 'components/usage-period-dropdown/UsagePeriodDropdown';
import { useMemo, useState } from 'react';
import { useTimePeriods } from 'selectors/useStatisticsSelector';
import { TimePeriodOptionsEnum } from 'types/enum';

export const PayAsYouGoDashboard = () => {
  const { data: timePeriods } = useTimePeriods();

  const timePeriodData = useMemo(
    () =>
      timePeriods
        .map(e => ({ label: e.label, key: e.value as TimePeriodOptionsEnum }))
        .filter(e => e.key !== TimePeriodOptionsEnum.CUSTOM_TIME),
    [timePeriods],
  );

  const [selectedTimePeriod, setSelectedTimePeriod] = useState(timePeriodData[0]);

  return (
    <Flex vertical gap={40} className={styles.dashboard}>
      <Flex>
        <UsageRemainingCredits selectedTimePeriod={selectedTimePeriod} />
        <UsagePeriodDropdown
          timePeriodItems={timePeriodData}
          selectedTimePeriod={selectedTimePeriod}
          onSelect={item => setSelectedTimePeriod(item)}
        />
      </Flex>
      <UsageDailyCredits selectedTimePeriod={selectedTimePeriod} />
      <UsageDetails selectedTimePeriod={selectedTimePeriod} />
    </Flex>
  );
};
