import { FC } from 'react';
import styles from './ChartTooltip.module.scss';
import { Divider, Flex, Typography } from 'antd';

export type ChartTooltipData = {
  id: string;
  value?: number;
  credits: number;
};

type TooltipContentProps = {
  data: ChartTooltipData;
  name?: string;
};

export const ChartTooltip: FC<TooltipContentProps> = ({ data, name }) => {
  return (
    <div className={styles.tooltipContainer}>
      <Flex vertical className={styles.tooltipContent}>
        <Flex gap={4} align="flex-end">
          <Typography.Text className={styles.tooltipCredits}>{data.credits}</Typography.Text>
          <Typography.Text className={styles.tooltipDescription}>tokens used</Typography.Text>
        </Flex>
        <Divider className={styles.tooltipDivider} />
        <Typography.Text className={styles.tooltipEntityName}>{data.id || (name ?? '-')}</Typography.Text>
      </Flex>
      <div className={styles.tooltipArrow}></div>
    </div>
  );
};
