import { message } from 'antd';
import { useAppContext } from 'contexts/AppProviders';
import { SVGProps, useCallback, useEffect, useMemo, useState } from 'react';
import { DataSourcesActionTypes, DatasourceUploadType } from 'reducers/dataSourcesReducer';
import { CommonStatusEnum, DatasourceTypesEnum, RetrieveTypesEnum } from 'types/enum';
import { NewtonApi } from 'utils/newtonApi';
import { ErrorCode, redirectToLogin } from 'types/errorCodes';
import { allowedFileTypes, iconMap } from 'utils/dataSourceTypes';

const useDataSourcesState = () => {
  const {
    state: { dataSourcesState },
    dispatch,
  } = useAppContext();
  const fetchDataSources = useFetchDataSources();
  const { status } = dataSourcesState;

  useEffect(() => {
    if (status === CommonStatusEnum.INITIAL) {
      fetchDataSources(RetrieveTypesEnum.ALL);
    }
  }, [dispatch, fetchDataSources, status]);

  return { state: dataSourcesState };
};

export const useDataSource = () => {
  const { dispatch } = useAppContext();

  return useCallback(
    async (id: DataSource['id']) => {
      dispatch({
        type: DataSourcesActionTypes.FETCHING_DATASOURCE,
      });

      try {
        const [dataSource, permissions] = await Promise.all([
          NewtonApi.fetchDatasource(id, true),
          NewtonApi.fetchDatasourcePermissions(id),
        ]);
        await dispatch({
          type: DataSourcesActionTypes.FETCH_DATASOURCE,
          payload: dataSource,
        });

        const shareableEntities = permissions.map((elem: { actor: ShareableEntity; permission: string }) => {
          return { ...elem.actor, permission: elem.permission };
        });

        await dispatch({
          type: DataSourcesActionTypes.FETCH_DATASOURCE_PERMISSIONS,
          payload: { entities: shareableEntities, ownerId: dataSource.ownerId },
        });
      } catch (error) {
        redirectToLogin(ErrorCode.DATASOURCE_NOT_FOUND);
      }
    },
    [dispatch],
  );
};

const useDataSourceTypesState = () => {
  const {
    state: { dataSourcesState },
    dispatch,
  } = useAppContext();
  const { status } = dataSourcesState.dataSourceTypes;

  useEffect(() => {
    if (status === CommonStatusEnum.INITIAL) {
      (async () => {
        dispatch({
          type: DataSourcesActionTypes.FETCHING_DATASOURCE_TYPES,
        });
        const { results } = await NewtonApi.fetchDatasourceTypes();
        dispatch({
          type: DataSourcesActionTypes.FETCH_DATASOURCE_TYPES,
          payload: results,
        });
      })();
    }
  }, [dispatch, status]);

  return { state: dataSourcesState };
};

const useFetchDataSources = () => {
  const { dispatch } = useAppContext();

  return useCallback(
    async (retrieveType: RetrieveTypesEnum) => {
      dispatch({
        type: DataSourcesActionTypes.FETCHING_DATASOURCES,
        payload: retrieveType,
      });
      const [{ results: dataSources, ...pagination }] = await Promise.all([
        NewtonApi.fetchAllDataSources(retrieveType, true),
      ]);

      dispatch({
        type: DataSourcesActionTypes.FETCH_DATASOURCES,
        payload: { dataSources, pagination, retrieveType: retrieveType },
      });
      return dataSources;
    },
    [dispatch],
  );
};

export const useDataSources = () => {
  const {
    state: { scope, status },
  } = useDataSourcesState();

  return {
    dataSources: Array.from(scope[RetrieveTypesEnum.ALL].dataSources.values()),
    loading: useMemo(() => status === CommonStatusEnum.INITIAL, [status]),
    status,
  };
};

export const useFilterDataSources = () => {
  const {
    state: { scope, loading },
  } = useDataSourcesState();
  const [filter, setFilter] = useState<RetrieveTypesEnum>(RetrieveTypesEnum.ALL);
  const fetchDataSources = useFetchDataSources();

  const dataSourceScope = useMemo(() => {
    return scope[filter];
  }, [scope, filter]);

  const dataSources = useMemo(() => {
    return Array.from(dataSourceScope.dataSources.values());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSourceScope, dataSourceScope?.dataSources?.size]);

  useEffect(() => {
    if (!loading && dataSourceScope.status === CommonStatusEnum.INITIAL) {
      fetchDataSources(filter);
    }
  }, [dataSourceScope, loading, filter, fetchDataSources]);

  return {
    filteredDataSources: dataSources,
    filterDataSources: useCallback(
      (filter: RetrieveTypesEnum) => {
        setFilter(filter);
      },
      [setFilter],
    ),
    status: dataSourceScope.status,
    loading: useMemo(
      () => dataSourceScope.status === CommonStatusEnum.INITIAL || dataSourceScope.status === CommonStatusEnum.FETCHING,
      [dataSourceScope.status],
    ),
  };
};

export const useDataSourceTypes = () => {
  const {
    state: { dataSourceTypes },
  } = useDataSourceTypesState();

  const typesDataObject: Record<
    DatasourceTypesEnum,
    DataSourceTypeData & { icon?: React.ComponentType<SVGProps<SVGSVGElement>> | null }
  > = useMemo(() => {
    return Array.from(dataSourceTypes.types.values()).reduce(
      (acc, item) => {
        acc[item.name as DatasourceTypesEnum] = { ...item, icon: iconMap[item.name as DatasourceTypesEnum] ?? null };

        return acc;
      },
      {} as Record<
        DatasourceTypesEnum,
        DataSourceTypeData & { icon?: React.ComponentType<SVGProps<SVGSVGElement>> | null }
      >,
    );
  }, [dataSourceTypes.types]);

  return {
    loading:
      dataSourceTypes.status === CommonStatusEnum.INITIAL || dataSourceTypes.status === CommonStatusEnum.FETCHING,
    types: Array.from(dataSourceTypes.types.values()),
    typesDataObject,
  };
};

export const useActiveDataSource = () => {
  const {
    state: { activeDatasource },
  } = useDataSourcesState();

  const { status, ...rest } = activeDatasource;

  const dataSource = useMemo(() => {
    return {
      ...rest,
      loading: status === CommonStatusEnum.INITIAL || status === CommonStatusEnum.FETCHING,
    };
  }, [rest, status]);

  return dataSource;
};

export const useUploadDataSource = (conversationId?: Conversation['id']) => {
  const { dispatch } = useAppContext();
  const fetchDataSources = useFetchDataSources();

  return useCallback(
    async (formData: FormData, uploadType: DatasourceUploadType) => {
      if (conversationId) {
        formData.append('conversation_id', `${conversationId}`);
      }
      const dataSource = await NewtonApi.uploadMemoryFile(formData, true);

      dispatch({
        type: DataSourcesActionTypes.FETCH_DATASOURCE,
        payload: dataSource,
      });
      const retrieveType =
        uploadType === DatasourceUploadType.ORGANIZATION
          ? RetrieveTypesEnum.ORGANIZATION
          : uploadType === DatasourceUploadType.USER
            ? RetrieveTypesEnum.OWNED
            : RetrieveTypesEnum.ALL;
      await fetchDataSources(retrieveType);

      return dataSource;
    },
    [conversationId, dispatch, fetchDataSources],
  );
};

export const useConversationDataSources = (conversationId?: Conversation['id']) => {
  const {
    state: { scope },
  } = useDataSourcesState();

  return {
    all: useMemo(() => {
      if (!conversationId) return [];
      const container = scope.conversations.get(conversationId);

      if (!container) return [];

      const byMessage = Array.from(container.dataSourceByMessageId.values()).flat();

      // Remove duplicate ids
      return [...new Map([...byMessage, ...container.dataSources].map(item => [item['id'], item])).values()];
    }, [scope, conversationId]),
    byMessageId: useCallback(
      (messageId: Message['id']) => {
        if (!conversationId) return [];

        return Array.from(scope.conversations.get(conversationId)?.dataSourceByMessageId.get(messageId) || []);
      },
      [scope, conversationId],
    ),
  };
};

export const useDataSourceByConversationIdMessageId = () => {
  const {
    state: { scope },
  } = useDataSourcesState();

  return useCallback(
    (conversationId: Conversation['id'], messageId: Message['id']) => {
      return scope.conversations.get(conversationId)?.dataSourceByMessageId.get(messageId);
    },
    [scope],
  );
};

export const useArchiveDataSource = (id: DataSource['id']) => {
  const { dispatch } = useAppContext();
  const remove = useCallback(async () => {
    try {
      await NewtonApi.archiveDatasource(id, true);
    } catch (error) {
      message.error('Failed to archive connector');
      return;
    }

    dispatch({
      type: DataSourcesActionTypes.ARCHIVE_DATASOURCE,
      payload: id,
    });

    message.success('Connector archived successfully');
  }, [dispatch, id]);

  return remove;
};

export const useDeleteDataSource = (id: DataSource['id']) => {
  const { dispatch } = useAppContext();
  const remove = useCallback(async () => {
    try {
      await NewtonApi.deleteDatasource(id, true);
    } catch (error) {
      message.error('Failed to delete connector');
      return;
    }
    dispatch({
      type: DataSourcesActionTypes.DELETE_DATASOURCE,
      payload: id,
    });
    message.success('Connector deleted successfully');
  }, [dispatch, id]);

  return remove;
};

export const useUpdateDataSourceSelector = () => {
  const { dispatch } = useAppContext();

  return useCallback(
    async (id: DataSource['id'], payload: UpdateDataSourcePayload, hasSupportedFile: boolean) => {
      try {
        const data = hasSupportedFile
          ? await NewtonApi.updateDatasourceWithSupportedFormat(id, payload, true)
          : await NewtonApi.updateDatasource(id, payload, true);

        dispatch({
          type: DataSourcesActionTypes.UPDATE_DATASOURCE,
          payload: { data: data as unknown as DataSource, csv: payload.file ?? undefined },
        });
      } catch (error) {
        message.error('Failed to update connector');

        return;
      }

      message.success('Connector updated successfully');
    },
    [dispatch],
  );
};

export const useMarkAsFavorite = () => {
  const { dispatch } = useAppContext();

  return useCallback(
    async (id: DataSource['id'], isFavorite: boolean) => {
      try {
        await NewtonApi.toggleDataSourceAsFavorite(id, !isFavorite);
      } catch (error) {
        message.error(`Failed to mark connector as ${isFavorite ? 'favorite' : 'unfavorite'}`);
        return;
      }
      dispatch({
        type: DataSourcesActionTypes.TOGGLE_FAVORITE_DATASOURCE,
        payload: !isFavorite,
      });
    },
    [dispatch],
  );
};

export const useGetFile = () => {
  const { dispatch } = useAppContext();
  return useCallback(
    async (id: DataSource['id'], name: string, type: DatasourceTypesEnum) => {
      const fileType = allowedFileTypes[type as keyof typeof allowedFileTypes].type[0];
      try {
        const response = await NewtonApi.fetchFile(id, name, fileType);
        dispatch({
          type: DataSourcesActionTypes.FETCH_DATASOURCE_FILE,
          payload: response as unknown as File,
        });
      } catch (error) {
        message.error('Failed to fetch CSV');
        return;
      }
    },
    [dispatch],
  );
};

export const useCleanActiveDataSource = () => {
  const { dispatch } = useAppContext();
  return useCallback(() => {
    dispatch({ type: DataSourcesActionTypes.CLEAN_DATASOURCE });
  }, [dispatch]);
};

export const useDownloadDataSource = () => {
  return useCallback(async (id: DataSource['id'], name: DataSource['name']) => {
    await NewtonApi.downloadDatasource(id, name, true);
  }, []);
};

export const useManageAccessToDataSource = () => {
  const { dispatch } = useAppContext();
  return useCallback(
    async (
      id: DataSource['id'],
      permissions: { users?: { id: number; permission: string }[]; organizations?: { id: number; permission: string } },
      ownerId: number,
    ) => {
      const response = await NewtonApi.updateDatasourcePermissions(id, permissions);
      const shareableEntities = response.map((elem: { actor: ShareableEntity; permission: string }) => {
        return { ...elem.actor, permission: elem.permission };
      });

      dispatch({
        type: DataSourcesActionTypes.FETCH_DATASOURCE_PERMISSIONS,
        payload: { entities: shareableEntities, ownerId },
      });
    },
    [],
  );
};

export const useFetchDataSourcesByIds = () => {
  const {
    state: { scope },
  } = useDataSourcesState();

  return useCallback(
    async (ids: DataSourceId[]) => {
      const existingDataSources = Array.from(scope[RetrieveTypesEnum.ALL].dataSources.values());
      const foundDataSources: DataSource[] = [];
      const missingIds: DataSourceId[] = [];

      ids.forEach(id => {
        const found = existingDataSources.find(ds => ds.id === id);
        if (found) {
          foundDataSources.push(found);
        } else {
          missingIds.push(id);
        }
      });

      // not going to update the store with these datasource if some were missing to avoid
      // pagination issues
      const fetchedDataSources = await Promise.all(
        missingIds.map(async id => {
          try {
            return await NewtonApi.fetchDatasource(id, true);
          } catch (error) {
            message.error(`Failed to fetch data source ${id}`);
            return null;
          }
        }),
      );

      return [...foundDataSources, ...fetchedDataSources.filter((ds): ds is DataSource => ds !== null)];
    },
    [scope],
  );
};
