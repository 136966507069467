import { Typography } from 'antd';
import { Step as IStep } from 'components/layouts/context/EntityProvider';
import styles from 'ui/UIKit.module.scss';
import entityStyles from './EntityDetails.module.scss';
import classNames from 'classnames';

type Props = {
  active: boolean;
  forwardRef: React.MutableRefObject<HTMLDivElement | null>;
  step: IStep;
};
export const Step: React.FC<Props> = ({ active, step, forwardRef }) => {
  const key = (step.key || step.title).split(' ').join('-').toLowerCase();
  return (
    <div
      key={`step-${key}`}
      ref={active ? forwardRef : null}
      className={classNames(key, step.className, { [entityStyles.activeForm]: active })}
      style={{ display: active ? 'block' : 'none' }}
    >
      {active ? (
        <div style={{ marginBottom: 24 }}>
          <Typography.Title className={styles.title} level={1}>
            {step.formTitle || step.title}
          </Typography.Title>
          {step.description ? (
            <Typography.Title className={styles.subtitle} level={5}>
              {step.description}
            </Typography.Title>
          ) : null}
        </div>
      ) : null}
      {step.content}
    </div>
  );
};
