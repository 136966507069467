import { ConfirmationModal } from 'components/common/ConfirmationModal';
import { useCallback, useState } from 'react';
import { ModalScopesEnum, ModalTypesEnum } from 'reducers/modalsReducer';
import { useModal } from 'selectors/useModals';
import { useDeleteScheduledTask } from 'selectors/useScheduledTasksSelector';
export const DeleteScheduledTaskModal = () => {
  const { data: scheduledTask, hide } = useModal<ScheduledTask>(ModalScopesEnum.SCHEDULED_TASKS, ModalTypesEnum.DELETE);
  const [processing, setProcessing] = useState(false);

  const remove = useDeleteScheduledTask();
  const onConfirm = useCallback(async () => {
    setProcessing(true);
    await remove(scheduledTask.id);
    hide();
    setProcessing(false);
  }, [remove, hide]);

  return (
    <ConfirmationModal
      key={'scheduled-task::delete'}
      isOpen
      confirmationButton={processing ? 'Deleting' : 'Delete'}
      title={'Delete Scheduled Work'}
      description={
        <>
          Are you sure you want to delete this scheduled task <strong>{scheduledTask.name}</strong>?
        </>
      }
      onCancel={() => {
        hide();
      }}
      onConfirm={onConfirm}
      cancelButton={'Cancel'}
      confirmColor={'#E23C26'}
      processing={processing}
    />
  );
};
