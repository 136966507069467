import { InboxIcon } from '@heroicons/react/24/outline';
import { Flex, Layout, Menu, Skeleton } from 'antd';
import classNames from 'classnames';
import { NewConversationButton } from 'components/new-converation-btn/NewConversationButton';
import { ProjectSelect } from 'components/project-select/ProjectSelect';
import styles from 'components/sidebar/Sidebar.module.scss';
import { SidebarItem } from 'components/sidebar/SidebarItem';
import { formatDate, parseISO } from 'date-fns';
import { AnimatePresence } from 'framer-motion';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useAuth } from 'selectors/useAuthSelector';
import {
  useActiveConversation,
  useArchivedConversations,
  useConversation,
  useConversations,
  useLoadMoreConversations,
} from 'selectors/useConversationSelectors';
import { CommonStatusEnum } from 'types/enum';
import { Button } from 'ui/Button';
import { InputSearch } from 'ui/InputSearch';
import { InputSearchOption } from 'ui/InputSearchOption';
import { Segmented } from 'ui/Segmented';

type Props = { onChange?(t: string): void };

const LoadMoreButton = ({ onClick, loading }: { onClick: () => void; loading: boolean }) => {
  return (
    <Button
      type="primary"
      style={{
        alignSelf: 'center',
        margin: '20px 0',
      }}
      disabled={loading}
      onClick={() => onClick()}
    >
      Load More
    </Button>
  );
};

export const Sidebar: React.FC<Props> = () => {
  const { me } = useAuth();
  const { conversation } = useActiveConversation();
  const { conversationId } = useParams();
  const {
    conversations: archivedConversations,
    fetch: fetchArchivedConversations,
    showLoadMoreConversations: showLoadMoreArchivedConversations,
    status: archivedStatus,
  } = useArchivedConversations();
  const loadMoreConversations = useLoadMoreConversations();
  const loadMoreArchivedConversations = () => fetchArchivedConversations(true);
  const { conversations: fullConversationList, showLoadMoreConversations, loading } = useConversations();
  const selectConversation = useConversation();
  const [convoFilter, setConvoFilter] = useState<'all' | 'user' | 'shared' | 'favorite' | 'archived'>('all');

  const [typeAheadTerm, setTypeAheadTerm] = useState<string>('');
  const [search, setSearch] = useState<string>('');
  const [selectedProject, setSelectedProject] = useState<ProjectLabel | null>();

  useEffect(() => {
    if (convoFilter === 'archived' && archivedStatus === CommonStatusEnum.INITIAL) fetchArchivedConversations();
  }, [convoFilter, archivedStatus, fetchArchivedConversations]);

  const conversations = useMemo(() => {
    let convoList: Conversation[] = [];
    switch (convoFilter) {
      case 'archived':
        convoList = archivedConversations;
        break;
      case 'favorite':
        convoList = fullConversationList.filter(c => c.isFavorite);
        break;
      case 'shared':
        convoList = fullConversationList.filter(c => c.owner !== me?.id);
        break;
      case 'user':
        convoList = fullConversationList.filter(c => c.owner == me?.id);
        break;
      default:
        convoList = fullConversationList;
    }

    // Filter by project if one is selected
    if (selectedProject) {
      convoList = convoList.filter(c => c.projectLabels?.some(label => label.id === selectedProject?.id));
    }

    // Apply search filter
    return convoList
      .filter(c => {
        if (c.name?.toLowerCase().includes(search.toLowerCase())) return true;
        if (c.description?.toLowerCase().includes(search.toLowerCase())) return true;
        if (c.messageSet.some(m => m.text?.toLowerCase().includes(search.toLowerCase()))) return true;
        return false;
      })
      .sort((a, b) => parseISO(b.updatedAt).getTime() - parseISO(a.updatedAt).getTime());
  }, [archivedConversations, convoFilter, fullConversationList, me?.id, search, selectedProject]);

  const filteredConversations = useMemo(() => {
    let convoList: Conversation[] = [...fullConversationList, ...archivedConversations];
    if (selectedProject) {
      convoList = convoList.filter(c => c.projectLabels?.some(label => label.id === selectedProject?.id));
    }
    return convoList;
  }, [archivedConversations, fullConversationList, selectedProject]);

  const conversationsPerUser = useMemo(
    () =>
      conversations.reduce(
        (acc, c) => {
          if (c.owner !== me?.id) acc.shared.push(c);
          else acc.recent.push(c);
          return acc;
        },
        { recent: [], shared: [] } as Record<string, Conversation[]>,
      ),
    [conversations, me?.id],
  );

  // Attempt to watch the conversation url params, if not convo is selected we will attempt to select the first from our list.
  useEffect(() => {
    if (!conversationId && conversations.length > 0 && conversationId !== 'new') {
      selectConversation(conversations[0].id);
    }
  }, [conversationId, conversations, selectConversation]);

  const handleSelectConvo = useCallback(
    (convo: Conversation) => {
      if (convo.id != conversation?.id) {
        selectConversation(convo.id);
        // setCleanConversationDataSources(true);
        // goToConversation(convo);
      }
    },
    [selectConversation, conversation?.id],
  );

  const onSelect = useCallback(
    (value: string) => {
      if (typeof value === 'string') setSearch(value);
      else if (typeof value === 'number') selectConversation(value);
    },
    [selectConversation],
  );

  return (
    <Layout.Sider className={styles.sidebar} width="380px">
      <Flex
        gap="10px"
        justify="center"
        style={{
          marginBottom: '10px',
          padding: '0 16px',
        }}
        vertical
      >
        <Flex
          align="center"
          gap={10}
          style={{
            height: '60px',
          }}
        >
          <InputSearch
            fillColor="var(--nri-color-white)"
            filterOption={(searchTerm, option) => option?.meta.name.toLowerCase().includes(searchTerm.toLowerCase())}
            options={filteredConversations.map(conversation => {
              const { id, name, updatedAt } = conversation;
              return {
                id: `conversation-${id}`,
                meta: conversation,
                label: (
                  <InputSearchOption
                    title={name!}
                    searchTerm={typeAheadTerm}
                    subText={formatDate(updatedAt, 'MM/dd/yy')}
                  />
                ),
                value: id,
              };
            })}
            onFilter={setTypeAheadTerm}
            onOptionSelect={onSelect}
          />
          <ProjectSelect onClick={value => setSelectedProject(value)} />
        </Flex>
        <Segmented
          style={{
            // @ts-expect-error css variable definition
            '--ant-control-padding-horizontal': '0',
          }}
          block
          defaultValue="all"
          options={[
            { label: 'All', value: 'all' },
            { label: 'Favorite', value: 'favorite' },
            { label: 'My conversations', value: 'user' },
            { label: 'Shared', value: 'shared' },
            { label: 'Archived', value: 'archived' },
          ]}
          onChange={value => setConvoFilter(value as 'all' | 'user' | 'shared' | 'favorite' | 'archived')}
        />

        <NewConversationButton placeholder="Start new conversation" variant="icon" />
      </Flex>

      {loading || (convoFilter === 'archived' && archivedStatus === CommonStatusEnum.FETCHING) ? (
        Array.from({ length: 6 }).map((_, idx) => (
          <Skeleton active className={styles.skeleton} key={idx} paragraph={{ rows: 1 }} />
        ))
      ) : search && !conversations.length ? (
        <Flex align="center" className={styles.empty} justify="center">
          <Flex align="center" gap="10px" className={styles.iconText}>
            <InboxIcon height="24px" /> No conversations.
          </Flex>
        </Flex>
      ) : (
        <Menu selectedKeys={[`${conversationId}`]} className={classNames(styles.menu)}>
          <AnimatePresence>
            <ul>
              {Object.values(conversationsPerUser)
                .flat()
                .sort((a, b) => parseISO(b.updatedAt).getTime() - parseISO(a.updatedAt).getTime())
                .map((conv: Conversation, index) => (
                  <SidebarItem
                    active={conversationId === `${conv.id}`}
                    conversation={conv}
                    disabled={loading}
                    index={index}
                    key={`convo-${conv.id}-${index}`}
                    onSelect={() => handleSelectConvo(conv)}
                  />
                ))}
            </ul>
            {(showLoadMoreConversations && convoFilter === 'all') ||
            (showLoadMoreArchivedConversations && convoFilter === 'archived') ? (
              <Flex justify="center">
                <LoadMoreButton
                  onClick={convoFilter !== 'archived' ? loadMoreConversations : loadMoreArchivedConversations}
                  loading={loading}
                />
              </Flex>
            ) : null}
          </AnimatePresence>
        </Menu>
      )}
    </Layout.Sider>
  );
};
