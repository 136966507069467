import { Flex } from 'antd';
import { DataSourceList } from 'components/datasource-list/DataSourceList';
import { NewConversationButton } from 'components/new-converation-btn/NewConversationButton';
import { PageSection } from 'components/page-section/PageSection';
import { useState } from 'react';
import styles from './DataSourcesView.module.scss';
const NewConversationDataSources: React.FC<{ selections: DataSource[] }> = ({ selections }) => {
  const placeholder = selections.length
    ? `Start with ${selections.length} connector${selections.length > 1 ? 's' : ''}`
    : 'Start without connectors';

  return <NewConversationButton placeholder={placeholder} params={{ dataSources: selections.map(ds => ds.id) }} />;
};
export const DataSourcesView: React.FC = () => {
  const [selections, setSelections] = useState<DataSource[]>([]);

  return (
    <Flex justify="center" vertical className={styles.dataSources}>
      <PageSection button={<NewConversationDataSources selections={selections} />} heading="Connectors">
        <DataSourceList
          onSelect={setSelections}
          onNewEntityConfirm={ds => {
            setSelections(prev => [...prev, ds]);
          }}
          selected={selections}
        />
      </PageSection>
    </Flex>
  );
};
