import { EntityHeader } from 'components/entity-header/EntityHeader';
import { EntityProvider } from 'components/layouts/context/EntityProvider';
import { useActiveScheduledTask, useUpdateScheduledTask } from 'selectors/useScheduledTasksSelector';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader } from 'components/loader/Loader';
import { EntityDetails } from 'components/entity-details/EntityDetails';
import { ScheduleTaskStep } from 'components/scheduled-tasks-form/step/ScheduleTaskStep';
import { SelectBlueprintStep } from 'components/scheduled-tasks-form/step/SelectBlueprintStep';
import styles from './ScheduledTaskView.module.scss';
export const ScheduledTaskView = ({ editMode = false }: { editMode?: boolean }) => {
  const { task, loading } = useActiveScheduledTask();
  const navigate = useNavigate();
  const updateScheduledTask = useUpdateScheduledTask();

  const steps = [
    {
      title: 'Blueprint',
      key: 'blueprint',
      description: '',
      content: <SelectBlueprintStep />,
    },
    {
      title: 'General',
      key: 'task',
      description: '',
      content: <ScheduleTaskStep />,
      className: styles.taskStep,
    },
  ];

  const onSubmit = useCallback(
    async (values: ScheduledTaskFormData) => {
      if (!task) return;
      await updateScheduledTask(task.id, { ...values, isEnabled: task.isEnabled });
      navigate('/tasks');
    },
    [navigate, updateScheduledTask, task],
  );

  return (
    <>
      {loading ? (
        <Loader fullscreen />
      ) : (
        <EntityProvider<ScheduledTaskFormData>
          steps={steps}
          initialFormValue={{
            name: task?.name as string,
            projectLabels: task?.projectLabels?.map(label => label.id) ?? [],
            timeZone: task?.schedule?.timeZone,
            time: task?.schedule?.time,
            dayOfWeek: task?.schedule?.dayOfWeek,
            dayOfMonth: task?.schedule?.dayOfMonth,
            month: task?.schedule?.month,
            frequencyType: task?.schedule?.frequencyType,
            icebreaker: [task?.icebreaker as Blueprint['id']],
          }}
          isNew={false}
          onSubmit={onSubmit}
          readOnly={editMode === false ? true : false}
        >
          <EntityHeader isEditable={false} canGoBack actions={[]} title={task?.name as string} />
          <EntityDetails<ScheduledTaskFormData>
            detailsFormClassName={styles.detailsForm}
            isEditable={editMode}
            entityName="Scheduled Task"
          />
        </EntityProvider>
      )}
    </>
  );
};
