import { Actions } from 'types/actionTypes';
import { CommonStatusEnum, ScheduledTasksFilterTypesEnum } from 'types/enum';

type SheduledTaskScope = {
  tasks: Map<ScheduledTaskId, ScheduledTask>;
  pagination: PaginationType;
  status: CommonStatusEnum;
};

export interface ScheduledTasksState {
  scope: {
    [ScheduledTasksFilterTypesEnum.ALL]: SheduledTaskScope;
    [ScheduledTasksFilterTypesEnum.OWNED]: SheduledTaskScope;
    [ScheduledTasksFilterTypesEnum.SHARED]: SheduledTaskScope;
    [ScheduledTasksFilterTypesEnum.ARCHIVED]: SheduledTaskScope;
  };
  timeZones: { pagination: PaginationType; status: CommonStatusEnum; results: Map<TimeZone['id'], TimeZone> };
  activeTask: { data: ScheduledTask | undefined; status: CommonStatusEnum };
  status: CommonStatusEnum;
}
export const initialScheduledTasksState: ScheduledTasksState = {
  scope: {
    [ScheduledTasksFilterTypesEnum.ALL]: {
      tasks: new Map(),
      pagination: {
        next: null,
        previous: null,
        count: 0,
      },
      status: CommonStatusEnum.INITIAL,
    },
    [ScheduledTasksFilterTypesEnum.OWNED]: {
      tasks: new Map(),
      pagination: {
        next: null,
        previous: null,
        count: 0,
      },
      status: CommonStatusEnum.INITIAL,
    },
    [ScheduledTasksFilterTypesEnum.SHARED]: {
      tasks: new Map(),
      pagination: {
        next: null,
        previous: null,
        count: 0,
      },
      status: CommonStatusEnum.INITIAL,
    },
    [ScheduledTasksFilterTypesEnum.ARCHIVED]: {
      tasks: new Map(),
      pagination: {
        next: null,
        previous: null,
        count: 0,
      },
      status: CommonStatusEnum.INITIAL,
    },
  },
  timeZones: {
    pagination: {
      next: null,
      previous: null,
      count: 0,
    },
    status: CommonStatusEnum.INITIAL,
    results: new Map<TimeZone['id'], TimeZone>(),
  },
  activeTask: { data: undefined, status: CommonStatusEnum.INITIAL },
  status: CommonStatusEnum.INITIAL,
};

export enum ScheduledTasksActionTypes {
  FETCHING_SCHEDULED_TASKS = 'FETCHING_SCHEDULED_TASKS',
  FETCH_SCHEDULED_TASKS = 'FETCH_SCHEDULED_TASKS',
  CREATE_SCHEDULED_TASKS = 'CREATE_SCHEDULED_TASKS',
  UPDATE_SCHEDULED_TASKS = 'UPDATE_SCHEDULED_TASKS',
  CLEAN_ALL_SCHEDULED_TASKS = 'CLEAN_ALL_SCHEDULED_TASKS',
  UPDATE_SCHEDULED_TASK = 'UPDATE_SCHEDULED_TASK',
  DELETE_SCHEDULED_TASK = 'DELETE_SCHEDULED_TASK',
  FETCHING_TIME_ZONES = 'FETCHING_TIME_ZONES',
  FETCH_TIME_ZONES = 'FETCH_TIME_ZONES',
  FETCHING_ACTIVE_TASK = 'FETCHING_ACTIVE_TASK',
  FETCH_ACTIVE_TASK = 'FETCH_ACTIVE_TASK',
}

export type ScheduledTasksActions =
  | { type: ScheduledTasksActionTypes.FETCHING_SCHEDULED_TASKS; payload: ScheduledTasksFilterTypesEnum }
  | {
      type: ScheduledTasksActionTypes.FETCH_SCHEDULED_TASKS;
      payload: {
        tasks: ScheduledTask[];
        pagination: PaginationType;
        scheduledTasksFilter: ScheduledTasksFilterTypesEnum;
      };
    }
  | { type: ScheduledTasksActionTypes.CREATE_SCHEDULED_TASKS; payload: ScheduledTask }
  | { type: ScheduledTasksActionTypes.UPDATE_SCHEDULED_TASKS; payload: ScheduledTask }
  | { type: ScheduledTasksActionTypes.CLEAN_ALL_SCHEDULED_TASKS }
  | {
      type: ScheduledTasksActionTypes.UPDATE_SCHEDULED_TASK;
      payload: { data: Partial<ScheduledTask>; id: ScheduledTaskId };
    }
  | { type: ScheduledTasksActionTypes.DELETE_SCHEDULED_TASK; payload: ScheduledTaskId }
  | { type: ScheduledTasksActionTypes.FETCHING_TIME_ZONES }
  | {
      type: ScheduledTasksActionTypes.FETCH_TIME_ZONES;
      payload: { timeZones: TimeZone[]; pagination: PaginationType };
    }
  | { type: ScheduledTasksActionTypes.FETCHING_ACTIVE_TASK }
  | { type: ScheduledTasksActionTypes.FETCH_ACTIVE_TASK; payload: ScheduledTask };

export const scheduledTasksReducer = (state: ScheduledTasksState, action: Actions): ScheduledTasksState => {
  switch (action.type) {
    case ScheduledTasksActionTypes.FETCHING_SCHEDULED_TASKS:
      return {
        ...state,
        status: CommonStatusEnum.FETCHING,
        scope: {
          ...state.scope,
          [action.payload]: {
            ...state.scope[action.payload],
            tasks: new Map(),
            pagination: {
              next: null,
              previous: null,
              count: 0,
            },
            status: CommonStatusEnum.FETCHING,
          },
        },
      };
    case ScheduledTasksActionTypes.FETCH_SCHEDULED_TASKS:
      return {
        ...state,
        status: CommonStatusEnum.FETCHED,
        scope: {
          ...state.scope,
          [action.payload.scheduledTasksFilter]: {
            tasks: new Map(action.payload.tasks.map((task: ScheduledTask) => [task.id, task])),
            pagination: action.payload.pagination,
            status: CommonStatusEnum.FETCHED,
          },
        },
      };
    case ScheduledTasksActionTypes.CLEAN_ALL_SCHEDULED_TASKS:
      return { ...state, status: CommonStatusEnum.INITIAL };
    case ScheduledTasksActionTypes.UPDATE_SCHEDULED_TASK: {
      const updatedTaskId = action.payload.id;
      const taskData = action.payload.data;
      const updatedScope = { ...state.scope };

      Object.values(ScheduledTasksFilterTypesEnum).forEach(filter => {
        if (updatedScope[filter].tasks.has(updatedTaskId)) {
          const existingTask = updatedScope[filter].tasks.get(updatedTaskId)!;
          updatedScope[filter].tasks.set(updatedTaskId, {
            ...existingTask,
            ...taskData,
            id: updatedTaskId,
          });
        }
      });

      return {
        ...state,
        activeTask: {
          ...state.activeTask,
          data: { ...state.activeTask.data, ...action.payload.data } as ScheduledTask,
        },
        scope: updatedScope,
      };
    }
    case ScheduledTasksActionTypes.DELETE_SCHEDULED_TASK: {
      const deletedTaskId = action.payload;
      const newScope = { ...state.scope };
      Object.values(ScheduledTasksFilterTypesEnum).forEach(filter => {
        newScope[filter].tasks.delete(deletedTaskId);
      });

      return { ...state };
    }
    case ScheduledTasksActionTypes.FETCHING_TIME_ZONES:
      return { ...state, timeZones: { ...state.timeZones, status: CommonStatusEnum.FETCHING } };
    case ScheduledTasksActionTypes.FETCH_TIME_ZONES: {
      const newTimeZones = new Map(action.payload.timeZones.map((timeZone: TimeZone) => [timeZone.id, timeZone]));
      return {
        ...state,
        timeZones: {
          pagination: action.payload.pagination,
          status: CommonStatusEnum.FETCHED,
          results: new Map([...state.timeZones.results, ...newTimeZones]),
        },
      };
    }
    case ScheduledTasksActionTypes.FETCHING_ACTIVE_TASK:
      return { ...state, activeTask: { ...state.activeTask, status: CommonStatusEnum.FETCHING } };
    case ScheduledTasksActionTypes.FETCH_ACTIVE_TASK:
      return { ...state, activeTask: { ...state.activeTask, data: action.payload, status: CommonStatusEnum.FETCHED } };

    default:
      return state;
  }
};
