import { EntityDetails } from 'components/entity-details/EntityDetails';
import { EntityProvider } from 'components/layouts/context/EntityProvider';
import { SelectBlueprintStep } from 'components/scheduled-tasks-form/step/SelectBlueprintStep';
import { ModalScopesEnum, ModalTypesEnum } from 'reducers/modalsReducer';
import { useModal } from 'selectors/useModals';
import styles from './AddScheduledTaskModal.module.scss';
import { Modal } from 'ui/Modal';
import { ScheduleTaskStep } from 'components/scheduled-tasks-form/step/ScheduleTaskStep';
import { useCallback } from 'react';
import { useCreateScheduledTask, useUpdateScheduledTask } from 'selectors/useScheduledTasksSelector';
import { ScheduledTasksFilterTypesEnum } from 'types/enum';

export const ManageScheduledTaskModal = () => {
  const { data, hide } = useModal<{ page?: number; filter?: ScheduledTasksFilterTypesEnum; task?: ScheduledTask }>(
    ModalScopesEnum.SCHEDULED_TASKS,
    ModalTypesEnum.MANAGE,
  );
  const createScheduledTask = useCreateScheduledTask();
  const updateScheduledTask = useUpdateScheduledTask();

  const steps = [
    {
      title: 'Select blueprint',
      key: 'blueprints',
      description:
        'Select a blueprint to start with the scheduled task.  Only blueprints that do not require user inputs are available for scheduling.',
      content: <SelectBlueprintStep />,
      className: styles.blueprintsStep,
    },
    {
      title: 'Schedule task',
      key: 'task',
      description: 'Enter details to schedule your task',
      content: <ScheduleTaskStep />,
      className: styles.taskStep,
    },
  ];

  const onSubmit = useCallback(
    async (values: ScheduledTaskFormData) => {
      if (data?.task) {
        await updateScheduledTask(data?.task.id, { ...values, isEnabled: data?.task.isEnabled });
      } else {
        await createScheduledTask(values, data.page!, data.filter!);
      }
      hide();
    },
    [data, createScheduledTask, updateScheduledTask],
  );

  return (
    <Modal
      footer={false}
      styles={{
        footer: { display: 'none' },
      }}
      width={'fit-content'}
      centered
      onCancel={() => hide()}
      open={true}
      title={''}
    >
      <EntityProvider<ScheduledTaskFormData>
        steps={steps}
        isNew={!data?.task}
        initialFormValue={{
          icebreaker: [data?.task?.icebreaker as Blueprint['id']],
          projectLabels: data?.task?.projectLabels?.map(label => label.id) ?? [],
          name: data?.task?.name ?? '',
          frequencyType: data?.task?.schedule?.frequencyType ?? undefined,
          timeZone: data?.task?.schedule?.timeZone ?? undefined,
          time: data?.task?.schedule?.time ?? undefined,
          dayOfWeek: data?.task?.schedule?.dayOfWeek ?? undefined,
          dayOfMonth: data?.task?.schedule?.dayOfMonth ?? undefined,
          month: data?.task?.schedule?.month ?? undefined,
        }}
        onSubmit={onSubmit}
      >
        <EntityDetails<ScheduledTaskFormData>
          className={styles.taskDetails}
          detailsFormClassName={styles.detailsForm}
          entityName="Scheduled Task"
          onCancel={() => hide()}
          showSegmented={false}
        />
      </EntityProvider>
    </Modal>
  );
};
