import { ModalScopesEnum, ModalTypesEnum } from 'reducers/modalsReducer';
import { useModal } from 'selectors/useModals';

import { useSharedEntities } from 'selectors/useSharedEntitiesSelector';
import { useCallback, useMemo, useState } from 'react';
import { useManageAccessToDataSource } from 'selectors/useDataSourcesSelector';
import { EntityPermissionsEnum } from 'types/enum';

import { Modal } from 'ui/Modal';
import { Button } from 'ui/Button';
import { Form } from 'antd';
import { EntityShareForm } from 'components/entity-share-form/EntityShareForm';
import { DatasourceSelectedItem } from 'components/datasource-shared-item/DatasourceSelectedItem';

export const ManageDataSourceAccessModal: React.FC = () => {
  const { data: dataSource, hide } = useModal<{ data: DataSource; permissions: ShareableEntity[] }>(
    ModalScopesEnum.DATA_SOURCES,
    ModalTypesEnum.MANAGE,
  );
  const [form] = Form.useForm();

  const manageAccess = useManageAccessToDataSource();
  const { me, sharedEntities } = useSharedEntities();
  const ownerId = dataSource!.data!.owner;

  const owner = useMemo(
    () => dataSource.permissions.find(e => (e.type === 'user' && e.id === ownerId) || me),
    [ownerId, dataSource.permissions],
  );

  const [isLoading, setIsLoading] = useState(false);

  const onConfirm = useCallback(async () => {
    setIsLoading(true);
    const { shareWith, sharedUsers } = form.getFieldsValue(true);
    const payload =
      shareWith === 'users'
        ? {
            users: sharedUsers!.map(({ id, permission }: UserEntity) => ({ id, permission })),
          }
        : { organization: { id: me!.organization, permission: EntityPermissionsEnum.READ } };

    try {
      await manageAccess(dataSource!.data.id, payload, ownerId);
      setIsLoading(false);
      hide();
    } catch (error) {
      setIsLoading(false);
    }
  }, [setIsLoading, dataSource, me]);

  return (
    <Modal
      open={true}
      centered
      styles={{
        content: { height: '572px' },
        body: { height: '100%' },
        header: { margin: '0' },
        footer: { margin: '0' },
      }}
      onCancel={hide}
      title="Manage Access"
      description="Here you can manage access to connector"
      destroyOnClose
      divider={true}
      footer={
        <>
          <Button onClick={hide} bordered>
            Cancel
          </Button>
          <Button loading={isLoading} onClick={onConfirm} type="primary">
            Save
          </Button>
        </>
      }
    >
      <EntityShareForm
        entityName="connector"
        form={form}
        sharedEntities={sharedEntities}
        sharedPermissions={dataSource!.permissions}
        owner={owner!}
        components={{ SelectedItem: DatasourceSelectedItem }}
      />
    </Modal>
  );
};
