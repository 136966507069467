import { Tabs } from 'antd';
import { DashboardSection } from 'components/dashboard-section/DashboardSection';
import { TimePeriodItem } from 'components/usage-period-dropdown/UsagePeriodDropdown';
import { SegmentedItemLabel } from '../../newVersionComponents/common/SegmentedItemLabel';
import { useState } from 'react';
import { Segmented } from 'ui/Segmented';
import styles from './UsageDetails.module.scss';
import { UsageDetailsList } from './UsageDetailsList';
import { useCreditDetails } from 'selectors/useStatisticsSelector';
import { UsageEntityNameEnum } from 'types/enum';

export const UsageDetails = ({ selectedTimePeriod }: { selectedTimePeriod: TimePeriodItem }) => {
  const [activeTab, setActiveTab] = useState(UsageEntityNameEnum.CONVERSATION);
  const {
    loading: detailsLoading,
    data,
    totalExpendedCreditsPercentageOverOrg,
  } = useCreditDetails(selectedTimePeriod, activeTab);

  const accountItems: {
    hide: boolean | undefined;
    label: string;
    key: string;
    children: JSX.Element;
  }[] = [
    {
      hide: false,
      label: 'My Conversations',
      key: UsageEntityNameEnum.CONVERSATION,
      children: (
        <UsageDetailsList
          entityName={activeTab}
          pieData={data}
          piePercentage={totalExpendedCreditsPercentageOverOrg || '0'}
          loading={detailsLoading}
          creditInfo={data}
        />
      ),
    },
    {
      hide: false,
      label: 'By Project',
      key: UsageEntityNameEnum.PROJECT,
      children: (
        <UsageDetailsList
          entityName={activeTab}
          creditInfo={data}
          pieData={data.filter(item => item.totalTokensCreditPercentage! > 0)}
          loading={detailsLoading}
        />
      ),
    },
    {
      hide: false,
      label: 'By User',
      key: UsageEntityNameEnum.USER,
      children: (
        <UsageDetailsList
          entityName={activeTab}
          creditInfo={data}
          pieData={data.filter(item => item.totalTokensCreditPercentage! > 0)}
          loading={detailsLoading}
        />
      ),
    },
  ].filter(({ hide }) => !hide);

  return (
    <DashboardSection title="Details" vertical>
      <Tabs
        items={accountItems}
        activeKey={activeTab}
        renderTabBar={() => {
          return (
            <div>
              <Segmented
                className={styles.detailsSegmented}
                options={accountItems.map(({ label, key }) => ({
                  key: key,
                  label: (
                    <SegmentedItemLabel
                      label={label}
                      active={activeTab === key}
                      handleClick={() => {
                        setActiveTab(key as UsageEntityNameEnum);
                      }}
                    />
                  ),
                  value: key,
                }))}
              />
            </div>
          );
        }}
      />
    </DashboardSection>
  );
};
