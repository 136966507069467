import { Flex, Typography } from 'antd';
import styles from './UsageRemainingCredits.module.scss';
import { useCreditsInfo } from 'selectors/useStatisticsSelector';
import { DashboardSection } from 'components/dashboard-section/DashboardSection';
import { TimePeriodItem } from 'components/usage-period-dropdown/UsagePeriodDropdown';
import { formatNumberWithSpace } from 'utils/FormatWithSpace';

const DataBox = ({ data, description }: { data: string; description: string }) => {
  const displayedNumber = data === '-' ? 0 : data;
  return (
    <Flex gap={12} align="flex-end" className={styles.dataContainer}>
      <Typography.Text className={styles.data}>{displayedNumber}</Typography.Text>
      <Typography.Text className={styles.dataDescription}>{description}</Typography.Text>
    </Flex>
  );
};
export const UsageRemainingCredits = ({ selectedTimePeriod }: { selectedTimePeriod: TimePeriodItem }) => {
  const { loading, totalTokens } = useCreditsInfo(selectedTimePeriod);

  return (
    <DashboardSection
      title="Organization wide usage"
      description="Total number of credits used across the organization"
      loading={loading}
      vertical
    >
      <DataBox data={formatNumberWithSpace(totalTokens)} description="Output credits used" />
    </DashboardSection>
  );
};
