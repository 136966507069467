import { Layout, message } from 'antd';
import styles from './Layout.module.scss';
import { memo, useCallback, useEffect } from 'react';
import { Loader } from 'components/loader/Loader';
import { AgentView } from 'components/agent-view/AgentView';
import { useActiveAgent, useUpdateAgent } from 'selectors/useAgents';
import { useAppContext } from 'contexts/AppProviders';
import { useNavigate, useParams } from 'react-router-dom';
import { ConversationsActionTypes } from 'reducers/conversationsReducer';
import {
  useToggleArchiveConversation,
  useConversation,
  useCreateConversation,
  useDeleteConversation,
} from 'selectors/useConversationSelectors';

export const AgentPage = memo(({ edit = false }: { edit: boolean }) => {
  const { dispatch } = useAppContext();
  const archiveConversation = useToggleArchiveConversation(undefined, { surpressMessage: true });
  const selectConversation = useConversation();
  const createConversation = useCreateConversation();
  const deleteConversation = useDeleteConversation(undefined, { skipNavigate: true, surpressMessage: true });
  const navigate = useNavigate();

  const { conversationId } = useParams();
  const updateAgent = useUpdateAgent();
  const agent = useActiveAgent();

  const createTempConversation = useCallback(async () => {
    const conversation = await createConversation(
      {
        name: 'New Conversation',
        description: 'New Conversation',
      },
      true,
      true,
    );
    await archiveConversation(conversation.id);
    navigate(`/agents/${agent?.id}/conversations/${conversation.id}`, { replace: true });
  }, [createConversation, archiveConversation, navigate, agent]);

  const onSubmit = useCallback(
    async (values: UpdateAgent) => {
      message.success('Agent updated successfully');
      updateAgent({
        ...values,
        id: agent?.id,
      });

      deleteConversation(Number(conversationId) as ConversationId);
      await createTempConversation();
    },
    [updateAgent, agent, deleteConversation, conversationId, createTempConversation],
  );

  useEffect(() => {
    if (conversationId) return;
    (async () => {
      await createTempConversation();
    })();

    return () => {
      if (conversationId) {
        deleteConversation(Number(conversationId) as ConversationId);
      }
    };
  }, [agent, createTempConversation, conversationId, deleteConversation]);

  useEffect(() => {
    if (!conversationId) return;
    (async () => {
      await selectConversation(Number(conversationId) as ConversationId, { skipNavigate: true });
      dispatch({
        type: ConversationsActionTypes.SAVE_PROMPT,
        payload: {
          conversationId: Number(conversationId) as ConversationId,
          data: {
            analyst: agent,
            prompt: { text: '' },
          },
        },
      });
    })();
  }, [conversationId, agent, dispatch, selectConversation]);

  return (
    <Layout className={styles.agentPage}>
      {agent ? <AgentView agent={agent} edit={edit} onSubmit={onSubmit} isNew={false} /> : <Loader fullscreen />}
    </Layout>
  );
});
