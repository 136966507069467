import { useAppContext } from 'contexts/AppProviders';
import { StatisticsActionTypes } from './../reducers/statisticsReducer';
import { useEffect, useMemo } from 'react';
import { CommonStatusEnum, TimePeriodOptionsEnum, UsageEntityNameEnum } from 'types/enum';
import { NewtonApi } from 'utils/newtonApi';
import camelCase from 'camelcase';

export const useTimePeriodsState = () => {
  const {
    state: { statisticsState },
    dispatch,
  } = useAppContext();

  const { status } = statisticsState.timePeriods;

  useEffect(() => {
    if (status === CommonStatusEnum.INITIAL) {
      (async () => {
        dispatch({
          type: StatisticsActionTypes.FETCHING_TIME_PERIODS,
        });
        const response = await NewtonApi.fetchTimePeriods();
        const timePeriods = response.map((item: { value: string; label: string }, i: number) => ({
          id: i.toString(),
          ...item,
        }));

        dispatch({
          type: StatisticsActionTypes.FETCH_TIME_PERIODS,
          payload: timePeriods,
        });
      })();
    }
  }, [dispatch, status]);

  return { state: statisticsState };
};

export const useMonthlyCreditsState = (params: { [key: string]: string | string[] }) => {
  const {
    state: { statisticsState },
    dispatch,
  } = useAppContext();
  const { status } = statisticsState.monthlyCredits;

  useEffect(() => {
    if (status === CommonStatusEnum.INITIAL) {
      (async () => {
        dispatch({
          type: StatisticsActionTypes.FETCHING_MONTHLY_CREDITS,
        });

        const response = await NewtonApi.fetchUsageReport(params);
        const creditsInfo = {
          totalTokensCredit: response[0].totalTokensCreditSum,
          availableCredits: 0,
          totalCredits: 0,
          availableTokensPercentage: 0,
        };
        dispatch({
          type: StatisticsActionTypes.FETCH_MONTHLY_CREDITS,
          payload: creditsInfo,
        });
      })();
    }
  }, [dispatch, status]);

  return { state: statisticsState };
};

export const useCreditsInfoState = (params: { [key: string]: string | string[] }) => {
  const {
    state: { statisticsState },
    dispatch,
  } = useAppContext();
  const { status } = statisticsState.creditsInfo;

  useEffect(() => {
    if (status === CommonStatusEnum.INITIAL) {
      (async () => {
        dispatch({
          type: StatisticsActionTypes.FETCHING_CREDITS_INFO,
        });

        const response = await NewtonApi.fetchUsageReport(params);
        const creditsInfo = {
          totalTokensCredit: response[0].totalTokensCreditSum,
          availableCredits: 0,
          totalCredits: 0,
          availableTokensPercentage: 0,
        };
        dispatch({
          type: StatisticsActionTypes.FETCH_CREDITS_INFO,
          payload: creditsInfo,
        });
      })();
    }
    return () => {
      if (status === CommonStatusEnum.FETCHED) {
        dispatch({
          type: StatisticsActionTypes.CLEAN_CREDITS_INFO,
        });
      }
    };
  }, [dispatch, status, params]);

  return { state: statisticsState };
};

const useUsageInfoState = (params: { [key: string]: string | string[] }) => {
  const {
    state: { statisticsState },
    dispatch,
  } = useAppContext();
  const { status } = statisticsState.dailyCredits;

  useEffect(() => {
    if (status === CommonStatusEnum.INITIAL) {
      (async () => {
        dispatch({
          type: StatisticsActionTypes.FETCHING_DAILY_CREDITS,
        });
        const response = await NewtonApi.fetchUsageReport(params);
        const creditsByDay = response.map(
          (
            { date, week, totalTokensCreditSum }: { date?: string; week?: string; totalTokensCreditSum: number },
            index: number,
          ) => ({
            id: index.toString(),
            ...(date && { date }),
            ...(week && { week }),
            totalTokensCredit: totalTokensCreditSum,
          }),
        );

        dispatch({
          type: StatisticsActionTypes.FETCH_DAILY_CREDITS,
          payload: creditsByDay,
        });
      })();
    }

    return () => {
      if (status === CommonStatusEnum.FETCHED)
        dispatch({
          type: StatisticsActionTypes.CLEAN_DAILY_CREDITS,
        });
    };
  }, [dispatch, status, params]);

  return { state: statisticsState };
};
const useUsageDetailsState = (params: { [key: string]: string | string[] }, entityName: string) => {
  const {
    state: { statisticsState },
    dispatch,
  } = useAppContext();

  const { status } = statisticsState.creditDetails;

  useEffect(() => {
    if (status === CommonStatusEnum.INITIAL) {
      (async () => {
        dispatch({
          type: StatisticsActionTypes.FETCHING_CREDIT_DETAILS,
        });
        const camelCaseName = camelCase(entityName);
        const response = await NewtonApi.fetchUsageReport(params);
        const creditDetail = response.map(
          (item: { [key: string]: number; totalTokensCreditSum: number; totalTokensCreditPercentageSum: number }) => ({
            id: item[`${camelCaseName}Id`],
            entityName: item[`${camelCaseName}Name`],
            totalTokensCredit: item.totalTokensCreditSum,
            totalTokensCreditPercentage: item.totalTokensCreditPercentageSum,
            ...(entityName === UsageEntityNameEnum.CONVERSATION && {
              isDeleted: item[`${camelCaseName}IsDeleted`],
              totalTokensCreditPercentageOverOrg: item.totalTokensCreditPercentageOverOrgSum,
            }),
            ...(entityName === UsageEntityNameEnum.PROJECT && {
              color: item[`${camelCaseName}Color`],
            }),
          }),
        );

        dispatch({
          type: StatisticsActionTypes.FETCH_CREDIT_DETAILS,
          payload: creditDetail,
        });
      })();
    }

    return () => {
      if (status === CommonStatusEnum.FETCHED)
        dispatch({
          type: StatisticsActionTypes.CLEAN_CREDIT_DETAILS,
        });
    };
  }, [dispatch, status, params]);

  return { state: statisticsState };
};

const useConversationsUsageState = (params: { [key: string]: string | string[] }) => {
  const {
    state: { statisticsState },
    dispatch,
  } = useAppContext();

  const { status } = statisticsState.conversationsUsage;

  useEffect(() => {
    if (status === CommonStatusEnum.INITIAL) {
      (async () => {
        dispatch({
          type: StatisticsActionTypes.FETCHING_CONVERSATIONS_USAGE,
        });
        const response = await NewtonApi.fetchUsageReport(params);

        const conversationsUsage = response.map(
          (item: { id: string; isDeleted: boolean; totalTokensCreditPercentageSum: number }, i: number) => ({
            id: i.toString(),
            isDeleted: item.isDeleted,
            totalTokensCreditPercentage: item.totalTokensCreditPercentageSum,
          }),
        );
        dispatch({
          type: StatisticsActionTypes.FETCH_CONVERSATIONS_USAGE,
          payload: conversationsUsage,
        });
      })();
    }

    return () => {
      if (status === CommonStatusEnum.FETCHED)
        dispatch({
          type: StatisticsActionTypes.CLEAN_CONVERSATIONS_USAGE,
        });
    };
  }, [dispatch, status, params]);

  return { state: statisticsState };
};

export const useTimePeriods = () => {
  const {
    state: { timePeriods },
  } = useTimePeriodsState();
  const { status, data } = timePeriods;

  return {
    loading: status === CommonStatusEnum.FETCHING || status === CommonStatusEnum.INITIAL,
    data: Array.from(data.values()).filter(e => e.value !== 'custom_'),
  };
};

export const useMonthlyCredits = () => {
  const params = useMemo(
    () => ({
      include_deleted_conversations: 'true',
      fields: ['total_tokens_credit'],
    }),
    [],
  );
  const {
    state: { monthlyCredits },
  } = useMonthlyCreditsState(params);
  const { status, data } = monthlyCredits;

  return {
    loading: status === CommonStatusEnum.FETCHING || status === CommonStatusEnum.INITIAL,
    availableCredits: data.availableCredits,
    totalCredits: data.totalCredits,
    totalTokens: data.totalTokensCredit,
  };
};

export const useDailyCredits = (
  timePeriodItem: { key: TimePeriodOptionsEnum; label: string },
  personalUsage: boolean,
  timeGroupBy: string,
) => {
  const params = useMemo(
    () => ({
      time_period: timePeriodItem.key,
      group_by: timeGroupBy,
      include_deleted_conversations: 'true',
      level: personalUsage ? 'user' : 'organization',
      fields: ['total_tokens_credit', timeGroupBy],
    }),
    [timePeriodItem, personalUsage],
  );

  const {
    state: { dailyCredits },
  } = useUsageInfoState(params);

  const { status, data } = dailyCredits;

  return {
    loading: status === CommonStatusEnum.FETCHING || status === CommonStatusEnum.INITIAL,
    data: Array.from(data.values()),
  };
};

export const useCreditDetails = (
  timePeriod: { key: TimePeriodOptionsEnum; label: string },
  entityName: UsageEntityNameEnum,
) => {
  const baseFields = ['total_tokens_credit', 'total_tokens_credit_percentage', `${entityName}_name`];
  const conversationFields =
    entityName === UsageEntityNameEnum.CONVERSATION ? ['is_deleted', 'total_tokens_credit_percentage_over_org'] : [];
  const projectFields = entityName === UsageEntityNameEnum.PROJECT ? ['project_label_color'] : [];
  const params = useMemo(
    () => ({
      time_period: timePeriod.key,
      group_by: `${entityName}_id`,
      include_deleted_conversations: 'true',
      fields: [...baseFields, ...conversationFields, ...projectFields],
      ...(entityName === UsageEntityNameEnum.CONVERSATION && { level: 'user' }),
    }),
    [timePeriod, entityName],
  );

  const {
    state: { creditDetails },
  } = useUsageDetailsState(params, entityName);

  const { status, data } = creditDetails;

  const totalExpendedPercentageOverOrg = useMemo(() => {
    return Number(
      Array.from(data.values())
        .reduce((sum, item) => sum + item.totalTokensCreditPercentageOverOrg!, 0)
        .toFixed(2),
    );
  }, [data]);

  return {
    loading: status === CommonStatusEnum.INITIAL || status === CommonStatusEnum.FETCHING,
    data: Array.from(data.values()).map(e => ({
      ...e,
      totalTokensCreditPercentage: Number(e.totalTokensCreditPercentage?.toFixed(2)),
    })),
    totalExpendedCreditsPercentageOverOrg: Number(totalExpendedPercentageOverOrg),
  };
};
export const useCreditsInfo = (timePeriod?: { key: TimePeriodOptionsEnum; label: string }) => {
  const params = useMemo(
    () => ({
      ...(timePeriod && { time_period: timePeriod?.key }),
      include_deleted_conversations: 'true',
      fields: ['total_tokens_credit'],
    }),
    [timePeriod],
  );
  const {
    state: { creditsInfo },
  } = useCreditsInfoState(params);
  const { status, data } = creditsInfo;

  return {
    loading: status === CommonStatusEnum.FETCHING || status === CommonStatusEnum.INITIAL,
    availableCredits: data.availableCredits,
    totalCredits: data.totalCredits,
    totalTokens: data.totalTokensCredit,
  };
};

export const useConversationsUsagePercentage = (timePeriod: { key: TimePeriodOptionsEnum; label: string }) => {
  const params = useMemo(
    () => ({
      fields: ['total_tokens_credit_percentage'],
      group_by: 'is_deleted',
      time_period: timePeriod.key,
    }),
    [timePeriod],
  );
  const {
    state: { conversationsUsage },
  } = useConversationsUsageState(params);

  const { status, data } = conversationsUsage;

  const conversationsUsageData = useMemo(
    () =>
      Array.from(data.values()).map(e => ({
        ...e,
        totalTokensCreditPercentage: Math.round(e.totalTokensCreditPercentage),
      })),
    [data],
  );

  return {
    loading: status === CommonStatusEnum.FETCHING || status === CommonStatusEnum.INITIAL,
    inProgress: conversationsUsageData[0],
    deleted: conversationsUsageData[1],
  };
};
