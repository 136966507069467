import { ResponsiveBar, BarDatum } from '@nivo/bar';
import styles from './UsageDailyCredits.module.scss';
import { useCallback, useMemo, useState } from 'react';
import { useDailyCredits } from 'selectors/useStatisticsSelector';
import { TimePeriodItem } from 'components/usage-period-dropdown/UsagePeriodDropdown';
import { DashboardSection } from 'components/dashboard-section/DashboardSection';
import { UsageLevelSwitch } from 'components/usage-level-switch/UsageLevelSwitch';
import { customDate } from 'utils/formatDate';
import { ChartTooltip, ChartTooltipData } from 'components/usage-chart-tooltip/ChartTooltip';

const CHART_THEME = {
  labels: {
    text: {
      fill: 'var(--nri-color-white)',
      fontSize: 'var(--nri-font-size-xl)',
      fontWeight: 'var(--nri-weight-medium)',
      fontFamily: 'var(--nri-font-family)',
    },
  },
} as const;

const CHART_MARGIN = { top: 50, right: 90, bottom: 50, left: 90 } as const;

export const UsageDailyCredits = ({ selectedTimePeriod }: { selectedTimePeriod: TimePeriodItem }) => {
  const timeGroupBy = useMemo(() => {
    if (['this_month', 'last_month', 'this_week'].includes(selectedTimePeriod.key)) {
      return 'date';
    } else {
      return 'week';
    }
  }, [selectedTimePeriod]);

  const [personalUsage, setPersonalUsage] = useState<boolean>(false);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const { loading, data } = useDailyCredits(selectedTimePeriod, personalUsage, timeGroupBy);
  const maxValue = useMemo(() => {
    const max = Math.max(...data.map(item => item.totalTokensCredit!));
    return max === 0 ? 1000 : max;
  }, [data]);
  const isVertical = useMemo(() => data.length > 15, [data]);

  const formatAxisValue = useCallback((value: number) => {
    if (value >= 1000) return `${(value / 1000).toFixed(1)}k`;
    return value.toString();
  }, []);

  const handleBarColor = useCallback(
    ({ index }: { index: number }) =>
      index === hoveredIndex ? 'var(--nri-color-primary)' : 'var(--nri-color-lavander)',
    [hoveredIndex],
  );

  return (
    <DashboardSection
      loadingClassName={styles.loader}
      loading={loading}
      vertical
      bordered
      title={`${timeGroupBy === 'date' ? 'Daily' : 'Weekly'} credit usage`}
      description={`Output credit usage by ${timeGroupBy === 'date' ? 'day' : 'week'}`}
      rightSide={<UsageLevelSwitch checked={personalUsage} onChange={setPersonalUsage} />}
    >
      <div className={styles.chart}>
        <ResponsiveBar<DailyCreditData>
          data={data}
          indexBy={timeGroupBy}
          theme={CHART_THEME}
          padding={0.6}
          keys={['totalTokensCredit']}
          margin={CHART_MARGIN}
          colors={handleBarColor}
          enableLabel={false}
          valueScale={{
            type: 'linear',
            min: 0,
            max: maxValue,
          }}
          axisLeft={{
            tickValues: 5,
            tickSize: 5,
            format: formatAxisValue,
          }}
          axisBottom={{
            tickRotation: isVertical ? 90 : 0,
            format: value => {
              return value ? customDate(value) : '';
            },
          }}
          gridYValues={5}
          valueFormat="<-0.1~%"
          borderRadius={4}
          layout="vertical"
          animate={false}
          tooltip={({ data: { date, week, totalTokensCredit } }: { data: BarDatum }) => {
            const chartData = { id: customDate((date as string) ?? (week as string)), credits: totalTokensCredit };
            return <ChartTooltip data={chartData as ChartTooltipData} />;
          }}
          onMouseEnter={({ index }) => {
            setHoveredIndex(index);
          }}
          onMouseLeave={() => {
            setHoveredIndex(null);
          }}
        />
      </div>
    </DashboardSection>
  );
};
