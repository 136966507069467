import { Form, Layout } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useActiveDataSource,
  useGetFile,
  useMarkAsFavorite,
  useUpdateDataSourceSelector,
} from 'selectors/useDataSourcesSelector';
import styles from './DatasourceView.module.scss';
import { EntityHeader, EntityHeaderAction } from 'components/entity-header/EntityHeader';
import DeleteIcon from '../../assets/iconsNewVersion/trash.svg?react';
import ArchiveIcon from '../../assets/iconsNewVersion/archive.svg?react';
import DownloadIcon from '../../assets/iconsNewVersion/download.svg?react';
import { DatasourceTypesEnum, EntityActionsEnum } from 'types/enum';
import classNames from 'classnames';
import { EntityDetails } from '../entity-details/EntityDetails';
import { DatasourceDetailsForm } from './DatasourceDetailsForm';
import { Loader } from 'components/loader/Loader';
import { useModal } from 'selectors/useModals';
import { ModalScopesEnum, ModalTypesEnum } from 'reducers/modalsReducer';
import { downloadConnectorFile } from 'utils/downloadConnector';
import { useNavigate } from 'react-router-dom';
import { urlParameters } from 'hooks/useURLParams';
import { EntityFooter } from 'components/entity-footer/EntityFooter';
import { RcFile, UploadFile } from 'antd/es/upload';
import { EntityProvider, Step } from 'components/layouts/context/EntityProvider';
import { FavoriteToggle } from 'components/favorite-toggle/FavoriteToggle';
import { EntityAccess } from 'components/entity-access/EntityAccess';
import { useSharedEntities } from 'selectors/useSharedEntitiesSelector';
import { allowedFileTypes } from 'utils/dataSourceTypes';

export type DataSourceDetailsFields = {
  name: string;
  type: string;
  description: string;
  fileInput: UploadFile[];
  projectLabels: ProjectLabel[];
};

export type EmptyValues = {
  fileInput: UploadFile[];
  name: string;
  description: string;
  projectLabels: ProjectLabel[];
};

export const DatasourceView: React.FC<{ edit: boolean }> = ({ edit = false }) => {
  const activeDataSource = useActiveDataSource();
  const updateDataSource = useUpdateDataSourceSelector();
  const toggleFavorite = useMarkAsFavorite();
  const fetchFile = useGetFile();
  const { me } = useSharedEntities();
  const { show: showArchiveModal } = useModal(ModalScopesEnum.DATA_SOURCES, ModalTypesEnum.ARCHIVE, '/');
  const { show: showDeleteModal } = useModal(ModalScopesEnum.DATA_SOURCES, ModalTypesEnum.DELETE, '/');
  const { show: showManageAccessModal } = useModal(ModalScopesEnum.DATA_SOURCES, ModalTypesEnum.MANAGE);

  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [isUserEditing, setIsUserEditing] = useState<boolean>(edit);
  const [isFormConfirming, setIsFormConfirming] = useState<boolean>(false);
  const [selectedProjectLabels, setSelectedProjectLabels] = useState<ProjectLabel[]>([]);
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const showFileInput = useMemo(
    () =>
      !!activeDataSource.data?.type?.includes(
        DatasourceTypesEnum.CSV || DatasourceTypesEnum.XLS || DatasourceTypesEnum.HYPER,
      ),
    [activeDataSource.data?.type],
  );

  const initialValues: DataSourceDetailsFields = {
    fileInput: [],
    name: activeDataSource?.data?.name as string,
    type: activeDataSource?.data?.scope as string,
    description: activeDataSource?.data?.description as string,
    projectLabels: activeDataSource?.data?.projectLabels as ProjectLabel[],
  };

  const owner = useMemo(
    () => activeDataSource.permissions.find(user => user.id === activeDataSource.data?.owner),
    [activeDataSource.permissions, activeDataSource.data?.owner],
  );

  const isOwner = useMemo(() => owner?.id == me?.id, [owner, me]);

  const handleDownloadFile = useCallback(
    (file: File) => {
      downloadConnectorFile(file);
    },
    [downloadConnectorFile],
  );

  const dataSourceActions: EntityHeaderAction[] = [
    {
      key: EntityActionsEnum.MANAGE_ACCESS,
      component: (
        <EntityAccess
          owner={owner as UserEntity}
          sharedUsers={activeDataSource.permissions || []}
          onAdd={() => showManageAccessModal(activeDataSource)}
        />
      ),
    },
    {
      key: EntityActionsEnum.DELETE,
      onClick: () => showDeleteModal(activeDataSource.data),
      icon: <DeleteIcon className={classNames(styles.actionIcon, styles.deleteIcon)} />,
      disabled: activeDataSource.data?.owner !== me?.id || !activeDataSource.data?.isDeletable,
    },
    {
      key: EntityActionsEnum.ARCHIVE,
      onClick: () => showArchiveModal(activeDataSource.data),
      icon: <ArchiveIcon className={styles.actionIcon} />,
      disabled: false,
    },
    {
      key: EntityActionsEnum.FAVORITE,
      onClick: () =>
        toggleFavorite(activeDataSource.data?.id as DataSourceId, activeDataSource.data?.isFavorite as boolean),
      icon: (
        <FavoriteToggle
          isFavorite={activeDataSource.data?.isFavorite as boolean}
          iconColor="var(--nri-color-purple-grey)"
        />
      ),
    },
    {
      key: EntityActionsEnum.DOWNLOAD,
      onClick: () => handleDownloadFile(activeDataSource?.file as File),
      icon: <DownloadIcon className={styles.actionIcon} />,
      disabled: !activeDataSource?.data || !(activeDataSource.data.type in allowedFileTypes),
    },
  ];

  const segmentedItems: Step[] = [
    {
      title: 'General',
      description: '', //'Here you can edit connectors',
      key: 'general',
      content: <></>,
    },
    // {
    //   hide: true,
    //   title: 'Activity',
    //   key: 'activity',
    //   content: <></>,
    // },
  ];

  const renderLayout = () => {
    return (
      <DatasourceDetailsForm
        dataSourceType={activeDataSource.data?.type as DatasourceTypesEnum}
        fileList={fileList}
        form={form}
        onRemoveFile={handleRemoveFile}
        initialValues={initialValues as DataSourceDetailsFields}
        showInput={showFileInput}
        isEditing={isUserEditing}
        selectedProjectLabels={selectedProjectLabels}
        setSelectedProjectLabels={setSelectedProjectLabels}
        setFileList={setFileList}
      />
    );
  };

  useEffect(() => {
    if (activeDataSource.data?.hasFile && activeDataSource.data?.type in allowedFileTypes) {
      const { id, name } = activeDataSource.data;
      fetchFile(id, name, activeDataSource.data?.type as DatasourceTypesEnum);
    }
  }, [activeDataSource.data?.hasFile, activeDataSource.data?.type]);

  useEffect(() => {
    if (!activeDataSource.data) return;
    setSelectedProjectLabels(activeDataSource.data?.projectLabels);
  }, [activeDataSource.data?.projectLabels]);

  useEffect(() => {
    if (activeDataSource.file !== undefined) {
      setFileList([activeDataSource.file] as unknown as UploadFile[]);
      form.setFieldValue('fileInput', [activeDataSource.file] as unknown as RcFile[]);
    }
  }, [activeDataSource.file]);

  const handleStartNewConversation = () => {
    const route = urlParameters({ dataSources: [activeDataSource.data?.id as DataSourceId] });
    navigate(`/conversations/new?${route}`);
  };

  const handleEdit = () => {
    setIsUserEditing(true);
  };

  const handleCancelEditing = () => {
    form.setFieldsValue({ ...initialValues, fileInput: [activeDataSource.file] });
    setSelectedProjectLabels(initialValues?.projectLabels as ProjectLabel[]);
    setFileList([activeDataSource?.file] as unknown as UploadFile[]);
    setIsUserEditing(false);
  };

  const handleRemoveFile = (emptyValues: EmptyValues) => {
    form.setFieldsValue(emptyValues);
    setFileList([]);
  };

  const handleConfirm = useCallback(async () => {
    await form.validateFields();
    setIsFormConfirming(true);
    const dataInfo = form.getFieldsValue() as DataSourceDetailsFields;

    const payload = {
      description: dataInfo.description,
      ...(showFileInput
        ? { file: dataInfo.fileInput[0].originFileObj ?? (dataInfo.fileInput[0] as unknown as File) }
        : { name: dataInfo.name }),
      projectLabels: dataInfo.projectLabels.map(label => label.id),
    };

    updateDataSource(activeDataSource.data?.id as DataSourceId, payload, showFileInput);
    setIsFormConfirming(false);
    setIsUserEditing(false);
  }, [activeDataSource.data?.id, form, setIsFormConfirming, setIsUserEditing, updateDataSource]);

  return (
    <>
      {activeDataSource.loading ? (
        <Loader fillVertical style={{ height: '100%' }} />
      ) : (
        <Layout className={styles.dataSource}>
          <EntityProvider<unknown> isNew={false} steps={segmentedItems}>
            <EntityHeader
              actions={dataSourceActions}
              canGoBack
              isEditable={false}
              rightSideButtonText="Start new conversation"
              rightSideButtonOnClick={handleStartNewConversation}
              title={activeDataSource?.data?.name ?? ''}
            />
            <EntityDetails isEditable={false} entityName="connector">
              {renderLayout()}
            </EntityDetails>
            {/* @TODO connectors view shouldn't define its own footer, it should come in from entity details */}
            {isOwner && (
              <EntityFooter
                isEditing={isUserEditing}
                isSaving={isFormConfirming}
                onCancel={handleCancelEditing}
                onConfirm={handleConfirm}
                onEdit={handleEdit}
              />
            )}
          </EntityProvider>
        </Layout>
      )}
    </>
  );
};
