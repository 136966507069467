import { LockOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Form, Layout, Typography } from 'antd';
import Logo from '../../assets/logo.svg?react';
import styles from './login.module.scss';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { message } from 'antd';
import { ErrorCode, getErrorMessage } from 'types/errorCodes';

const { Text, Title } = Typography;

const Login = () => {
  const [searchParams] = useSearchParams();
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    const errorCode = searchParams.get('code');
    if (errorCode) {
      const code = errorCode as ErrorCode;
      message.error(getErrorMessage(code));
    }
  }, [searchParams]);

  return (
    <Layout className={styles.layout}>
      <section className={styles.section}>
        <div className={styles.container}>
          <div className={styles.logo}>
            <Logo width={130} />
          </div>
          <div className={styles.header}>
            <Title className={styles.title}>Sign in</Title>
            <Text className={styles.text}>Welcome back to Newton! Please login below to continue.</Text>
          </div>
          <Form
            name="normal_login"
            initialValues={{
              remember: true,
            }}
            onFinish={loginWithRedirect}
            layout="vertical"
            requiredMark="optional"
          >
            <Form.Item style={{ marginBottom: '0px' }}>
              <Button block={true} type="primary" onClick={() => loginWithRedirect()} icon={<LockOutlined />}>
                Login
              </Button>
            </Form.Item>
          </Form>
        </div>
      </section>
    </Layout>
  );
};

export default Login;
