import { Badge, Flex, Form, Typography } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { useEntityContext } from 'components/layouts/context/EntityProvider';
import { useMemo, useState } from 'react';
import { useProjectLabels } from 'selectors/useProjectLabels';
import { useLoadMoreTimeZones, useTimeZones } from 'selectors/useScheduledTasksSelector';
import { InfiniteScrollSelect } from 'ui/InfiniteScrollSelect';
import { InputSimple } from 'ui/Input';
import { ProjectLabel } from 'ui/ProjectLabel';
import { Select } from 'ui/Select';
import { frequencyFields, scheduleVariants } from 'utils/scheduledTask';
import styles from './steps.module.scss';
import classNames from 'classnames';

const frequencyOptions: { label: string; value: Frequency }[] = [
  { label: 'Daily', value: 'daily' },
  { label: 'Weekly', value: 'weekly' },
  { label: 'Monthly', value: 'monthly' },
  { label: 'Annually ', value: 'annually' },
];

export const ScheduleTaskStep = ({ isEditMode = false }: { isEditMode?: boolean }) => {
  const { form, initialFormValue, readOnly } = useEntityContext<ScheduledTaskFormData>();
  const { projectLabels } = useProjectLabels();
  const { timeZones, loading: timeZonesLoading } = useTimeZones();
  const { loadMore: loadMoreTimeZones, hasMore } = useLoadMoreTimeZones();
  const [selectedProjectLabelIds, setSelectedProjectLabelIds] = useState<ProjectLabelId[]>(
    initialFormValue?.projectLabels,
  );
  const projectOptions = useMemo(() => {
    return (projectLabels || []).filter(label => !selectedProjectLabelIds.includes(label.id));
  }, [projectLabels, selectedProjectLabelIds]);
  const selectedProjectLabels = useMemo(() => {
    return selectedProjectLabelIds.map(id => projectLabels.find(l => l.id === id)!);
  }, [projectLabels, selectedProjectLabelIds]);
  const [frequency, setFrequency] = useState<Frequency>(
    (initialFormValue?.frequencyType?.toLocaleLowerCase() as Frequency) || frequencyOptions[0].value,
  );

  const handleSelectProject = (_: string, option: DefaultOptionType) => {
    setSelectedProjectLabelIds(prev => [...prev, option.value as ProjectLabelId]);
    form.setFieldValue('projectLabels', [...selectedProjectLabelIds, option.value]);
  };

  const handleRemoveProject = (label: ProjectLabel) => {
    setSelectedProjectLabelIds(prev => prev.filter(l => l !== label.id));
    form.setFieldValue(
      'projectLabels',
      selectedProjectLabels.filter(l => l.id !== label.id),
    );
  };

  const handleSelectFrequency = (value: Frequency) => {
    setFrequency(value);
    form.setFieldsValue({
      ...scheduleVariants[frequency].fields.reduce(
        (acc, field) => {
          acc[field] = undefined;
          return acc;
        },
        {} as Record<string, string | undefined>,
      ),
    });
  };

  const formValidations = {
    name: {
      required: true,
      message: 'Please enter a valid name',
    },
    projectLabels: { required: false, message: 'Please select one or more project labels' },
    timeZone: { required: true, message: 'Please select a time zone' },
    time: { required: true, message: 'Please select a time' },
    dayOfWeek: { required: true, message: 'Please select a day of the week' },
    dayOfMonth: { required: true, message: 'Please select a day of the month' },
    month: { required: true, message: 'Please select a month' },
    ...(isEditMode ? { icebreaker: { required: true, message: 'Please select at least one icebreaker' } } : {}),
  };

  return (
    <Flex vertical>
      <div>
        <Form.Item
          className={styles.formItem}
          label="Projects"
          name="projectLabels"
          rules={[formValidations['projectLabels']]}
          validateTrigger={['onChange', 'onBlur']}
        >
          <Select
            placeholder="Add project"
            disabled={readOnly}
            options={projectOptions
              ?.sort((a, b) => a.name.localeCompare(b.name))
              .map(option => ({
                label: (
                  <>
                    <Badge text={option.name} color={option.color ?? 'var(--nri-color-purple-grey)'} />
                  </>
                ),
                value: option.id,
                color: option.color,
              }))}
            value={0}
            onSelect={handleSelectProject}
            labelRender={() => <Typography.Text>Add to project</Typography.Text>}
            showSearch={false}
          />
        </Form.Item>
        <Flex wrap="wrap" gap={8} className={styles.projectLabelsContainer}>
          {selectedProjectLabels.map((item, index) => (
            <ProjectLabel
              key={index}
              readOnly={readOnly}
              text={item.name}
              filled
              onRemove={() => handleRemoveProject(item)}
            />
          ))}
        </Flex>
      </div>
      <Form.Item
        className={styles.formItem}
        label="Name"
        name="name"
        rules={[formValidations['name']]}
        validateTrigger={['onChange', 'onBlur']}
      >
        <InputSimple readOnly={readOnly} />
      </Form.Item>
      <Flex vertical>
        <Form.Item
          className={styles.formItem}
          label="Schedule"
          name="timeZone"
          rules={[formValidations['timeZone']]}
          validateTrigger={['onChange', 'onBlur']}
        >
          <InfiniteScrollSelect
            disabled={readOnly}
            options={timeZones.map(item => ({ label: item.timezone, value: item.timezone }))}
            hasMore={hasMore}
            loading={timeZonesLoading}
            placeholder="Select a time zone"
            onLoad={() => loadMoreTimeZones()}
          />
        </Form.Item>
        <Flex gap={16} style={{ width: '100%' }}>
          <Form.Item
            style={{ ['--scheduled-items-count' as string]: scheduleVariants[frequency].fields.length + 1 }}
            className={classNames(styles.scheduledItem, { [styles.formItem]: true })}
          >
            <Select
              disabled={readOnly}
              placeholder="Select frequency"
              options={frequencyOptions}
              value={frequency}
              onChange={handleSelectFrequency}
            />
          </Form.Item>
          {scheduleVariants[frequency].fields.map(variant => {
            const field = frequencyFields[variant];
            return (
              <Form.Item
                style={{ ['--scheduled-items-count' as string]: scheduleVariants[frequency].fields.length + 1 }}
                className={styles.scheduledItem}
                key={field.key}
                name={field.key}
                rules={[formValidations[field.key as keyof typeof formValidations] || {}]}
                validateTrigger={['onChange', 'onBlur']}
              >
                <Select
                  disabled={readOnly}
                  placeholder={field.placeholder}
                  options={field.options.map((option, index) => ({
                    key: `${index}_${option}`,
                    label: option,
                    value: option,
                  }))}
                />
              </Form.Item>
            );
          })}
        </Flex>
      </Flex>
    </Flex>
  );
};
