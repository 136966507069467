import { Flex, Form, Input, Typography } from 'antd';
import { AgentFormData } from 'components/agent-view/AgentView';
import { useEntityContext } from 'components/layouts/context/EntityProvider';
import React, { useCallback, useMemo } from 'react';
import { useAgentModels } from 'selectors/useAgents';
import { useAuth } from 'selectors/useAuthSelector';
import { useMemories, useSpecialistMemories } from 'selectors/userMemories';
import { Select } from 'ui/Select';
import { Switch } from 'ui/Switch';
import styles from 'ui/UIKit.module.scss';

export const GeneralStep: React.FC = () => {
  const { form } = useEntityContext<AgentFormData>();
  const { me } = useAuth();
  const models = useAgentModels();
  const { labels } = useMemories();
  const { results: chartingSpecialistMemories } = useSpecialistMemories('chartReview');

  const isEnabled = Form.useWatch('isEnabled', form);
  const isChartingEnabled = Form.useWatch(['agentParams', 'specialists', 'chartReview', 'isEnabled'], form) || false;

  const handleToggle = (checked: boolean) => {
    form.setFieldValue('isEnabled', checked);
  };

  const handleChartingToggle = (checked: boolean) => {
    form.setFieldValue(['agentParams', 'specialists', 'chartReview', 'isEnabled'], checked);
  };

  const providers = useMemo(() => {
    if (!models) return [];
    return models
      .map(model => model.provider)
      .filter((provider, index, self) => self.indexOf(provider) === index)
      .sort()
      .map(provider => ({ label: provider, value: provider }));
  }, [models]);

  const selectedProvider = Form.useWatch(['agentParams', 'modelProvider'], form);

  const availableModels = useMemo(() => {
    if (!models || !selectedProvider) return [];

    return models
      .filter(model => model.provider === selectedProvider)
      .sort((a, b) => a.model.localeCompare(b.model))
      .map(model => ({
        label: model.model,
        value: model.model,
      }));
  }, [models, selectedProvider]);

  const onChartingSpecialistMemoriesSelect = useCallback(
    (memories: Memory[]) => {
      form.setFieldValue('agentParams.specialists.chartReview.memories', memories);
    },
    [form],
  );

  return (
    <>
      <Typography.Title className={styles.title} level={2}>
        <Flex justify="space-between" align="center" style={{ marginBlock: '24px 40px' }}>
          <div>Agent Details</div>
          <Flex>
            <Form.Item name="isEnabled" noStyle valuePropName="checked">
              <Switch checked={isEnabled} displayLabel onChange={handleToggle} />
            </Form.Item>
          </Flex>
        </Flex>
      </Typography.Title>

      <Form.Item name="owner" hidden>
        <Input value={me!.id} />
      </Form.Item>

      <Form.Item label="Agent name" name="name" rules={[{ required: true, message: 'Enter agent name' }]}>
        <Input autoFocus placeholder="Enter Agent name" />
      </Form.Item>

      <Form.Item label="Description" name="description">
        <Input.TextArea rows={4} placeholder="Enter description" />
      </Form.Item>

      <Form.Item label="Controller" name="agent">
        <Select
          options={[
            {
              label: 'Prism',
              value: 'prism-1',
            },
          ]}
          placeholder="Select base agent"
        />
      </Form.Item>

      <Form.Item label="LLM provider" name={['agentParams', 'modelProvider']}>
        <Select options={providers} placeholder="Select LLM provider" />
      </Form.Item>

      <Form.Item label="LLM model" name={['agentParams', 'modelName']}>
        <Select options={availableModels} placeholder="Select LLM model" disabled={!selectedProvider} />
      </Form.Item>

      <Form.Item label="Special instructions for agent" name={['agentParams', 'instructions']}>
        <Input.TextArea rows={4} placeholder="Enter specific prompting" />
      </Form.Item>

      <Form.Item label="Agent knowledge base" name="memoryLabels">
        <Select
          options={labels.map(label => ({ label: label.name, value: label.id }))}
          placeholder="Select labels to filter knowledge"
          mode="multiple"
        />
      </Form.Item>

      <Typography.Title className={styles.title} level={2}>
        <Flex justify="space-between" align="center" style={{ marginBlock: '24px 40px' }}>
          <div>Charting Specialist</div>
          <Flex>
            <Form.Item
              name={['agentParams', 'specialists', 'chartReview', 'isEnabled']}
              noStyle
              valuePropName="checked"
            >
              <Switch checked={isChartingEnabled} displayLabel onChange={handleChartingToggle} />
            </Form.Item>
          </Flex>
        </Flex>
      </Typography.Title>

      <Form.Item
        label="Instructions for Charting Specialist"
        name={['agentParams', 'specialists', 'chartReview', 'instructions']}
      >
        <Input.TextArea rows={4} placeholder="Enter specific prompting" />
      </Form.Item>

      <Form.Item
        label="Examples for Charting Specialist"
        name={['agentParams', 'specialists', 'chartReview', 'memories']}
      >
        <Select
          options={chartingSpecialistMemories.map(memory => ({ label: memory.name, value: memory.id }))}
          placeholder="Select from examples for charting specialist"
          mode="multiple"
          onChange={onChartingSpecialistMemoriesSelect}
        />
      </Form.Item>
    </>
  );
};
