import { CheckOutlined } from '@ant-design/icons';
import { Flex } from 'antd';
import { EntityCard } from 'components/entity-list/EntityCard';
import { BaseEntityCardProps } from 'components/entity-list/EntityList';
import { ProjectLabels } from 'components/project-labels/ProjectLabels';
import { ShareList } from 'components/share-list/ShareList';
import { Button } from 'ui/Button';
import { highlightText } from 'utils/highlightText';

type SelectBlueprintCardProps = BaseEntityCardProps<IceBreaker> & {
  showCategory?: boolean;
  showFavorite?: boolean;
  showOwner?: boolean;
};

export const SelectBlueprintCard = ({
  entity: blueprint,
  isSelected = false,
  onClick: onToggle,
  search = '',
}: SelectBlueprintCardProps) => {
  const { name, description } = blueprint;

  return (
    <EntityCard
      avatar={<ShareList owner={blueprint.owner} />}
      description={highlightText(description, search)}
      descriptionLines={2}
      titleLines={2}
      footer={
        <>
          <Flex gap="8px" align="center" justify="flex-start" wrap="wrap">
            <Flex>
              Connectors:&nbsp;<strong>{blueprint?.dataSources?.length}</strong>
            </Flex>
            <ProjectLabels width="45%" projectLabels={blueprint.projectLabels} />
          </Flex>
          <Button
            style={{ margin: '0 8px' }}
            icon={isSelected ? <CheckOutlined /> : undefined}
            onClick={() => onToggle?.(blueprint)}
            type="text"
          >
            {isSelected ? 'Selected' : 'Select'}
          </Button>
        </>
      }
      title={highlightText(name, search)}
    />
  );
};
