import { message } from 'antd';
import { useAppContext } from 'contexts/AppProviders';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ScheduledTasksActionTypes } from 'reducers/scheduledTasksReducer';
import { CommonStatusEnum, ScheduledTasksFilterTypesEnum } from 'types/enum';
import { NewtonApi } from 'utils/newtonApi';

const useScheduledTasksState = (params: { page: string; retrieveType: ScheduledTasksFilterTypesEnum }) => {
  const {
    state: { scheduledTasksState },
    dispatch,
  } = useAppContext();
  const { status } = scheduledTasksState;
  const fetchScheduledTasks = useFetchScheduledTasks();

  useEffect(() => {
    if (status === CommonStatusEnum.INITIAL) {
      (async () => {
        dispatch({
          type: ScheduledTasksActionTypes.FETCHING_SCHEDULED_TASKS,
          payload: params.retrieveType,
        });
        fetchScheduledTasks(params);
      })();
    }
    return () => {
      if (status === CommonStatusEnum.FETCHED)
        dispatch({
          type: ScheduledTasksActionTypes.CLEAN_ALL_SCHEDULED_TASKS,
        });
    };
  }, [dispatch, status, params]);

  return { state: scheduledTasksState };
};
export const useFetchScheduledTasks = () => {
  const { dispatch } = useAppContext();
  return useCallback(
    async (params: { page: string; retrieveType: ScheduledTasksFilterTypesEnum }) => {
      const shouldIncludeStatus = [ScheduledTasksFilterTypesEnum.ALL, ScheduledTasksFilterTypesEnum.ARCHIVED].includes(
        params.retrieveType,
      );

      const fetchParams = {
        page: params.page,
        ...(shouldIncludeStatus && { status: params.retrieveType }),
      };
      const { results, ...pagination } = await NewtonApi.fetchScheduledTasks(fetchParams);
      dispatch({
        type: ScheduledTasksActionTypes.FETCH_SCHEDULED_TASKS,
        payload: { tasks: results, pagination, scheduledTasksFilter: params.retrieveType },
      });
    },
    [dispatch],
  );
};
export const useScheduledTask = () => {
  const { dispatch } = useAppContext();
  const navigate = useNavigate();

  return useCallback(
    async (id: ScheduledTask['id']) => {
      try {
        dispatch({
          type: ScheduledTasksActionTypes.FETCHING_ACTIVE_TASK,
        });
        const task = await NewtonApi.fetchScheduledTask(id, true);
        dispatch({
          type: ScheduledTasksActionTypes.FETCH_ACTIVE_TASK,
          payload: task,
        });
      } catch (error) {
        navigate('/tasks');
        message.error(`Scheduled task ${id} not found`);
      }
    },
    [dispatch],
  );
};

const useTimeZonesState = () => {
  const {
    dispatch,
    state: { scheduledTasksState },
  } = useAppContext();
  const { status } = scheduledTasksState.timeZones;

  useEffect(() => {
    if (status === CommonStatusEnum.INITIAL) {
      (async () => {
        dispatch({
          type: ScheduledTasksActionTypes.FETCHING_TIME_ZONES,
        });
        const { results, ...pagination } = await NewtonApi.fetchTimeZones();
        const newTimeZones = results.map((item: { abbreviation: string; timezone: string }, index: number) => ({
          id: `${index}-${item.timezone}`,
          ...item,
        }));

        dispatch({
          type: ScheduledTasksActionTypes.FETCH_TIME_ZONES,
          payload: { timeZones: newTimeZones, pagination },
        });
      })();
    }
  }, [dispatch, status]);

  return { state: scheduledTasksState };
};

export const useScheduledTasks = (page: number, filter: ScheduledTasksFilterTypesEnum) => {
  const params = useMemo(() => ({ page: page.toString(), retrieveType: filter }), [page, filter]);
  const {
    state: { scope, status },
  } = useScheduledTasksState(params);

  return {
    tasks: Array.from(scope[filter].tasks.values()),
    pagination: scope[filter].pagination,
    status: status,
    loading: status === CommonStatusEnum.FETCHING || status === CommonStatusEnum.INITIAL,
  };
};
export const useCreateScheduledTask = () => {
  const fetchScheduledTasks = useFetchScheduledTasks();
  return useCallback(
    async (data: ScheduledTaskFormData, page: number, filter: ScheduledTasksFilterTypesEnum) => {
      const { icebreaker, time, dayOfWeek, dayOfMonth, month, timeZone, ...rest } = data;
      const payload = {
        ...rest,
        isEnabled: true,
        icebreaker: icebreaker[0] ?? '',
        schedule: {
          type: 'cron',
          timeZone,
          ...(time ? { time } : {}),
          ...(dayOfWeek ? { dayOfWeek } : {}),
          ...(dayOfMonth ? { dayOfMonth } : {}),
          ...(month ? { month } : {}),
        },
      };

      try {
        await NewtonApi.createScheduledTask(payload, true);
        fetchScheduledTasks({ page: page.toString(), retrieveType: filter });
        message.success('Scheduled task created successfully');
      } catch (error) {
        message.error('Failed to create scheduled task');
      }
    },
    [fetchScheduledTasks],
  );
};

export const useUpdateScheduledTask = () => {
  const { dispatch } = useAppContext();
  return useCallback(
    async (id: ScheduledTask['id'], data: ScheduledTaskFormData) => {
      const { icebreaker, time, dayOfWeek, dayOfMonth, month, timeZone, ...rest } = data;
      const payload = {
        ...rest,
        icebreaker: icebreaker[0] ?? '',
        schedule: {
          type: 'cron',
          timeZone,
          ...(time ? { time } : {}),
          ...(dayOfWeek ? { dayOfWeek } : {}),
          ...(dayOfMonth ? { dayOfMonth } : {}),
          ...(month ? { month } : {}),
        },
      };
      try {
        const response = await NewtonApi.updateScheduledTask(id, payload, true);
        dispatch({
          type: ScheduledTasksActionTypes.UPDATE_SCHEDULED_TASK,
          payload: { id, data: response },
        });
        message.success('Scheduled task updated successfully');
      } catch (error) {
        message.error('Failed to update scheduled task');
      }
    },
    [dispatch],
  );
};

export const useToggleActivateScheduledTask = () => {
  const { dispatch } = useAppContext();

  return useCallback(
    async (id: ScheduledTask['id'], isEnabled: boolean) => {
      try {
        const { nextRunAt } = await NewtonApi.updateScheduledTaskStatus(id, isEnabled, true);
        dispatch({
          type: ScheduledTasksActionTypes.UPDATE_SCHEDULED_TASK,
          payload: { id, data: { isEnabled, nextRunAt } },
        });
        message.success('Scheduled task updated successfully');
      } catch (error) {
        message.error('Failed to update scheduled task');
      }
    },
    [dispatch],
  );
};

export const useDeleteScheduledTask = () => {
  const { dispatch } = useAppContext();

  return useCallback(
    async (id: ScheduledTask['id']) => {
      try {
        await NewtonApi.deleteScheduledTask(id);
        dispatch({
          type: ScheduledTasksActionTypes.DELETE_SCHEDULED_TASK,
          payload: id,
        });
        message.success('Scheduled task deleted successfully');
      } catch (error) {
        message.error('Failed to delete scheduled task');
      }
    },
    [dispatch],
  );
};

export const useArchiveScheduledTask = () => {
  const { dispatch } = useAppContext();

  return useCallback(
    async (id: ScheduledTask['id'], status: string = 'archived') => {
      try {
        await NewtonApi.archiveScheduledTask(id, status);
        dispatch({
          type: ScheduledTasksActionTypes.DELETE_SCHEDULED_TASK,
          payload: id,
        });
        message.success('Scheduled task archived successfully');
      } catch (error) {
        message.error('Failed to archive scheduled task');
      }
    },
    [dispatch],
  );
};

export const useTimeZones = () => {
  const {
    state: { timeZones },
  } = useTimeZonesState();

  return {
    timeZones: Array.from(timeZones.results.values()),
    pagination: timeZones.pagination,
    status: timeZones.status,
    loading: timeZones.status === CommonStatusEnum.FETCHING || timeZones.status === CommonStatusEnum.INITIAL,
  };
};

export const useLoadMoreTimeZones = () => {
  const {
    dispatch,
    state: {
      scheduledTasksState: { timeZones },
    },
  } = useAppContext();
  const { pagination: currentPagination } = timeZones;

  return {
    loadMore: useCallback(async () => {
      if (currentPagination.next) {
        const urlObj = new URL(currentPagination.next);
        const urlSearch = new URLSearchParams(urlObj.search);
        const params: Record<string, string> = {};

        Array.from(urlSearch.entries()).forEach(([key, value]) => {
          params[key] = value;
        });

        const { results, ...pagination } = await NewtonApi.fetchTimeZones(params);
        const newTimeZones = results.map((item: { abbreviation: string; timezone: string }, index: number) => ({
          id: `${index}-${item.timezone}`,
          ...item,
        }));
        dispatch({
          type: ScheduledTasksActionTypes.FETCH_TIME_ZONES,
          payload: { timeZones: newTimeZones, pagination },
        });
      }
    }, [dispatch, currentPagination.next]),
    hasMore: currentPagination.next !== null,
  };
};
export const useActiveScheduledTask = () => {
  const {
    state: {
      scheduledTasksState: { activeTask },
    },
  } = useAppContext();
  return {
    task: activeTask.data,
    loading: activeTask.status === CommonStatusEnum.FETCHING || activeTask.status === CommonStatusEnum.INITIAL,
  };
};
