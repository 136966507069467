import { ModalScopesEnum, ModalTypesEnum } from 'reducers/modalsReducer';
import { useModal } from 'selectors/useModals';

import { useSharedEntities } from 'selectors/useSharedEntitiesSelector';
import { useCallback, useMemo, useState } from 'react';
import { EntityPermissionsEnum } from 'types/enum';

import { Modal } from 'ui/Modal';
import { Button } from 'ui/Button';
import { Form } from 'antd';
import { EntityShareForm } from 'components/entity-share-form/EntityShareForm';
import { DatasourceSelectedItem } from 'components/datasource-shared-item/DatasourceSelectedItem';
import { useManageAccess } from 'selectors/useAgents';

export const ManageAgentAccessModal: React.FC = () => {
  const {
    data: { agent, permissions },
    hide,
  } = useModal<{ agent: Agent; permissions: ShareableEntity[] }>(ModalScopesEnum.AGENTS, ModalTypesEnum.MANAGE);
  const [form] = Form.useForm();

  const manageAccess = useManageAccess();
  const { me, sharedEntities } = useSharedEntities();
  const ownerId = agent.owner;

  const owner = useMemo(
    () =>
      permissions.find(e => e.type === 'user' && e.id === ownerId) ||
      sharedEntities.find(e => e.type === 'user' && e.id === ownerId),
    [permissions],
  );

  const [isLoading, setIsLoading] = useState(false);

  const onConfirm = useCallback(async () => {
    setIsLoading(true);
    const { shareWith, sharedUsers } = form.getFieldsValue(true);
    const payload =
      shareWith === 'users'
        ? {
            users: sharedUsers!.map(({ id, permission }: UserEntity) => ({ id, permission })),
          }
        : { organization: { id: me!.organization, permission: EntityPermissionsEnum.READ } };

    try {
      await manageAccess(agent.id, payload, ownerId);
      setIsLoading(false);
      hide();
    } catch (error) {
      setIsLoading(false);
    }
  }, [agent, me, form, hide, manageAccess, ownerId, setIsLoading]);

  return (
    <Modal
      open={true}
      centered
      styles={{
        content: { height: '572px' },
        body: { height: '100%' },
        header: { margin: '0' },
        footer: { margin: '0' },
      }}
      onCancel={hide}
      title="Manage Access"
      description="Here you can manage access to connector"
      destroyOnClose
      divider={true}
      footer={
        <>
          <Button onClick={hide} bordered>
            Cancel
          </Button>
          <Button loading={isLoading} onClick={onConfirm} type="primary">
            Add
          </Button>
        </>
      }
    >
      <EntityShareForm
        entityName="agent"
        form={form}
        sharedEntities={sharedEntities}
        sharedPermissions={permissions}
        owner={owner!}
        components={{ SelectedItem: DatasourceSelectedItem }}
      />
    </Modal>
  );
};
