import { useAppContext } from 'contexts/AppProviders';
import { useCallback, useEffect } from 'react';
import { AnalystsActionTypes } from 'reducers/analystsReducer';
import { CommonStatusEnum } from 'types/enum';
import { NewtonApi } from 'utils/newtonApi';

const useAnalystsState = () => {
  const {
    state: { analystsState },
    dispatch,
  } = useAppContext();
  const { status } = analystsState;

  useEffect(() => {
    if (status === CommonStatusEnum.INITIAL) {
      (async () => {
        dispatch({
          type: AnalystsActionTypes.FETCHING_ANALYSTS,
        });
        const [{ results: analysts, ...pagination }, defaultAnalyst] = await Promise.all([
          NewtonApi.fetchAllAnalysts(),
          NewtonApi.fetchDefaultAnalyst(),
        ]);

        dispatch({
          type: AnalystsActionTypes.FETCH_ANALYSTS,
          payload: { analysts, pagination },
        });
        dispatch({
          type: AnalystsActionTypes.FETCH_DEFAULT_ANALYST,
          payload: defaultAnalyst,
        });
      })();
    }
  }, [dispatch, status]);

  return { state: analystsState };
};

export const useAnalysts = () => {
  const {
    state: { analysts, defaultAnalyst, status },
  } = useAnalystsState();
  return {
    analysts: Array.from(analysts.values()),
    defaultAnalyst: defaultAnalyst,
    loading: status === CommonStatusEnum.FETCHING || status === CommonStatusEnum.INITIAL,
  };
};

export const useAnalystById = () => {
  const { analysts } = useAnalysts();
  return useCallback(
    (id?: AnalystId | string) => {
      if (!id) return;
      return analysts.find(a => a.id.toString() === id.toString());
    },
    [analysts],
  );
};
